import { useParams } from "react-router-dom"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import FormularioDepositante from "./components/formulario"


function DepositanteIncluir() {

    const params = useParams()

    return (
        <>
            <div className="container-fluid mt-2">
                <ContainerTitulo titulo="Incluir Depositante" />
                <FormularioDepositante id_depositante={params.id} acao="Incluir" />
            </div>
        </>
    )
}

export default DepositanteIncluir