import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Pages/login/login';
import { MainPage } from './Pages/mainPage';
import { Usuario } from './Pages/administrativo/usuario/usuario';
import { UsuarioIncluir } from './Pages/administrativo/usuario/usuarioIncluir';
import { UsuarioEditar } from './Pages/administrativo/usuario/usuarioEditar';
import { UsuarioVisualizar } from './Pages/administrativo/usuario/usuarioVisualizar';
import { Permissao } from './Pages/administrativo/permissao/permissao';
import { CodigiodoSistema } from './Pages/administrativo/codigodoSistema/codigodosistema';
import { Empresa } from './Pages/administrativo/empresa/empresa';
import { PermissaoIncluir } from './Pages/administrativo/permissao/permissaoIncluir';
import { PermissaoVisualizar } from './Pages/administrativo/permissao/permissaoVisualizar';
import { PermissaoEditar } from './Pages/administrativo/permissao/permissaoEditar';
import { EmpresaIncluir } from './Pages/administrativo/empresa/empresaIncluir';
import { EmpresaEditar } from './Pages/administrativo/empresa/empresaEditar';
import { EmpresaVisualizar } from './Pages/administrativo/empresa/empresaVisualizar';
import { CodigiodoSistemaIncluir } from './Pages/administrativo/codigodoSistema/codigodosistemaIncluir';
import { CodigiodoSistemaVisualizar } from './Pages/administrativo/codigodoSistema/codigodosistemaVisualizar';
import { CodigiodoSistemaEditar } from './Pages/administrativo/codigodoSistema/codigodosistemaEditar';
import { Filiais } from './Pages/cadastro/filiais/filiais/filiais';
import { FilialIncluir } from './Pages/cadastro/filiais/filiais/filialIncluir';
import { FilialEditar } from './Pages/cadastro/filiais/filiais/filialEditar';
import { FilialVisualizar } from './Pages/cadastro/filiais/filiais/filialVisualizar';
import ListarDepositantes from './Pages/cadastro/depositante/depositantes';
import DepositanteIncluir from './Pages/cadastro/depositante/depositanteIncluir';
import DepositanteVisualizar from './Pages/cadastro/depositante/depositanteVisualizar';
import DepositanteEditar from './Pages/cadastro/depositante/depositanteEditar';
import ListarEquipes from './Pages/cadastro/equipeOperacional/equipes';
import EquipeIncluir from './Pages/cadastro/equipeOperacional/equipesIncluir';
import EquipeVisualizar from './Pages/cadastro/equipeOperacional/equipesVisualizar';
import EditarEquipe from './Pages/cadastro/equipeOperacional/equipeEditar';
import ListarOperacoes from './Pages/cadastro/tempoOperacao/operacoes';
import IncluirOperacao from './Pages/cadastro/tempoOperacao/incluirOperacao';
import VisualizarOperacao from './Pages/cadastro/tempoOperacao/visualizarOperacao';
import EditarOperacao from './Pages/cadastro/tempoOperacao/editarOperacao';
import EstoqueSintetico from './Pages/estoque/estoqueSintetico';


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledEngineProvider } from "@mui/material/styles";
import EstoqueBarraValidade from './Pages/estoque/estoqueValidadeLote';
import PedidoStatus from './Pages/pedido/pedidoStatus';
import PedidoBarra from './Pages/pedido/pedidoBarra';
import PedidoLote from './Pages/pedido/pedidoLote';
import PedidoConsolidado from './Pages/pedido/pedidoConsolidado';
import NovoPedido from './Pages/pedido/NovoPedido';
import { AgendaSalaEditar } from './Pages/cadastro/agendaSalas/agendaEquipeEditar';
import { AgendaSalas } from './Pages/cadastro/agendaSalas/agendaEquipe';
import { AgendaSalaIncluir } from './Pages/cadastro/agendaSalas/agendaEquipeIncluir';
import { AgendaSalaVisualizar } from './Pages/cadastro/agendaSalas/agendaEquipeVisualizar';

import AgendaSalaAtendimento from './Pages/agendamento/Equipes/salasAtendimentoAgenda';
import { CalendarioConsultas } from './Pages/agendamento/Equipes/consultas';
import ResumidoValidade from './Pages/recebimentos/resumidoValidade';
import DetalhadaoPorBarra from './Pages/recebimentos/detalhadaPorBarra';
import AgendaConsultaAgendamentos from './Pages/agendamento/agenda';
import Dashboards from './Pages/relatorios/dashboards';
import UsuariosFilial from './Pages/administrativo/usuariosFilial/usuariosFilial';
import TorreDeControle from './Pages/relatorios/torreControle';
import GridFilial from './Pages/administrativo/usuariosFilial/gridFilial';
function App() {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route path="/" element={<MainPage />}>

            {/* Administrativo -> Usuário */}
            <Route path="administrativo/usuario" element={<Usuario />} />
            <Route path="administrativo/usuario/usuario/:id" element={<UsuarioIncluir />} />
            <Route path="administrativo/usuario/usuario/visu/:id" element={<UsuarioVisualizar />} />
            <Route path="administrativo/usuario/usuario/editar/:id" element={<UsuarioEditar />} />

            {/* Administrativo -> Permissão */}
            <Route path="administrativo/permisao" element={<Permissao />} />
            <Route path="administrativo/permisao/permissao/:id" element={<PermissaoIncluir />} />
            <Route path="administrativo/permisao/permissao/visu/:id" element={<PermissaoVisualizar />} />
            <Route path="administrativo/permisao/permissao/editar/:id" element={<PermissaoEditar />} />

            {/* Administrativo -> Empresa */}
            <Route path="administrativo/empresa" element={<Empresa />} />
            <Route path="administrativo/empresa/empresa/:id" element={<EmpresaIncluir />} />
            <Route path="administrativo/empresa/empresa/visu/:id" element={<EmpresaVisualizar />} />
            <Route path="administrativo/empresa/empresa/editar/:id" element={<EmpresaEditar />} />

            {/* Administrativo -> Códigos do Sistema */}
            <Route path="administrativo/codistema" element={<CodigiodoSistema />} />
            <Route path="administrativo/codistema/codsis/:id" element={<CodigiodoSistemaIncluir />} />
            <Route path="administrativo/codistema/codsis/visu/:id" element={<CodigiodoSistemaVisualizar />} />
            <Route path="administrativo/codistema/codsis/editar/:id" element={<CodigiodoSistemaEditar />} />

            {/*Administrativo -> Cadastro -> Filial */}
            <Route path="cadastro/filiais/filial" element={<Filiais />} />
            <Route path="cadastro/filiais/filials/:id" element={<FilialIncluir />} />
            <Route path="cadastro/filiais/filial/:id" element={<FilialIncluir />} />
            <Route path="cadastro/filiais/filial/visu/:id" element={<FilialVisualizar />} />
            <Route path="cadastro/filiais/filial/editar/:id" element={<FilialEditar />} />

            {/*Filial */}

            {/*DEPOSITANTE INICIO */}
            <Route path="cadastro/depositantes" element={<ListarDepositantes />} />
            <Route path="cadastro/depositantes/depositante/:id" element={<DepositanteIncluir />} />
            <Route path="cadastro/depositantes/depositante/visualizar/:id" element={<DepositanteVisualizar />} />
            <Route path="cadastro/depositantes/depositante/editar/:id" element={<DepositanteEditar />} />
            {/*DEPOSITANTE FIM */}

            {/*EQUIPES INICIO*/}
            <Route path="cadastro/equipes/operacional/listar" element={<ListarEquipes />} />
            <Route path="cadastro/equipes/operacional/criar/:id" element={<EquipeIncluir />} />
            <Route path="cadastro/equipes/operacional/visualizar/:id" element={<EquipeVisualizar />} />
            <Route path="cadastro/equipes/operacional/editar/:id" element={<EditarEquipe />} />
            {/*EQUIPES FIM */}

            {/*AGENDA OPERACIONAL INICIO*/}
            <Route path="cadastro/agenda/operacional/listar" element={<AgendaSalas />} />
            <Route path="cadastro/agendaequipe/:id" element={<AgendaSalaIncluir />} />
            <Route path="cadastro/agendaequipe/ver/:id" element={<AgendaSalaVisualizar />} />
            <Route path="cadastro/agendaequipe/editar/:id" element={<AgendaSalaEditar />} />

            {/*AGENDA OPERACIONAL FIM */}

            {/*tempo operacional */}
            <Route path="cadastro/tempo/operacional/listar" element={<ListarOperacoes />} />
            <Route path="cadastro/tempo/operacional/incluir/:id" element={<IncluirOperacao />} />
            <Route path="cadastro/tempo/operacional/visualizar/:id" element={<VisualizarOperacao />} />
            <Route path="cadastro/tempo/operacional/editar/:id" element={<EditarOperacao />} />
            {/*tempo operacional FIM */}

            <Route path="estoque/sintetico" element={<EstoqueSintetico />} />
            <Route path="estoque/barra/validade" element={<EstoqueBarraValidade />} />

            <Route path="pedido/consultar/status" element={<PedidoStatus />} />
            <Route path="pedido/consultar/barra" element={<PedidoBarra />} />
            <Route path="pedido/consultar/validade/lote" element={<PedidoLote />} />
            <Route path="pedido/consultar/consolidado" element={<PedidoConsolidado />} />
            <Route path="pedido/criar/novo" element={<NovoPedido />} />



            {/*RECEBIDO - INICIO */}
            <Route path="recebido/resumido/validade" element={<ResumidoValidade />} />
            <Route path="recebido/detalhado/barra" element={<DetalhadaoPorBarra />} />
            {/*RECEBIDO FIM */}

            {/*AGENDAMENTO EQUIPES -- inicio */}
            <Route path="agendamento/equipe" element={<AgendaSalaAtendimento />} />
            <Route path="agendamento/equipe/agenda" element={<CalendarioConsultas />} />
            <Route path="agendamento/consultar/agenda" element={<AgendaConsultaAgendamentos />} />
            {/*AGENDAMENTO EQUIPES -- fim */}

            <Route path="dashborad/consultar" element={<Dashboards />} />
            <Route path="dashborad/controle/torre" element={<TorreDeControle />} />
            
            <Route path="grid/filial" element={<GridFilial/>} />
            <Route path="usuarios/filial/:idFilial" element={<UsuariosFilial/>} />

          </Route>

          <Route path="/login" element={<Login />} />
        </Routes>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
