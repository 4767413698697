import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../shared/SelectInputs";
import { getSessionData } from "../../../../../utils/storageUtils";
import axios from "axios";


const FormAgendaSala = (info: any) => {

  const id_empresa_usuario = getSessionData("isPerfilVinculo")
  const id_filial_usuario = getSessionData("DadosIdfilial")
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_perfil = getSessionData("id_perfil")
  const navigate = useNavigate()

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

  const [id_agenda_equipe, set_id_agenda_equipe] = useState(info.id)
  const [id_equipe, set_id_equipe] = useState("")
  const [nome_equipe, set_nome_equipe] = useState("")
  const [operacao, set_operacao] = useState("")
  const [status, setStatus] = useState(false)
  const [inicio, setInicio] = useState("")
  const [fim, setFim] = useState("")


  const [listaEquipes, set_listaEquipes] = useState([])
  async function carregarEquipes() {
    const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/equipe/operacao/listar?id_filial=${id_filial_usuario}`, {
      headers: {
        Authorization: token
      }
    })
    if (resposta.data.codigo != 200) {
      toast.error(resposta.data.message)
    }
    else {
      set_listaEquipes(resposta.data.resultado)
    }
    set_mostrarModalCarregando(false)
  }

  async function insert(e: any) {
    e.preventDefault()
    const dados = {
      id_filial: id_filial_usuario,
      id_equipe: id_equipe,
      operacao: "",
      inicio: inicio,
      fim: fim,
      ativo: status,
      usuario_cadastro: getSessionData("NomeUsuario")
    }

    if (inicio > fim) {

      toast.error("Horário inicio não pode ser maior que horario fim.")
    }
    else {
      const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/agenda`, dados, {
        headers: {
          Authorization: token
        }
      })
      if (resposta.data.codigo != 200) {
        toast.error(resposta.data.message)
      }
      else {
        toast.success(resposta.data.message)
        setTimeout(function () {
          navigate(-1)
        }, 2000)
      }
    }
  }

  async function update(e: any) {
    e.preventDefault()
    const dados = {
      id_filial: id_filial_usuario,
      id_equipe: id_equipe,
      id_agenda_equipe: info.id,
      operacao: operacao,
      inicio: inicio,
      fim: fim,
      ativo: status,
      usuario_cadastro: getSessionData("NomeUsuario")
    }
    const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/editar/agenda/equipe`, dados, {
      headers: {
        Authorization: token
      }
    })
    if (resposta.data.codigo != 200) {
      toast.error(resposta.data.message)
    }
    else {
      toast.success(resposta.data.message)
      setTimeout(function () {
        navigate(-1)
      }, 2000)
    }
  }

  async function carregarAgendaEquipe() {
    const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/agenda/byid?id_filial=${id_filial_usuario}&id_agenda_equipe=${info.id}`, {
      headers: {
        Authorization: token
      }
    })
    if (resposta.data.codigo != 200) {
      toast.error(resposta.data.message)
    }
    else {

      set_id_agenda_equipe(info.id)
      set_id_equipe(resposta.data.agenda_equipe[0].id_equipe)
      set_nome_equipe(resposta.data.agenda_equipe[0].equipe)
      set_operacao(resposta.data.agenda_equipe[0].operacao)
      setStatus(resposta.data.agenda_equipe[0].ativo)

      //FORMATANDO TEMPO INICIO
      const dataObjInicio = new Date(resposta.data.agenda_equipe[0].inicio)

      // Obtendo as horas, minutos e segundos
      let horas_inicio = dataObjInicio.getUTCHours()
      let minutos_inicio = dataObjInicio.getUTCMinutes()
      let segundos_inicio = dataObjInicio.getUTCSeconds()

      // Formatando a string de hora desejada
      let tempoInicio_formatado = `${horas_inicio.toString().padStart(2, '0')}:${minutos_inicio.toString().padStart(2, '0')}:${segundos_inicio.toString().padStart(2, '0')}`
      setInicio(tempoInicio_formatado)

      //FORMATANDO TEMPO FIM
      const dataObjFim = new Date(resposta.data.agenda_equipe[0].fim)

      // Obtendo as horas, minutos e segundos
      let horas_fim = dataObjFim.getUTCHours()
      let minutos_fim = dataObjFim.getUTCMinutes()
      let segundos_fim = dataObjFim.getUTCSeconds()

      // Formatando a string de hora desejada
      let tempoFim_formatado = `${horas_fim.toString().padStart(2, '0')}:${minutos_fim.toString().padStart(2, '0')}:${segundos_fim.toString().padStart(2, '0')}`
      setFim(tempoFim_formatado)
    }
  }

  useEffect(function () {
    set_mostrarModalCarregando(true)
    if (info.acao != "novo") {
      carregarAgendaEquipe()
    }
    carregarEquipes()
  }, [])

  return (
    <>
      <Card>
        <Form onSubmit={info.id == "novo" ? insert : update}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Agenda Equipe"
                        name="id_agenda_equipe"
                        type="text"
                        placeholder="ID Agenda Equipe"
                        readonly
                        value={id_agenda_equipe}
                        onChange={set_id_agenda_equipe}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Equipe"
                        name="id_sala"
                        type="text"
                        placeholder="ID Equipe"
                        readonly
                        value={id_equipe}
                        onChange={set_id_equipe}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"

                      />
                    </Col>
                    {/*<Col sm={4}>
                      <SelectInput
                        value={nome_equipe || ''}
                        onChange={set_nome_equipe}
                        options={listaEquipes}
                        placeholder="Equipe"
                        disabed={info.acao == "visualizar" ? true : false}
                      />
                    </Col> */}
                    <Col sm={4}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Equipe
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={nome_equipe}
                          onChange={function (e) {
                            set_id_equipe(e.target.value)
                          }}
                          label="Equipe"
                          required
                          readOnly={info.acao == "visualizar" ? true : false}
                        >
                          <MenuItem value={nome_equipe} hidden={true}>
                            <em>{nome_equipe}</em>
                          </MenuItem>
                          {listaEquipes.map((equipe: any) => (
                            <MenuItem value={equipe.id_equipe} data-master={equipe.equipe} onClick={function () {
                              set_operacao(equipe.descricao)
                              set_nome_equipe(equipe.equipe)
                            }}>
                              {equipe.equipe}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2} hidden>
                      <InputSemBorda
                        label="Operação"
                        name="desctiposala"
                        type="text"
                        placeholder="Operação"
                        readonly
                        value={operacao || ''}
                        onChange={() => { }}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                      {/* <SelectInput
                        value={selectedTipoSala || ''}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedTipoSala(e.target.value)}
                        options={tipoSalaOptions}
                        placeholder="Equipe"
                      /> */}
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={info.acao == "visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>



                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Início"
                        name="inicio"
                        type="time"
                        placeholder="Início"
                        readonly={info.acao == "visualizar" ? true : false}
                        value={inicio || ""}
                        onChange={setInicio}
                        required
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Fim"
                        name="fim"
                        type="time"
                        placeholder="Fim"
                        readonly={info.acao === "visualizar" ? true : false}
                        value={fim || ""}
                        onChange={setFim}
                        required
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                invisivel={false}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={info.acao == "visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalLoading show={mostrarModalCarregando} />
    </>
  );
};
export default FormAgendaSala;
