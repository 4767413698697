import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import Tabela from "../../../components/Formularios/Table";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"
import ApiGet from "../../../api/endPoints/useGet";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";



function ListarEquipes() {
    const id_filial_usuario = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";
    const id_perfil = getSessionData("id_perfil")

    const visualizar = (dados: any) => {
        const id = dados.id_equipe;
        navigate("/cadastro/equipes/operacional/visualizar/" + id);
    }

    const editar = async (dados: any) => {
        const id = dados.id_equipe;
        navigate("/cadastro/equipes/operacional/editar/" + id)
    }

    const excluir = async (dados: any) => {
        const id = dados.id_equipe

        await showConfirmationDialog(
            "Excluir Código do Sitema",
            `Tem certeza que deseja excluir a equipe \n ${dados.equipe}?`,
            "question"
        ).then(async function (resposta) {
            if (resposta.confirmed) {
                const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/del/equipe?id_filial=${getSessionData("DadosIdfilial")}&id_equipe=${id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                if (resposta.data.codigo != 200) {
                    toast.error(resposta.data.message)
                    carregarEquipesListar()
                }
                else {
                    toast.success(resposta.data.message)
                    carregarEquipesListar()
                }
            }
        })
    }

    interface Action {
        icon: JSX.Element;
        name: string;
    }

    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/cadastro/equipes/operacional/criar/novo")}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    const navigate = useNavigate()

    //depositantes
    const [equipes, set_equipes] = useState([])

    const colunas: ITabela[] = [

        { titulo: "Código", acesso: `id_equipe` },
        { titulo: "Equipe", acesso: "equipe" },
        { titulo: "Operação", acesso: "descricao" },
    ]

    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    //permissões de alteração
    const [editarEquipeOperacional, set_editarEquipeOperacional] = useState(true) //-- não tem permissão para editar (true)
    const [verEquipeOperacional, set_verEquipeOperacional] = useState(true) //-- não tem permissão para ver (true)
    const [excluirEquipeOperacional, set_excluirEquipeOperacional] = useState(true) // -- não tem permissão para excluir (true)
    const [criarEquipeOperacional, set_criarEquipeOperacional] = useState(true) // -- não tem permissão para criar (true)

    async function CarregarPermissões(id_perfil: any) {
        set_mostrarModalCarregando(true)
        const headers = {
            Authorization: token,
        }
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: id_perfil,
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].visualizarEquipeOperacional === 1) {
                set_verEquipeOperacional(false)
            }
            if (response[0].incluirEquipeOperacional === 1) {
                set_criarEquipeOperacional(false)
            }
            if (response[0].EditarEquipeOperacional === 1) {
                set_editarEquipeOperacional(false)
            }
            if (response[0].ExcluirEquipeOperacional === 1) {
                set_excluirEquipeOperacional(false)
            }


        })
        set_mostrarModalCarregando(false)
    }

    // async function carregarEquipesListar() {
    //     set_mostrarModalCarregando(true)

    //     const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/equipes?id_filial=${id_filial_usuario}&id_vinculo=${getSessionData("isPerfilVinculo")}`, {
    //         headers: {
    //             authorization: token
    //         }
    //     })

    //     if (resposta.data.codigo != 200) {
    //         toast.error(resposta.data.message || "Erro ao listar equipes")
    //     }
    //     else {
    //         console.log(resposta)
    //         set_equipes(resposta.data)
    //     }
    //     set_mostrarModalCarregando(false)
    // }

    async function carregarEquipesListar() {
        set_mostrarModalCarregando(true)

        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/equipes?id_filial=${id_filial_usuario}&id_vinculo=${getSessionData("isPerfilVinculo")}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            console.log(resposta)
            set_mostrarModalCarregando(false)
            set_equipes(resposta.data.equipes)
        }).catch(function (erro) {
            set_mostrarModalCarregando(false)
            // console.lo(erro)
            toast.error("Erro ao listas equipes")
        })
    }



    useEffect(function () {
        carregarEquipesListar()
        CarregarPermissões(id_perfil)
    }, [])

    // Função para realizar a busca
    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase();

        if (termoBusca == "") {
            carregarEquipesListar()
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = equipes.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.equipe.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)

            })

            set_equipes(resultados)
        }
    }

    useEffect(function () {
        Search(busca)
    }, [busca])

    return (
        <>
            <div className="container-fluid mt-2">
                <ContainerTitulo titulo="Listagem de Equipes" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        hidden={criarEquipeOperacional}
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <div className="d-flex my-2 gap-1 ">
                                        <Col sm={11}>
                                            <InputSemBorda
                                                name="CNPJ"
                                                type="text"
                                                value={busca}
                                                onChange={set_busca}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Procurar por equipe"
                                            />
                                        </Col>
                                    </div>
                                </Row>




                                <Row>
                                    <Tabela
                                        coluna={colunas}
                                        dados={equipes}
                                        itemsPerPage={10}
                                        onVisuClick={visualizar}
                                        onEditClick={editar}
                                        onDeleteClick={excluir}
                                        id="codigo"
                                        permissao_editar={editarEquipeOperacional}
                                        permissao_visualizar={verEquipeOperacional}
                                        permissao_excluir={excluirEquipeOperacional}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalLoading show={mostrarModalCarregando} />
        </>
    )
}

export default ListarEquipes