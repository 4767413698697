import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import FormAgendaSala from "./components/formulario";

export function AgendaSalaIncluir() {
  const params = useParams();
  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Incluir Agenda Equipe" />
        <FormAgendaSala id={params.id} acao="novo" />
      </div>
    </>
  );
}
