import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../types/UsuarioInterface";

import { toast } from "react-toastify";
import ApiGet from "../../../api/endPoints/useGet";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../api/endPoints/useDelete";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";

export function AgendaSalas() {
  const id_empresa_usuario = getSessionData("isPerfilVinculo")
  const id_filial_usuario = getSessionData("DadosIdfilial")
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_perfil = getSessionData("id_perfil")
  const navigate = useNavigate()

  interface Action {
    icon: JSX.Element;
    name: string;
  }

  const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/cadastro/agendaequipe/novo")}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualizar = (dados: any) => {
    const idagendaequipe = dados.id_agenda_equipe;
    navigate("/cadastro/agendaequipe/ver/" + idagendaequipe);
  };

  const editar = async (dados: any) => {
    const idagendaequipe = dados.id_agenda_equipe;
    navigate("/cadastro/agendaequipe/editar/" + idagendaequipe);
  };

  const excluir = async (dados: any) => {
    const id_agenda_equipe = dados.id_agenda_equipe
    await showConfirmationDialog(
      "Excluir Agenda da Equipe",
      `Tem certeza que deseja excluir Agenda da equipe \n ${dados.equipe}?`,
      "question"
    )
      .then(async (result) => {
        if (result.confirmed) {
          const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/agenda?id_filial=${id_filial_usuario}&id_agenda_equipe=${id_agenda_equipe}`, {
            headers: {
              Authorization: token
            }
          })

          if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message)
          }
          else {
            toast.success(resposta.data.message)
          }
          buscarDados()
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  }

  const colunas: ITabela[] = [
    { titulo: "ID Agenda", acesso: "id_agenda_equipe" },
    { titulo: "Equipe", acesso: "equipe" },
    { titulo: "Início", acesso: "hora_inicio" },
    { titulo: "Fim", acesso: "hora_fim" }
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const buscarDados = async () => {
    set_mostrarModalCarregando(true)
    const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/agenda/equipes?id_filial=${id_filial_usuario}&id_vinculo=${getSessionData("isPerfilVinculo")}`, {
      headers: {
        Authorization: token
      }
    })
    if (resposta.data.codigo != 200) {
      toast.error(resposta.data.message)
    }
    else {
      setDados(resposta.data.agenda_equipe)
    }
    set_mostrarModalCarregando(false)
  };



  //permissões de alteração
  const [editarAgendaEquipe, set_editarAgendaEquipe] = useState(true) //-- não tem permissão para editar (true)
  const [verAgendaEquipe, set_verAgendaEquipe] = useState(true) //-- não tem permissão para ver (true)
  const [excluirAgendaEquipe, set_excluirAgendaEquipe] = useState(true) // -- não tem permissão para excluir (true)
  const [criarAgendaEquipe, set_criarAgendaEquipe] = useState(true) // -- não tem permissão para criar (true)

  async function CarregarPermissões(id_perfil: any) {

    const headers = {
      Authorization: token,
    }
    const dados = {
      url: "/perfil/carregar/:idperfil",
      parametros: {
        idperfil: id_perfil,
      },
      headers: headers,
    };
    ApiGet(dados).then(function (response) {
      if (response[0].visualizarAgendaEquipe === 1) {
        set_verAgendaEquipe(false)
      }
      if (response[0].incluirAgendaEquipe === 1) {
        set_criarAgendaEquipe(false)
      }
      if (response[0].EditarAgendaEquipe === 1) {
        set_editarAgendaEquipe(false)
      }
      if (response[0].ExcluirAgendaEquipe === 1) {
        set_excluirAgendaEquipe(false)
      }


    })
  }


  // Função para realizar a busca
  const [busca, set_busca] = useState("")
  function Search(string: any) {
    // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
    let termoBusca = string.toLowerCase();

    if (termoBusca == "") {
     buscarDados()
    }
    else {
      // Use a função filter para encontrar as pessoas que correspondem à busca
      const resultados = dados.filter(function (info: any) {

        // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
        let buscando = info.equipe.toLowerCase()

        // Verifique se contém o termo de busca
        return buscando.includes(termoBusca)

      })

      setDados(resultados)
    }
  }

  useEffect(function () {
    Search(busca)
  }, [busca])


  useEffect(() => {
    CarregarPermissões(id_perfil)
    buscarDados();
  }, []);
  //#endregion

  return (
    <>
      <div className="container-fluid">
        <ContainerTitulo titulo="Listagem Agenda Equipes" />
      </div>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Row>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  direction="left"
                  sx={{
                    right: 0,
                    position: "absolute",
                  }}
                  icon={<SpeedDialIcon />}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                    />
                  ))}
                </SpeedDial>
              </Row>
              <Row>
                <div className="d-flex my-2 gap-1 ">
                  <Col sm={10}>
                    <InputSemBorda
                      name="pesquisa"
                      type="text"
                      value={busca}
                      onChange={set_busca}
                      classNameInputsSemBorda=" bordasInferiorInput"
                      placeholder="Procurar por equipe"
                    />
                  </Col>
                </div>
              </Row>
              <Row>
                <Tabela
                  coluna={colunas}
                  dados={dados}
                  itemsPerPage={10}
                  onVisuClick={visualizar}
                  onEditClick={editar}
                  onDeleteClick={excluir}
                  id="codigo"
                  permissao_editar={editarAgendaEquipe}
                  permissao_visualizar={verAgendaEquipe}
                  permissao_excluir={excluirAgendaEquipe}
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ModalLoading show={mostrarModalCarregando} label="Carregando Agenda Sala" />
    </>
  );
}
