import React from "react";

type EventContentProps = {
  timeText: string;
  eventTitle: string;
  subtitle: string;
  detail: string;
};

const EventContent: React.FC<EventContentProps> = (props) => {
  const { timeText, eventTitle, subtitle, detail } = props;

  return (
    <>
    <div className="evento-customizado">

      <div className="row mt-0">
        <b>{timeText}</b>
        <br />
        <i>Status: {eventTitle}</i>
        <p>Tipo: {subtitle}</p>
      </div>
    </div>
    </>
  );
};

export default EventContent;
