import Cards from "../../components/Formularios/Cards/Cards";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import { Row, Col, Card } from "react-bootstrap";
import { mdiCalendarMonthOutline, mdiAccountCheck } from "@mdi/js";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { getSessionData } from "../../utils/storageUtils";
import ApiGet from "../../api/endPoints/useGet";
import { toast } from "react-toastify";
import ApiPost from "../../api/endPoints/usePost";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
//import { BarChart } from '@mui/x-charts/BarChart';
import React from "react";
import { Chart } from "react-google-charts";
import { ApexOptions } from "apexcharts";
import axios from "axios";
import Icon from '@mdi/react';
import { MdBookmarkAdded } from "react-icons/md";
import { FaTruckLoading } from "react-icons/fa";
import { FaTruckArrowRight } from "react-icons/fa6"
import { FaTruckFast } from "react-icons/fa6"

export function Dashboards() {
  const id_filial = getSessionData("DadosIdfilial") || "";
  const token = getSessionData("MultfilialWebToken")

  const [datainicio, setDataInicio] = useState(new Date().toISOString().slice(0, 10))
  const [datafim, setDataFim] = useState(new Date().toISOString().slice(0, 10))

  const [aguardando, set_aguardando] = useState([])
  const [emOp, setEmOp] = useState([])
  const [concluido, set_concluido] = useState([])
  const [agendados, set_agendados] = useState([])
  const [cancelados, set_cancelados] = useState([])

  function TotalAgendados() {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/consultar/agendamentos/total?id_filial=${id_filial}&data_inicio=${datainicio}&data_fim=${datafim}`, {
      headers: {
        Authorization: token
      }
    })
      .then(function (resposta) {

        if (resposta.data.codigo != 200) {
          toast.error(resposta.data.message)
        }
        else {
          const agendamentos = resposta.data.agendamentos

          const agendamentosAguardando = agendamentos.filter(function (agendamento: any) {

            return agendamento.status == "AGUARDANDO OPERACAO"
          })

          const agendamentosEmOp = agendamentos.filter(function (agendamento: any) {
            return agendamento.status == "EM OPERACAO"
          })

          const agendamentosConcluidos = agendamentos.filter(function (agendamento: any) {

            return agendamento.status == "CONCLUIDO"
          })

          const agendamentosAgendados = agendamentos.filter(function (agendamento: any) {

            return agendamento.status == "AGENDADO"
          })

          const agendamentosCancelados = agendamentos.filter(function (agendamento: any) {

            return agendamento.status == "CANCELADO"
          })
          set_aguardando(agendamentosAguardando)
          setEmOp(agendamentosEmOp)
          set_concluido(agendamentosConcluidos)
          set_agendados(agendamentosAgendados)
          set_cancelados(agendamentosCancelados)
        }
      }).catch(function (erro) {

        toast.error(erro)
      })
  }

  const data = [
    ["Element", "Agendamentos", { role: "style" }],
    ["AGENDADO", agendados.length, "#1D2934"], // RGB value
    ["AGUARDANDO OP", aguardando.length, "#3788D8"],
    ["EM OPERAÇÃO", emOp.length, "#E6B33D"],
    ["CONCLUÍDO", concluido.length, "#015340"],
    ["CANCELADO", cancelados.length, "#950000"]
  ]

  useEffect(() => {

    TotalAgendados()
  }, [])

  return (
    <>
      <div className="page-content">
        <ContainerTitulo titulo="Dashboard" />
      </div>
      <Row>
        <Col className="mt-3" sm={12}>
          <Card>
            <Card.Body className="p-2">
              <Row>
                <Col sm={2}>
                  <InputLabel style={{ color: '#000', fontSize: '16px', margin: '0 0 -6px 0' }}>
                    Data Início
                  </InputLabel>
                  <InputSemBorda
                    name="datainicio"
                    type="date"
                    value={datainicio}
                    onChange={setDataInicio}
                    onBlur={function () {
                      if (datainicio != "" && datafim != "") {
                        TotalAgendados()
                      }
                      else {
                        console.error("data inválida")
                      }
                    }}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                    placeholder="Data Início"
                  // label="Data Início"
                  />
                </Col>
                <Col sm={2}>
                  <InputLabel style={{ color: '#000', fontSize: '16px', margin: '0 0 -6px 0' }}>
                    Data Fim
                  </InputLabel>
                  <InputSemBorda
                    name="datafim"
                    type="date"
                    value={datafim}
                    onChange={setDataFim}
                    onBlur={function () {
                      if (datainicio != "" && datafim != "") {
                        TotalAgendados()
                      }
                      else {
                        console.error("data inválida")
                      }
                    }}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                    placeholder="Data Fim"
                  // label="Data Fim"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <div className="card">
            <div className="card-body d-flex flex-column align-items-end">
              {/*<FiUserCheck size={"50px"} color="#f58619"/> */}
              <FaTruckArrowRight size={55} />
              <h6>Aguardando Operação</h6>
              <h2>{aguardando.length}</h2>
            </div>
            <div className="card-footer">
              <h6>
                Agendamentos aguardando operação
              </h6>
            </div>
          </div>
        </Col>
        <Col sm={4}>
          <div className="card">
            <div className="card-body d-flex flex-column align-items-end">
              {/*<FiUserCheck size={"50px"} color="#f58619"/> */}
              <FaTruckLoading size={55} />
              <h6>Em Operação</h6>
              <h2>{emOp.length}</h2>
            </div>
            <div className="card-footer">
              <h6>

                Agendamentos em operação
              </h6>
            </div>
          </div>
        </Col>
        <Col sm={4}>
          <div className="card">
            <div className="card-body d-flex flex-column align-items-end">
              {/*<FiUserCheck size={"50px"} color="#f58619"/> */}
              <FaTruckFast size={55} />
              <h6>Concluídos</h6>
              <h2>{concluido.length}</h2>
            </div>
            <div className="card-footer">
              <h6>

                Agendamentos concluídos
              </h6>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {/*        <Col sm={6}>
          <Card style={{ height: 248 }}>
            <Card.Header>
              <Card.Title as="h5">Agendamentos por Status</Card.Title>
            </Card.Header>
            <Card.Body>
              <BarChart
                categoriesData={dadosStatus.series[0].data}
                seriesData={dadosQtdeStatus.series}
                colors={['#FF4560', '#0090FF', '#FFEB3B', '#00E396']}
                height={170}
                distributed={true}
                labels={false}
              />
            </Card.Body>
          </Card>
        </Col> */}
        <Chart chartType="ColumnChart" width="100%" height="400px" data={data} />
      </Row>
      <Row>

        {/* <Col sm={12}>
          <Card style={{ height: 380 }}>
            <Card.Header>
              <Box display="flex" alignItems="center">
                <Card.Title as="h5">Agendamentos por Profissional</Card.Title>
                <FormControl size="small" style={{ marginLeft: 15 }}>
                  <InputLabel id="limite-label">TOP</InputLabel>
                  <Select
                    labelId="limite-label"
                    defaultValue={5}
                    value={limite}
                    onChange={(e) => setLimite(Number(e.target.value))}
                    label="TOP"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Card.Header>
            <Card.Body>
              <BarChart categoriesData={dadosProfissional.series[0].data} seriesData={dadosQtdeAgendamentos.series} height={290} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card style={{ height: 380 }}>
            <Card.Header>
              <Card.Title as="h5">Agendamentos por Dia</Card.Title>
            </Card.Header>
            <Card.Body>
              <BarChart categoriesData={dadosDia.series[0].data} seriesData={dadosQtdeAgendamentosdia.series} height={300} />
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </>
  );
}


export default Dashboards