import FormularioEquipeOperacional from "./formulario/formulario";
import { useParams } from "react-router-dom"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"

function EquipeIncluir() {

    const params = useParams()

    return (
        <>
            <div className="container-fluid mt-2">
            <ContainerTitulo titulo="Nova Equipe" />
                <FormularioEquipeOperacional idequipe={params.id} acao="novo"/>
            </div>

        </>
    )
}
export default EquipeIncluir