import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { resolvePath, useNavigate } from "react-router-dom";
import axios from "axios";
import { Api } from "../../../../api/api";
import ApiPost from "../../../../api/endPoints/usePost";
import { getSessionData } from "../../../../utils/storageUtils";
import { toast } from "react-toastify";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";

function FormularioDepositante(id_depositante: any, acao: any) {

    const navigate = useNavigate()

    //guia para paginação da TabPanel
    const [guia, set_guia] = useState("1")
    function setGuia(event: React.SyntheticEvent, guia: string) {
        set_guia(guia)
    }

    //dados do depositante -- inicio
    const [cnpj, set_cnpj] = useState("")
    const [razao_social, set_razao_social] = useState("")
    const [fantasia, set_fantasia] = useState("")
    const [email, set_email] = useState("")
    const [contato, set_contato] = useState("")
    const [telefone_contato, set_telefone_contato] = useState("")
    const [status, set_status] = useState(false)
    //dados do depositante -- fim 

    async function consultarDadosCnpj() {
        const cnpj_formatado = cnpj.replace(/[.\-/]/g, '')

        set_cnpj(cnpj_formatado)
        if (cnpj_formatado.length >= 14) {
            const response = await axios.get(`https://publica.cnpj.ws/cnpj/${cnpj_formatado}`)
            set_razao_social(response.data.razao_social)
            set_fantasia(response.data.razao_social)
        }


    }

    async function insert(e: any) {
        e.preventDefault()
        const dados = {
            cnpj: cnpj,
            razao_social: razao_social,
            fantasia: fantasia,
            email: email,
            contato: contato,
            telefone_contato: telefone_contato,
            usuario_add: getSessionData("NomeUsuario"),
            id_filial: getSessionData("DadosIdfilial"),
            status: status
        }

        ApiPost("/insert/depositante", dados).then(function (resposta) {

            if (resposta.codigo == 200) {
                toast.success(resposta.message)
                setTimeout(function () {
                    navigate(-1)
                }, 2000)
            }
            else {
                toast.error(resposta.message + ": " + resposta.erro)
            }
        }).catch(function (erro) {

            toast.error("Erro ao cadastrar Depositante: " + erro)
        })
    }

    async function update(e: any) {
        e.preventDefault()

        const dados = {
            cnpj: cnpj,
            razao_social: razao_social,
            fantasia: fantasia,
            email: email,
            contato: contato,
            telefone_contato: telefone_contato,
            id_filial: getSessionData("DadosIdfilial"),
            id_depositante: id_depositante.id_depositante,
            status: status
        }



        const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/update/depositante`, dados)
        if (resposta.data.codigo == 200) {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
        }
        else {
            toast.error(resposta.data.message + ": " + resposta.data.erro)
        }

    }

    async function pegarInfoDepositante() {

        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/one/depositante?id_filial=${getSessionData("DadosIdfilial")}&id=${id_depositante.id_depositante}`)

        if (resposta.data.codigo != 200) {
            toast.error("Erro ao listar depositante")
        }
        else {
            set_cnpj(resposta.data.depositante[0].cnpj)
            set_razao_social(resposta.data.depositante[0].razao_social)
            set_fantasia(resposta.data.depositante[0].fantasia)
            set_contato(resposta.data.depositante[0].contato)
            set_telefone_contato(resposta.data.depositante[0].tel)
            set_email(resposta.data.depositante[0].lista_email)
            set_status(resposta.data.depositante[0].status)
        }

    }

    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    useEffect(function () {
        set_mostrarModalCarregando(true)
        if (id_depositante.id_depositante != "novo") {
            pegarInfoDepositante()
        }
        set_mostrarModalCarregando(false)
    }, [])


    return (
        <>
            <Card>
                <Form onSubmit={id_depositante.id_depositante == "novo" ? insert : update}>
                    <Card.Body>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={setGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab label="Dados Básicos" value="1" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <Row>
                                        <Col sm={2}>
                                            <InputSemBorda
                                                label="ID Depositante"
                                                name="id_filial"
                                                type="text"
                                                placeholder="ID Filial"
                                                readonly
                                                value={id_depositante.id_depositante == "novo" ? "novo" : id_depositante.id_depositante}
                                                onChange={set_guia}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                label="CNPJ depositante"
                                                name="cnpj"
                                                type="text"
                                                placeholder="CNPJ"
                                                required
                                                readonly={id_depositante.acao == "visualizar" || id_depositante.acao == "editar" ? true : false}
                                                value={cnpj}
                                                onBlur={consultarDadosCnpj}
                                                onChange={set_cnpj}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <br />
                                            <label htmlFor="" className="form-label me-2">
                                                Ativo
                                            </label>
                                            <Switch
                                                checked={status}
                                                onChange={() => set_status(!status)}
                                                disabled={id_depositante.acao == "visualizar" ? true : false}
                                            />
                                        </Col>
                                        <Col sm={10}></Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                required
                                                label="Razão Social"
                                                name="razao"
                                                type="text"
                                                placeholder="Razão Social"
                                                readonly={id_depositante.acao == "visualizar" || id_depositante.acao == "editar" ? true : false}
                                                value={razao_social}
                                                onChange={set_razao_social}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                required
                                                label="Fantasia"
                                                name="fantasia"
                                                type="text"
                                                placeholder="Fantasia"
                                                readonly={id_depositante.acao == "visualizar" || id_depositante.acao == "editar" ? true : false}
                                                value={fantasia}
                                                onChange={set_fantasia}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={10}></Col>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="Email"
                                                name="Email"
                                                type="email"
                                                placeholder="Email"
                                                required
                                                readonly={id_depositante.acao == "visualizar" ? true : false}
                                                value={email}
                                                onChange={set_email}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="Contato"
                                                name="Contato"
                                                type="text"
                                                placeholder="Contato"
                                                required
                                                readonly={id_depositante.acao == "visualizar" ? true : false}
                                                value={contato}
                                                onChange={set_contato}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <InputSemBorda
                                                label="Telefone Contato"
                                                name="Telefone Contato"
                                                type="text"
                                                placeholder="Telefone do Contato"
                                                required
                                                readonly={id_depositante.acao == "visualizar" ? true : false}
                                                value={telefone_contato}
                                                onChange={set_telefone_contato}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                    </Row>

                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                                disabled={id_depositante.acao === "visualizar" ? true : false}
                            />
                        </div>
                    </Card.Footer>
                </Form>
            </Card>

            <ModalLoading show={mostrarModalCarregando}/>

        </>
    )

}

export default FormularioDepositante