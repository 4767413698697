import { getSessionData } from "../../utils/storageUtils";
import { useEffect, useState } from "react";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo";
import { Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Chart } from "react-google-charts";
import axios from "axios";
import { FaTruckArrowRight } from "react-icons/fa6"
import { AiFillAlert } from "react-icons/ai"
import { AiFillFileUnknown } from "react-icons/ai"
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";

function TorreDeControle() {

    const id_filial = getSessionData("DadosIdfilial") || "";
    const token = getSessionData("MultfilialWebToken")

    // const hoje = new Date().toISOString().slice(0, 10)
    const [datainicio, setDataInicio] = useState("")
    const [datafim, setDataFim] = useState("")

    const [mostrarLoading, set_mostrarLoading] = useState(false)

    const [dados, set_dados] = useState<any>([])
    function CarregarInformacoes() {

        set_mostrarLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/all/torre/controle?id_filial=${id_filial}&data_inicio=${datainicio}&data_fim=${datafim}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {

            if (resposta.data == null) {
                resposta.data = 0
            }

            if (resposta.data.codigo == 200) {

                set_mostrarLoading(false)
                set_dados(resposta.data.dados)
                console.log("--------------")
                console.log(resposta.data.dados)

                //pegando pedidos pendentes
                const novosPedidos = [["", "", { role: "style" }]]

                if (resposta.data.dados.pedidosPendentesCliente.length == 0) {
                    const novoPedido: any = []
                    novosPedidos.push(novoPedido)
                } else {

                    for (let i = 0; i < resposta.data.dados.pedidosPendentesCliente.length; i++) {
                        const novoPedido: any = [resposta.data.dados.pedidosPendentesCliente[i].Fantasia, resposta.data.dados.pedidosPendentesCliente[i].TotalPedidosPorCliente, `#0153${40 + (i * 10)}`]
                        novosPedidos.push(novoPedido)
                    }
                }
                set_pedidosPendentes(novosPedidos)


                //pegando pedidos pendentes picking
                const pedidosPicking = [["", "", { role: "style" }]]

                for (let i = 0; i < resposta.data.dados.pedidosPendentesLiberadosPicking.length; i = i + 1) {
                    const pedido: any = [resposta.data.dados.pedidosPendentesLiberadosPicking[i].Fantasia, resposta.data.dados.pedidosPendentesLiberadosPicking[i].TotalPedidosPorCliente, `#0153${40 + (i * 10)}`]
                    pedidosPicking.push(pedido)
                }

                set_pedidosPendentesPicking(pedidosPicking)

                //pegando OS sem baixa
                const osSemBaixa = [["", "", { role: "style" }]]
                for (let i = 0; i < resposta.data.dados.OsSemBaixa.length; i = i + 1) {

                    const data = resposta.data.dados.OsSemBaixa[i].DATA_CARGA_PREVISTO.split("T")[0].split("-")

                    const os: any = [data[2] + "/" + data[1] + "/" + data[0], resposta.data.dados.OsSemBaixa[i].numOSsemBaixa, `#0153${40 + (i * 10)}`]
                    osSemBaixa.push(os)
                }

                console.log(osSemBaixa)

                set_osSemBaixa(osSemBaixa)
            }
            else {

                set_mostrarLoading(false)
                toast.error(resposta.data.message)
            }
        }).catch(function (erro) {

            set_mostrarLoading(false)
            toast.error(erro)
        })
    }

    /*const ProcessosStatus : any = [
        ["Element", "Processos", { role: "style" }],
        ["ENTRADA", dados.statusTipoProcesso[0].qtd, "#1D2934"], // RGB value
        ["SAIDA", dados.statusTipoProcesso[1].qtd, "#3788D8"],
        ["EM OPERAÇÃO", 9, "#E6B33D"],
        ["CONCLUÍDO", 2, "#015340"],
        ["CANCELADO", 1, "#950000"]
    ]*/

    useEffect(function () {

        CarregarInformacoes()

        const intervalId = setInterval(() => {
            CarregarInformacoes();
        }, 30000)

        return () => clearInterval(intervalId)
    }, [])

    /*useEffect(() => {
        const intervalId = setInterval(() => {
            CarregarInformacoes();
        }, 30000);

        return () => clearInterval(intervalId);
    }, [datainicio, datafim])*/


    const [pedidosPendentes, set_pedidosPendentes] = useState<any>([])
    const [pedidosPendentesPicking, set_pedidosPendentesPicking] = useState<any>([])
    const [osSemBaixaArray, set_osSemBaixa] = useState<any>([])

    return (

        <>
            <div className="page-content">
                <ContainerTitulo titulo="Torre de Controle" />
            </div>
            <Row>
                <Col sm={4}>

                    <div className="bg-white p-3">
                        <h4 className="text-center pt-2">Status de processos</h4>


                        <Chart chartType="ColumnChart" width="100%" height="400px"

                            data={[
                                ["", "", { role: "style" }],
                                [dados.statusTipoProcesso && dados.statusTipoProcesso[0] ? dados.statusTipoProcesso[0].tipo : '---', dados.statusTipoProcesso && dados.statusTipoProcesso[0] ? dados.statusTipoProcesso[0].qtd : "---", "#1D2934"],
                                [dados.statusTipoProcesso && dados.statusTipoProcesso[1] ? dados.statusTipoProcesso[1].tipo : '---', dados.statusTipoProcesso && dados.statusTipoProcesso[1] ? dados.statusTipoProcesso[1].qtd : "---", "#3788D8"],
                                [dados.statusTipoProcesso && dados.statusTipoProcesso[2] ? dados.statusTipoProcesso[2].tipo : '---', dados.statusTipoProcesso && dados.statusTipoProcesso[2] ? dados.statusTipoProcesso[2].qtd : "---", "#E6B33D"],
                                //[dados.statusTipoProcesso && dados.statusTipoProcesso[3] ? dados.statusTipoProcesso[3].tipo : '---', dados.statusTipoProcesso && dados.statusTipoProcesso[3] ? dados.statusTipoProcesso[3].qtd : "---", "#950000"],
                                [dados.statusTipoProcesso && dados.statusTipoProcesso[4] ? dados.statusTipoProcesso[4].tipo : '---', dados.statusTipoProcesso && dados.statusTipoProcesso[4] ? dados.statusTipoProcesso[4].qtd : "---", "#015340"],
                                //[dados.statusTipoProcesso && dados.statusTipoProcesso[5] ? dados.statusTipoProcesso[5].tipo : '---', dados.statusTipoProcesso && dados.statusTipoProcesso[5] ? dados.statusTipoProcesso[5].qtd : "---", "#017777"]// RGB value
                            ]} />
                    </div>
                </Col>
                <Col sm={4}>

                    <div className="bg-white p-2">
                        <h4 className="text-center pt-2">Pedidos Pendentes</h4>



                        <Chart chartType="ColumnChart" width="100%" height="400px" data={pedidosPendentes} />
                    </div>
                </Col>
                <Col sm={4}>

                    <div className="bg-white p-2">
                        <h4 className="text-center pt-2">Pedidos Liberados - Picking</h4>



                        <Chart chartType="ColumnChart" width="100%" height="400px" data={pedidosPendentesPicking} />
                    </div>
                </Col>
            </Row>
            <br />
            <Row>
                <Col sm={4}>

                    <div className="bg-white p-1">
                        <h4 className="text-center pt-2">OS sem baixa</h4>



                        <Chart chartType="ColumnChart" width="100%" height="400px" data={osSemBaixaArray} />
                    </div>
                </Col>
                <Col sm={4}>

                    <div className="bg-white p-1">
                        <h4 className="text-center pt-2">Pallets Bloqueados</h4>



                        <Chart chartType="ColumnChart" width="100%" height="400px" data={[
                            ["", "", { role: "style" }],
                            [dados.palletsBloq && dados.palletsBloq[0] ? "Bloqueados" : '---', dados.palletsBloq && dados.palletsBloq[0] ? dados.palletsBloq[0].bloq : "---", "#950000"],
                            [dados.palletsBloq && dados.palletsBloq[0] ? "Bloq. Fiscal" : '---', dados.palletsBloq && dados.palletsBloq[0] ? dados.palletsBloq[0].bloq_nf : "---", "#1D2934"],
                            [dados.palletsBloq && dados.palletsBloq[0] ? "Bloq. Sif" : '---', dados.palletsBloq && dados.palletsBloq[0] ? dados.palletsBloq[0].bloq_sif : "---", "#1D8888"]
                        ]} />
                    </div>
                </Col>
                <Col sm={4}>
                    <div className="bg-white text-center p-1">
                        <h4 className="pt-2">O.S pendente de faturamento</h4>
                        <AiFillFileUnknown size={40} />
                        <h2>{dados.qtdOSPendenteFaturamento || "---"}</h2>


                    </div>
                    <br />
                    <div className="bg-white text-center p-1">
                        <h4 className="pt-2">Ocorrências não concluidas</h4>
                        <AiFillAlert size={40} />
                        <h2>{dados.qtdOcorrencia || "---"}</h2>


                    </div>
                </Col>

            </Row>
            {/**
             * 
             * 
             * 
             * <Row className="mt-3">
                <Col sm={12}>
                    <Card>
                        <Card.Body className="p-2">
                            <Row>
                                <Col sm={2}>
                                    <InputLabel style={{ color: '#000', fontSize: '16px', margin: '0 0 -6px 0' }}>
                                        Data Início
                                    </InputLabel>
                                    <InputSemBorda
                                        name="datainicio"
                                        type="date"
                                        value={datainicio}
                                        onChange={setDataInicio}
                                        onBlur={function () {
                                            if (datainicio != "" && datafim != "") {
                                                CarregarInformacoes()
                                            }
                                            else {
                                                console.error("data inválida")
                                            }
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                        placeholder="Data Início"
                                    // label="Data Início"
                                    />
                                </Col>
                                <Col sm={2}>
                                    <InputLabel style={{ color: '#000', fontSize: '16px', margin: '0 0 -6px 0' }}>
                                        Data Fim
                                    </InputLabel>
                                    <InputSemBorda
                                        name="datafim"
                                        type="date"
                                        value={datafim}
                                        onChange={setDataFim}
                                        onBlur={function () {
                                            if (datainicio != "" && datafim != "") {
                                                CarregarInformacoes()
                                            }
                                            else {
                                                console.error("data inválida")
                                            }
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                        placeholder="Data Fim"
                                    // label="Data Fim"
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
             */}

            <ModalLoading show={mostrarLoading} />
        </>
    )
}

export default TorreDeControle