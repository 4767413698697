import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import { MdLocalPrintshop } from "react-icons/md";
import { mdiTrashCanOutline } from "@mdi/js";
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import DatalistInput from "react-datalist-input";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../components/Formularios/Buttons/Button";
import SelectInput from "../../shared/SelectInputs";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import { resolvePath, useNavigate } from "react-router-dom";
import axios from "axios";
import { Api } from "../../api/api";
import { getSessionData } from "../../utils/storageUtils";
import { toast } from "react-toastify";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import { ITabela } from "../../components/Formularios/Table/TabelaInterface"
import Tabela from "../../components/Formularios/Table";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import FileSaver from "file-saver"
import { ExcelPedido } from "../../layouts/excelPedido";
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";

function NovoPedido() {

    const id_filial_usuario = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";
    const id_perfil = getSessionData("id_perfil")
    const navigate = useNavigate()

    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    const [listaDepositantesByIdFilial, set_listaDepositantesByIdFilial] = useState<any>([])
    const [depositante, set_depositante] = useState("")

    async function carregarDepositantesListar() {

        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/usuario/depositante?id_usuario=${getSessionData("DadosUsuarioID")}&id_filial=${getSessionData("DadosIdfilial")}`)

        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message || "Erro ao listar depositantes")
        }
        else {

            set_listaDepositantesByIdFilial(resposta.data.depositante)
            if (listaDepositantesByIdFilial.length == 1) {
                set_depositante(resposta.data.depositante[0].fantasia)
            }
        }
        setDatalistKey((prevKey) => prevKey + 1)

    }

    //pedido
    const [data, set_data] = useState("")
    const [pedido, set_pedido] = useState("")
    const [disabledNomePedido, set_disabledNomePedido] = useState(false)

    //item
    const [item, set_item] = useState("")
    const [cod_item, set_cod_item] = useState("")
    const [qtd, set_qtd] = useState("")
    const [data_min, set_data_min] = useState("")
    const [lote, set_lote] = useState("")
    const [destinatario, set_destinatario] = useState("")
    const [roteiro, set_roteiro] = useState("")

    const [listaItens, set_listaItens] = useState<any>([])
    function addItemAlista(e: any) {
        set_mostrarModalCarregando(true)
        e.preventDefault()

        //formatando data min-validade
        let data_min_validade_formatada: any = ""
        if (data_min != "" && data_min != " " && data_min != null) {
            data_min_validade_formatada = data_min.split("-")
        }

        let novoItem = {
            cliente: depositante, //é o cnpj do depositante
            destinatario: destinatario,
            roteiro: roteiro,
            dt_carga: data,
            pedido: pedido,
            cod_prod: cod_item,
            item: item,
            qtde_volume: qtd,
            min_validade: data_min_validade_formatada != "" ? data_min_validade_formatada[2] + "-" + data_min_validade_formatada[1] + "-" + data_min_validade_formatada[0] : "",
            lote: lote
        }

        set_listaItens([...listaItens, novoItem])

        //limpar inputs
        set_cod_item("")
        set_item("")
        set_qtd("")
        set_lote("")
        set_data_min("")
        set_mostrarModalCarregando(false)
    }


    async function consultarNovoPedido() {
        set_mostrarModalCarregando(true)

        if (depositante == "" || depositante == " " || depositante == null ||
            data == "" || data == " " || data == null ||
            pedido == "" || pedido == " " || pedido == null) {
            toast.error("Preencha os campo obrigatórios (*)")
        }
        else {
            if (listaItens.length == 0) {
                toast.error("O pedido não tem itens preenchidos para envio.")
            }
            else {

                const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/enviar/pedido?app=1&id_filial=${id_filial_usuario}&cnpj_depositante=${depositante}&usuario=${getSessionData("NomeUsuario")}`, listaItens,
                    {
                        headers: {
                            Authorization: token
                        }
                    })

                if (resposta.data.codigo == 400) {
                    toast.error(resposta.data.message)
                }
                else {
                    toast.success(resposta.data.msg || resposta.data.message)

                    //limpar inputs
                    set_roteiro("")
                    set_destinatario("")
                    set_cod_item("")
                    set_item("")
                    set_qtd("")
                    set_lote("")
                    set_data_min("")
                    set_depositante("")
                    set_data("")
                    set_pedido("")
                    set_listaItens([])
                    set_listaProdutosDepositante([])
                    set_disabledNomePedido(false)
                }
            }
        }

        set_mostrarModalCarregando(false)
    }

    function removerItemDaLista(indice: any) {
        set_mostrarModalCarregando(true)
        // Crie uma cópia da listaItens, excluindo o item com o índice fornecido, pego tudo que seja
        //diferente da posição do indice
        const novaLista = listaItens.filter((_: any, i: any) => i != indice)

        // Atualize o estado com a nova lista
        set_listaItens(novaLista)
        set_mostrarModalCarregando(false)
    }

    const colunas: ITabela[] = [

        { titulo: "Pedido", acesso: `pedido_cliente` },
        { titulo: "Data de Carga", acesso: "dt_carga" },
        { titulo: "Cód.Produto", acesso: "cod_prod" },
        { titulo: "Barra", acesso: "barra" },
        { titulo: "RG", acesso: "RG" },
        { titulo: "PB", acesso: "pb" },
        { titulo: "PL", acesso: "pl" },
        { titulo: "Produção", acesso: "producao" },
        { titulo: "Validade", acesso: "validade" }
    ]



    interface Action {
        icon: JSX.Element;
        name: string;
    }

    const actions: Action[] = [
        /* {
             icon: (
                 <AddIcon
                     onClick={() => navigate("/cadastro/tempo/operacional/incluir/novo")}
                 />
             ),
             name: "Incluir",
         },*/

        {
            icon: <MdLocalPrintshop onClick={function () {


                window.print()

            }} />, name: "Imprimir"
        }

    ]

    const [listaProdutosDepositante, set_listaProdutosDepositante] = useState([])
    async function carregarProdutosDepositante(cnpj_depositante: any) {
        set_mostrarModalCarregando(true)
        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/produtos?id_filial=${getSessionData("DadosIdfilial")}&app=1&cnpj_depositante=${cnpj_depositante}`, {
            headers: {
                Authorization: token
            }
        })

        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message)
        }
        else {

            set_listaProdutosDepositante(resposta.data.produtos)
        }
        set_mostrarModalCarregando(false)
    }


    //pedido em excel
    const [excelPedidoData, setExcelPedidoData] = useState<any>([])
    const [fileExcel, set_fileExcel] = useState<any>("")
    const [hiddenItem, set_hiddenItem] = useState(false)

    function lerExcel(excel: any) {
        set_mostrarModalCarregando(true)
        const file = excel.target.files[0]

        const reader = new FileReader()

        reader.onload = function (e: any) {

            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            // Acesse a primeira folha do Excel
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Converte os dados da folha para um array de objetos
            // Converte os dados da folha para um array de objetos
            const dataObjects = XLSX.utils.sheet_to_json(sheet, { header: 1 })

            // Mapeia os dados para objetos com chaves específicas
            const mappedData = dataObjects.map((row: any) => {

                return {
                    cnpj_depositante: row[0] || '',
                    data_carga: row[1] || '',
                    pedido: row[2] || '',
                    codigo: row[3] || '',
                    qtde_pedido: row[4],
                    destinatario: row[5] || '',
                    roteiro: row[6] || "",
                    min_validade: row[7] || "",
                    lote: row[8] || ""
                };
            })

            const novosItens = []

            for (let i = 1; i < mappedData.length; i = i + 1) {

                //FORMATANDO A DATA MIN e CARGA
                let data_min_excel: any = ""
                let data_carga_excel: any = ""
                if (mappedData[i].min_validade != "" && mappedData[i].min_validade != " " && mappedData[i].min_validade != null) {

                    const excelEpoch = new Date(1899, 11, 31)
                    // Adiciona o número de dias do Excel para obter a data em JavaScript
                    const jsDate = new Date(excelEpoch.getTime() + (mappedData[1].min_validade - 1) * 24 * 60 * 60 * 1000)
                    data_min_excel = jsDate.toISOString().split("T")[0]
                    data_min_excel = data_min_excel.split("-")



                }
                const excelEpoch = new Date(1899, 11, 31)
                const jsDate_carga = new Date(excelEpoch.getTime() + (mappedData[1].data_carga - 1) * 24 * 60 * 60 * 1000)
                data_carga_excel = jsDate_carga.toISOString().split("T")[0]
                data_carga_excel = data_carga_excel.split("-")

                let novoItem = {
                    cliente: mappedData[i].cnpj_depositante, //é o cnpj do depositante
                    destinatario: mappedData[i].destinatario,
                    roteiro: mappedData[i].roteiro,
                    dt_carga: data_carga_excel[2] + "-" + data_carga_excel[1] + "-" + data_carga_excel[0],
                    pedido: mappedData[i].pedido,
                    cod_prod: mappedData[i].codigo,
                    qtde_volume: mappedData[i].qtde_pedido,
                    min_validade: data_min_excel != "" ? data_min_excel[2] + "-" + data_min_excel[1] + "-" + data_min_excel[0] : "",
                    lote: mappedData[i].lote
                }
                //preencho o pedido item por item
                console.log(novoItem)
                novosItens.push(novoItem)
            }

            set_depositante(mappedData[1].cnpj_depositante)
            set_pedido(mappedData[1].pedido)
            set_disabledNomePedido(true)
            set_hiddenItem(true)

            //FORMATANDO DA DATA DE CARGA QUE VEM NO EXCEL
            const excelEpoch = new Date(1899, 11, 31);

            // Adiciona o número de dias do Excel para obter a data em JavaScript
            const jsDate = new Date(excelEpoch.getTime() + (mappedData[1].data_carga - 1) * 24 * 60 * 60 * 1000)
            set_data(jsDate.toISOString().split("T")[0])


            // Atualiza o estado com os dados do Excel
            setExcelPedidoData(mappedData)

            //seto o array de pedidos para listar
            console.log(novosItens)
            set_listaItens(novosItens)
        }

        reader.readAsBinaryString(file)
        set_mostrarModalCarregando(false)
    }

    const [datalistKey, setDatalistKey] = useState(0);

    //BAIXAR EXCEL - GERAR EXCEL E BAIXAR PARA USUARIO USUARIO
    //eu deixo o template em base64 na pasta layouts e converto.
    const downloadExcelFromBase64 = (base64String: string, fileName: string) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        FileSaver.saveAs(blob, fileName);
    };
    const handleDownload = () => {
        const base64String = ExcelPedido // Replace with your base64 string
        const fileName = 'excelPedido.xlsx';
        downloadExcelFromBase64(base64String, fileName);
    };

    useEffect(function () {
        carregarDepositantesListar()
    }, [])

    return (
        <>
            <div className="container-fluid mt-2">
                <ContainerTitulo titulo="Enviar Pedido" />
                <Card>
                    <Card.Body>
                        <Box>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2 p-1">
                                        <button type="button" className="btn btn-secondary w-100" onClick={handleDownload}>Exportar Template .xls</button>
                                    </div>
                                    <div className="col-2 p-1">
                                        <button type="button" className="btn btn-success w-100" data-toggle="modal" data-target="#exampleModal">Importar Arquivo .xls</button>
                                    </div>
                                    <div className="col-2 p-1 w-50" style={{ textAlign: "right" }}>
                                        <button type="button" className="btn btn-success" onClick={consultarNovoPedido}>Enviar Pedido</button>
                                    </div>
                                </div>
                            </div>

                            <br />

                            <div className="container">

                                <form>
                                    <div className="row">
                                        <div className="col-4">
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Depositante*
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={depositante}
                                                    onChange={function (e: any) {
                                                        set_depositante(e.target.value)
                                                    }}
                                                    label="Depositante"
                                                    required
                                                >
                                                    <MenuItem value={depositante} hidden={true}>
                                                        <em>{depositante}</em>
                                                    </MenuItem>
                                                    {listaDepositantesByIdFilial.map((depositante: any) => (
                                                        <MenuItem value={depositante.cnpj} data-master={depositante.fantasia} onClick={function () {
                                                            carregarDepositantesListar()
                                                            carregarProdutosDepositante(depositante.cnpj)
                                                        }}>
                                                            {depositante.fantasia}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-2">
                                            <InputSemBorda
                                                label="Data*"
                                                name="data"
                                                type="date"
                                                placeholder="Data"
                                                required
                                                value={data}
                                                onChange={set_data}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <InputSemBorda
                                                label="Pedido*"
                                                name="Pedido"
                                                type="text"
                                                placeholder="Pedido SIS"
                                                value={pedido}
                                                readonly={disabledNomePedido}
                                                onChange={set_pedido}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                        <div className="col-4">
                                            <InputSemBorda
                                                label="Destinatario"
                                                name="data"
                                                type="text"
                                                placeholder="Data"
                                                value={destinatario}
                                                onChange={set_destinatario}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                        <div className="col-4">
                                            <InputSemBorda
                                                label="Roteiro"
                                                name="data"
                                                type="text"
                                                placeholder="Roteiro"
                                                value={roteiro}
                                                onChange={set_roteiro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div className="w-100"></div>
                                <br />
                                <br />

                                <form onSubmit={addItemAlista}>
                                    <div className="row">
                                        <div className="col-4">
                                            {/*<FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Item
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={item}
                                                    onChange={function (e: any) {
                                                        set_cod_item(e.target.value)
                                                    }}
                                                    label="Und. de Pedido"
                                                    required
                                                >
                                                    <MenuItem value={item} hidden={true}>
                                                        <em>{item}</em>
                                                    </MenuItem>
                                                    {listaProdutosDepositante.map((produto: any) => (
                                                        <MenuItem value={produto.COD_PROD_CLIENTE} data-master={produto.DESCRICAO}
                                                            onClick={function () {
                                                                set_item(produto.DESCRICAO)
                                                            }}>
                                                            <em>{produto.COD_PROD_CLIENTE} | {produto.DESCRICAO}</em>
                                                        </MenuItem>
                                                    ))}



                                                </Select>
                                            </FormControl> */}

                                            <div className="form-floating w-100">
                                                <input type="text" list="datalistProdutos"
                                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                                    placeholder="Produto"
                                                    value={item}
                                                    aria-label="Produto"
                                                    onChange={function (e: any) {
                                                        set_cod_item(e.target.value.split("|")[0])
                                                        set_item(e.target.value.split("|")[1])
                                                    }}
                                                />
                                                <label htmlFor="item_pedido_qualquer">
                                                    Produto
                                                </label>
                                            </div>


                                            <datalist id="datalistProdutos" key={datalistKey}>
                                                {/* Opções do datalist */}
                                                {listaProdutosDepositante.map((produto: any, index: number) => (
                                                    <option key={index} value={`${produto.COD_PROD_CLIENTE}|${produto.DESCRICAO}`} />
                                                ))}
                                            </datalist>

                                        </div>
                                        <div className="col-2">
                                            <InputSemBorda
                                                required
                                                label="Quantidade"
                                                name="Pedido"
                                                type="number"
                                                placeholder="Qtd"
                                                value={qtd}
                                                onChange={set_qtd}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <InputSemBorda
                                                label="Validade Minima"
                                                name="data"
                                                type="date"
                                                placeholder="Data"
                                                value={data_min}
                                                onChange={set_data_min}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                        <div className="col-2">
                                            <InputSemBorda
                                                label="Lote"
                                                name="data"
                                                type="text"
                                                placeholder="Data"
                                                value={lote}
                                                onChange={set_lote}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                        <div className="col">
                                            <button type="submit" className="btn btn-primary mt-2">Adicionar Item ao pedido</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="w-100"></div>
                            <br />
                            <br />
                            <div className="container bg-primary">
                                <div className="row">
                                    <div className="col-1">
                                        <h4 className="text-light">Cód. Item</h4>
                                    </div>
                                    <div className="col-2" hidden={hiddenItem}>
                                        <h4 className="text-light">Item</h4>
                                    </div>
                                    <div className="col-1">
                                        <h4 className="text-light">Qtd Pedido</h4>
                                    </div>
                                    <div className="col-2">
                                        <h4 className="text-light">Validade Min</h4>
                                    </div>
                                    <div className="col-2">
                                        <h4 className="text-light">Lote</h4>
                                    </div>
                                    <div className="col-1">
                                        <h4 className="text-light">Ações</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100"></div>
                            <div className="container">


                                {listaItens.map(function (item: any, index: any) {

                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col-1 pt-2">
                                                    <p className="text">{item.cod_prod}</p>
                                                </div>
                                                <div className="col-2 pt-2" hidden={hiddenItem}>
                                                    <p className="text">{item.item}</p>
                                                </div>
                                                <div className="col-1 pt-2">
                                                    <p className="text">{item.qtde_volume}</p>
                                                </div>
                                                <div className="col-2 pt-2">
                                                    <p className="text">{item.min_validade}</p>
                                                </div>
                                                <div className="col-2 pt-2">
                                                    <p className="text">{item.lote}</p>
                                                </div>
                                                <div className="col-1 pt-2">
                                                    <ButtonCustom
                                                        invisivel={false}
                                                        onclick={function () {

                                                            removerItemDaLista(index)
                                                        }}
                                                        icone={mdiTrashCanOutline}
                                                        type="button"
                                                        className="botaoTransparente"
                                                        title={"Excluir"}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </Box>
                    </Card.Body>
                </Card>
            </div>

            {/*MODAL INPUT FILE */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">


                <div className="mx-auto w-50 mt-5">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content bg-light">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Importar Pedido .xls</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input type="file" onChange={function (e: any) {
                                    set_fileExcel(e)

                                }} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={function () {
                                        lerExcel(fileExcel)
                                    }}>Carregar</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div >
            <ModalLoading show={mostrarModalCarregando} />
        </>
    )
}

export default NovoPedido