import FormularioEquipeOperacional from "./formulario/formulario";
import { useParams } from "react-router-dom"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"

function EditarEquipe() {

    const params = useParams()

    return (
        <>
            <div className="container-fluid mt-2">
            <ContainerTitulo titulo="Editar Equipe" />
                <FormularioEquipeOperacional idequipe={params.id} acao="editar"/>
            </div>

        </>
    )
}
export default EditarEquipe