import armazem from "../assets/img/armazem.png";
import avatar from "../assets/img/avatar.png";
import { Form, Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ApiPost from "../api/endPoints/usePost";
import { toast } from "react-toastify";
import ApiGet from "../api/endPoints/useGet";
import ModalLoading from "../components/Formularios/Modal/ModalLoading";
import { getSessionData, setSessionData } from "../utils/storageUtils";
import { Col, Modal, Row } from "react-bootstrap";
import InputSemBorda from "../components/Formularios/Inputs/InputsSemBorda";
import { Button } from "../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import { ModalTrocarSenhaLink } from "./administrativo/usuario/components/ModalTrocarSenhaLink";
import { LogofilialAtual } from "../shared/logo";
import AvatarFilial from "../components/Formularios/AvatarFilial";
import ModalSelecionarFilialLink from "./cadastro/filiais/filiais/components/ModalSelecionaClinicaLink/ModalSelecionaClinicaLink";
function NavBar() {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const isVinculo = getSessionData("isPerfilVinculo") || "0";
  const navigate = useNavigate();
  const usuario = getSessionData("NomeUsuario") || "";
  const perfil = getSessionData("PerfilUsuario") || "";
  const id_usuario = getSessionData("DadosUsuarioID") || "";
  const login_usuario = getSessionData("DadosUsuario") || "";
  const avatarfilial = getSessionData("DadosAvatarfilial") || "";


  const [abrir, setAbrir] = useState<boolean>(false);

  useEffect(() => {
    if (usuario === "") {
      navigate("/login");
    }
  }, []);

  const logoff = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const [avatarImg, setAvatarImg] = useState<string>(avatarfilial || "");
  const [loading, setLoading] = useState<boolean>(false);
  const carregarAvatar = async (id_usuario: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/usuario/carregar/:idusuario",
      parametros: {
        idusuario: id_usuario,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {
        const { avatar } = response[0];
        setLoading(false);
        setAvatarImg(avatar);
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    if (id_usuario !== "") carregarAvatar(id_usuario);
  }, [id_usuario]);

  //#endregion

  //#region Carregar Filiais do usuario
  const [filiais, setFiliais] = useState<any[]>([]);
  const [avatarImgFilial, setAvatarImgFilial] = useState<string>("");
  const [idfilial, setIdfilial] = useState<string>("");
  const [nomefilial, setNomefilial] = useState<string>("");

  const carregarFiliais = async (id_usuario: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/filial/usuario/listar/:id_usuario/:id_empresa",
      parametros: {
        id_usuario: id_usuario,
        id_empresa: isVinculo,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((response) => {

        const retornoApi = response;
        const qtdfilial = retornoApi.length;
        setLoading(false);
        if (qtdfilial === 1) {
          setFiliais(response);
          setAvatarImgFilial(response[0].avatar);
          setSessionData("imgFilial", response[0].avatar)
          setIdfilial(response[0].id_filial);
          setNomefilial(response[0].fantasia);
          setSessionData("DadosIdfilial", response[0].id_filial);
          setSessionData("DadosNomefilial", response[0].fantasia);
        }
        if (qtdfilial > 1) {
          setFiliais(response);

          //setSessionData("DadosIdfilial", response[0].id_filial);
          //setSessionData("DadosNomefilial", response[0].fantasia);
          setAbrir(true);
        }
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Falha ao carregar dados. Motivo: ${msg}`);
      });
  };

  useEffect(() => {
    if (id_usuario !== "") carregarFiliais(id_usuario);
  }, [id_usuario]);

  //#endregion

  const fecharModal = () => {
    sessionStorage.clear();
    navigate("/login");
    // setAbrir(false)
  };

  const torreControle: any = getSessionData("TorreControle")

  const receberDados = (dados: any) => {
    setAvatarImgFilial(dados.avatar);
    setSessionData("imgFilial", dados.avatar)
    setSessionData("isPerfilVinculo", dados.id_empresa)
    setIdfilial(dados.id_filial);
    setNomefilial(dados.fantasia);
    setSessionData("DadosAvatarfilial", dados.avatar);
    setSessionData("DadosIdfilial", dados.id_filial);
    setSessionData("DadosNomefilial", dados.fantasia);
    
    if (torreControle == 1) {
      navigate("/dashborad/controle/torre")
    } else {
      navigate("/");
    }
    setAbrir(false);
  };

  return (
    <>
      <div className="navbar-custom">
        <ul className="list-unstyled topbar-menu float-end mb-0">
          <li className="dropdown notification-list topbar-dropdown">
            <a
              className="nav-link dropdown-toggle arrow-none"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <AvatarFilial
                avatarImgFilial={avatarImgFilial}
                alt="filial"
                className="rounded-action-icon imgEmpresa"
              />
              <label className="filial" style={{ color: "black" }}>
                &nbsp;{idfilial} - {nomefilial}
              </label>
              <i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle" />
            </a>

            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
              {filiais.map((item) => {
                return (
                  <a
                    className="dropdown-item notify-item"
                    onClick={() => {

                      setAvatarImgFilial(item.avatar);
                      setSessionData("imgFilial", item.avatar)
                      setIdfilial(item.id_filial);
                      setNomefilial(item.fantasia);
                      setSessionData("DadosAvatarfilial", item.avatar);
                      setSessionData("DadosIdfilial", item.id_filial);
                      setSessionData("DadosNomefilial", item.fantasia);

                      if (getSessionData("isPerfilSuper") == "1") {
                        setSessionData("isPerfilVinculo", item.id_empresa)
                      }

                      navigate("/");
                    }}
                  >
                    <img
                      src={
                        item.avatar !== null
                          ? `data:image/png;base64,${item.avatar}`
                          : armazem
                      }
                      alt="filial"
                      className="rounded-action-icon imgEmpresa"
                    />
                    <label className="form-label">
                      &nbsp;{item.id_filial} - {item.fantasia}
                    </label>
                  </a>
                );
              })}
            </div>
          </li>
          <li className="dropdown notification-list">
            <button
              type="button"
              className="nav-link dropdown-toggle nav-user arrow-none me-0"
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <span className="account-user-avatar">
                <img
                  src={
                    avatarImg ? `data:image/png;base64,${avatarImg}` : avatar
                  }
                  alt="user"
                  className="rounded-circle"
                />
              </span>
              <span>
                <span className="account-user-name">{usuario}</span>
                <span className="account-position">{perfil}</span>
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
              <div className=" dropdown-header noti-title">
                <h6 className="text-overflow m-0">Bem vindo !</h6>
              </div>
              <Link
                to={`/administrativo/usuario/usuario/editar/${id_usuario}`}
                className="dropdown-item notify-item"
              >
                <i className="mdi mdi-account-circle me-1"></i>
                <span>Minha conta</span>
              </Link>
              <ModalTrocarSenhaLink />
              <button
                type="button"
                style={{ cursor: "pointer" }}
                className="dropdown-item notify-item"
                onClick={logoff}
              >
                <i className="mdi mdi-logout me-1"></i>
                <span>Logout</span>
              </button>
              <Link
                target="_blank"
                to={`https://fklservice.com.br/produtos/treinamento-completo-area-do-cliente`}
                className="dropdown-item notify-item"
              >
                <i className="mdi mdi-book-open me-1"></i>
                <span>Manual do Usuario</span>
              </Link>
            </div>
          </li>
        </ul>
        <button className="button-menu-mobile open-left">
          <i className="mdi mdi-menu"></i>
        </button>
      </div>
      <ModalLoading show={loading} label="Carregando dados" />

      <ModalSelecionarFilialLink
        abrir={abrir}
        onClose={fecharModal}
        onclick={receberDados}
      />
    </>
  );
};
export default NavBar;
