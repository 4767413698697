import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect } from "react";
import ApiGet from "../../../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import SelectInput from "../../../../../shared/SelectInputs";
import { Tab, Box, Switch } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import { getSessionData } from "../../../../../utils/storageUtils";

interface FormPerfilProps {
  idperfil?: string;
  acao?: string;
}

const FormPerfil = ({ idperfil, acao }: FormPerfilProps) => {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const navigate = useNavigate();

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [id_perfil, setIdPerfil] = useState<string>("");
  const [nome_perfil, setNomePerfil] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [master, setMaster] = useState<boolean>(false);
  const [NivelPerfil, setNivelPerfil] = useState<any>("")

  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO
  const [loading, setLoading] = useState<boolean>(false);
  const carregarDados = async (idperfil: any) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil",
      parametros: {
        idperfil: idperfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {

        setIdPerfil(retorno[0].id_perfil[0])
        setNomePerfil(retorno[0].nome_perfil)
        setNivelPerfil(retorno[0].nivel)

        if (retorno[0].situacao === 1) {
          setAtivo(true);
        } else {
          setAtivo(false);
        }
        if (retorno[0].master === 1) {
          setMaster(true);
          setAllPermissaoPerfilMaster(!master)
        } else {
          setMaster(false);
        }

        if (retorno[0].editar_codsistema === 1) {
          setEditarCodSistema(true)
        }
        else {
          setEditarCodSistema(false)
        }

        const setFunctions: { [key: string]: (value: boolean) => void } = {
          visualizar_empresa_holding: setVisualizarEmpresaHolding,
          incluir_empresa_holding: setIncluirEmpresaHolding,
          editar_empresa_holding: setEditarEmpresaHolding,
          excluir_empresa_holding: setExcluirEmpresaHolding,
          visualizar_perfil_permissao: setVisualizarPerfilPermissao,
          incluir_perfil_permissao: setIncluirPerfilPermissao,
          editar_perfil_permissao: setEditarPerfilPermissao,
          excluir_perfil_permissao: setExcluirPerfilPermissao,
          visualizar_usuario: setVisualizarUsuario,
          incluir_usuario: setIncluirUsuario,
          editar_usuario: setEditarUsuario,
          excluir_usuario: setExcluirUsuario,
          visualizar_codsistema: setVisualizarCodSistema,
          incluir_codsistema: setIncluirCodSistema,
          editar_codsistema: setEditarCodSistema,
          excluir_codsistema: setExcluirCodSistema,
          visualizarfilial: setVisualizarFilial,
          incluirfilial: setIncluirFilial,
          editarfilial: setEditarFilial,
          excluirfilial: setExcluirFilial,
          visualizarDepositante: setVisualizarDepositante,
          incluirDepositante: setIncluirDepositante,
          EditarDepositante: setEditarDepositante,
          ExcluirDepositante: setExcluirDepositante,
          visualizarEquipeOperacional: setVisualizarEquipeOperacional,
          incluirEquipeOperacional: setIncluirEquipeOperacional,
          EditarEquipeOperacional: setEditarEquipeOperacional,
          ExcluirEquipeOperacional: setExcluirEquipeOperacional,
          visualizarTempoOperacional: setVisualizarTempoOperacional,
          incluirTempoOperacional: setIncluirTempoOperacional,
          EditarTempoOperacional: setEditarTempoOperacional,
          ExcluirTempoOperacional: setExcluirTempoOperacional,
          visualizarAgendaEquipe: setVisualizarAgendaEquipe,
          incluirAgendaEquipe: setIncluirAgendaEquipe,
          EditarAgendaEquipe: setEditarAgendaEquipe,
          ExcluirAgendaEquipe: setExcluirAgendaEquipe,
          visualizarEstoqueSintetico: setVisualizarEstoqueSintetico,
          visualizarEstoqueValidadeLote: setVisualizarEstoqueValidadeLote,
          visualizarEnviarPedido: setVisualizarEnviarPedido,
          visualizarPedidoStatus: setVisualizarPedidoStatus,
          visualizarPedidoResumido: setVisualizarPedidoResumido,
          visualizarPedidoValidadeLote: setVisualizarPedidoValidadeLote,
          visualizarPedidoBarra: setVisualizarPedidoBarra,
          visualizarAgendamentoDeEquipe: setVisualizarAgendamentoDeEquipe,
          incluirAgendamentoDeEquipe: setIncluirAgendamentoDeEquipe,
          EditarAgendamentoDeEquipeUsuario: setEditarAgendamentoDeEquipeUsuario,
          EditarAgendamentoDeEquipeTodos: setEditarAgendamentoDeEquipeTodos,
          ExcluirAgendamentoDeEquipeUsuario: setExcluirAgendamentoDeEquipeUsuario,
          ExcluirAgendamentoDeEquipeTodos: setExcluirAgendamentoDeEquipeTodos,
          AcessoTotalAgendamentoDeEquipe: setAcessoTotalAgendamentoDeEquipe,
          visualizarResumidoValidade: setVisualizarResumidoValidade,
          visualizarDetalhadoBarra: setVisualizarDetalhadoBarra,
          dashboard: setDashBoard,
          TorreControle: set_TorreControle,
          visualizarUsuarioFilial: setVisualizarUsuarioFilial,
          incluirUsuarioFilial: setIncluirUsuarioFilial,
          EditarUsuarioFilial: setEditarUsuarioFilial,
          ExcluirUsuarioFilial: setExcluirUsuarioFilial
        };

        // Iterar sobre as propriedades e definir os estados com base nos valores de retorno[0] -
        //OBS: obg gpt
        for (const key in retorno[0]) {
          if (key in setFunctions) {
            setFunctions[key](retorno[0][key] === 1);
          }
        }

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (idperfil !== "novo") {
      carregarDados(idperfil);
    }

  }, [idperfil]);
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS DO USUÁRIO
  const atualizarDados = async (
    id_perfil: string,
    nome_perfil: string,
    situacao: number,
    visualizarEmpresaHolding: number,
    incluirEmpresaHolding: number,
    EditarEmpresaHolding: number,
    ExcluirEmpresaHolding: number,

    visualizarPerfilPermissao: number,
    incluirPerfilPermissao: number,
    EditarPerfilPermissao: number,
    ExcluirPerfilPermissao: number,


    visualizarUsuario: number,
    incluirUsuario: number,
    EditarUsuario: number,
    ExcluirUsuario: number,

    visualizarCodSistema: number,
    incluirCodSistema: number,
    EditarCodSistema: number,
    ExcluirCodSistema: number,

    visualizarFilial: number,
    incluirFilial: number,
    EditarFilial: number,
    ExcluirFilial: number,

    visualizarDepositante: number,
    incluirDepositante: number,
    EditarDepositante: number,
    ExcluirDepositante: number,


    visualizarEquipeOperacional: number,
    incluirEquipeOperacional: number,
    EditarEquipeOperacional: number,
    ExcluirEquipeOperacional: number,

    visualizarTempoOperacional: number,
    incluirTempoOperacional: number,
    EditarTempoOperacional: number,
    ExcluirTempoOperacional: number,


    visualizarAgendaEquipe: number,
    incluirAgendaEquipe: number,
    EditarAgendaEquipe: number,
    ExcluirAgendaEquipe: number,

    visualizarEstoqueSintetico: number,
    visualizarEstoqueValidadeLote: number,

    visualizarEnviarPedido: number,
    visualizarPedidoStatus: number,
    visualizarPedidoResumido: number,
    visualizarPedidoValidadeLote: number,
    visualizarPedidoBarra: number,

    visualizarAgendamentoDeEquipe: number,
    incluirAgendamentoDeEquipe: number,
    EditarAgendamentoDeEquipeUsuario: number,
    EditarAgendamentoDeEquipeTodos: number,
    ExcluirAgendamentoDeEquipeUsuario: number,
    ExcluirAgendamentoDeEquipeTodos: number,
    AcessoTotalAgendamentoDeEquipe: number,

    NivelPerfil: number,

    visualizarResumidoValidade: number,
    visualizarDetalhadoBarra: number,
    DashBoard: number,

    TorreControle: number,
    visualizarUsuarioFilial: number,
    incluirUsuarioFilial: number,
    EditarUsuarioFilial: number,
    ExcluirUsuarioFilial: number,

    usuarioLogado: any
  ) => {
    const dados = {
      nome_perfil,
      situacao,
      //dados para permissao 
      visualizarEmpresaHolding,
      incluirEmpresaHolding,
      EditarEmpresaHolding,
      ExcluirEmpresaHolding,

      visualizarPerfilPermissao,
      incluirPerfilPermissao,
      EditarPerfilPermissao,
      ExcluirPerfilPermissao,


      visualizarUsuario,
      incluirUsuario,
      EditarUsuario,
      ExcluirUsuario,
      AcessoTotalUsuario,

      visualizarCodSistema,
      incluirCodSistema,
      EditarCodSistema,
      ExcluirCodSistema,

      visualizarFilial,
      incluirFilial,
      EditarFilial,
      ExcluirFilial,

      visualizarDepositante,
      incluirDepositante,
      EditarDepositante,
      ExcluirDepositante,

      visualizarEquipeOperacional,
      incluirEquipeOperacional,
      EditarEquipeOperacional,
      ExcluirEquipeOperacional,

      visualizarTempoOperacional,
      incluirTempoOperacional,
      EditarTempoOperacional,
      ExcluirTempoOperacional,

      visualizarAgendaEquipe,
      incluirAgendaEquipe,
      EditarAgendaEquipe,
      ExcluirAgendaEquipe,

      visualizarEstoqueSintetico,
      visualizarEstoqueValidadeLote,

      visualizarEnviarPedido,
      visualizarPedidoStatus,
      visualizarPedidoResumido,
      visualizarPedidoValidadeLote,
      visualizarPedidoBarra,

      visualizarAgendamentoDeEquipe,
      incluirAgendamentoDeEquipe,
      EditarAgendamentoDeEquipeUsuario,
      EditarAgendamentoDeEquipeTodos,
      ExcluirAgendamentoDeEquipeUsuario,
      ExcluirAgendamentoDeEquipeTodos,
      AcessoTotalAgendamentoDeEquipe,

      NivelPerfil,

      visualizarResumidoValidade,
      visualizarDetalhadoBarra,
      DashBoard,

      TorreControle,
      visualizarUsuarioFilial,
      incluirUsuarioFilial,
      EditarUsuarioFilial,
      ExcluirUsuarioFilial,

      usuarioLogado: getSessionData("NomeUsuario")
    };

    ApiPut(`/perfil/atualizar/${id_perfil}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(
          `Erro ao atualizar dados. Motivo: ${erro.response.data.message}`
        );
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS DO USUÁRIO
  const gravarDados = (
    nome_perfil: string,
    situacao: number,
    master: number,
    visualizarEmpresaHolding: number,
    incluirEmpresaHolding: number,
    EditarEmpresaHolding: number,
    ExcluirEmpresaHolding: number,

    visualizarPerfilPermissao: number,
    incluirPerfilPermissao: number,
    EditarPerfilPermissao: number,
    ExcluirPerfilPermissao: number,


    visualizarUsuario: number,
    incluirUsuario: number,
    EditarUsuario: number,
    ExcluirUsuario: number,

    visualizarCodSistema: number,
    incluirCodSistema: number,
    EditarCodSistema: number,
    ExcluirCodSistema: number,

    visualizarFilial: number,
    incluirFilial: number,
    EditarFilial: number,
    ExcluirFilial: number,

    visualizarDepositante: number,
    incluirDepositante: number,
    EditarDepositante: number,
    ExcluirDepositante: number,

    visualizarEquipeOperacional: number,
    incluirEquipeOperacional: number,
    EditarEquipeOperacional: number,
    ExcluirEquipeOperacional: number,

    visualizarTempoOperacional: number,
    incluirTempoOperacional: number,
    EditarTempoOperacional: number,
    ExcluirTempoOperacional: number,

    visualizarAgendaEquipe: number,
    incluirAgendaEquipe: number,
    EditarAgendaEquipe: number,
    ExcluirAgendaEquipe: number,

    visualizarEstoqueSintetico: number,
    visualizarEstoqueValidadeLote: number,

    visualizarEnviarPedido: number,
    visualizarPedidoStatus: number,
    visualizarPedidoResumido: number,
    visualizarPedidoValidadeLote: number,
    visualizarPedidoBarra: number,

    visualizarAgendamentoDeEquipe: number,
    incluirAgendamentoDeEquipe: number,
    EditarAgendamentoDeEquipeUsuario: number,
    EditarAgendamentoDeEquipeTodos: number,
    ExcluirAgendamentoDeEquipeUsuario: number,
    ExcluirAgendamentoDeEquipeTodos: number,
    AcessoTotalAgendamentoDeEquipe: number,

    NivelPerfil: number,

    visualizarResumidoValidade: number,
    visualizarDetalhadoBarra: number,
    DashBoard: number,

    TorreControle: number,
    visualizarUsuarioFilial: number,
    incluirUsuarioFilial: number,
    EditarUsuarioFilial: number,
    ExcluirUsuarioFilial: number,

    usuarioLogado: any

  ) => {
    const dados = {
      nome_perfil,
      situacao,
      master,

      //dados para permissao 
      visualizarEmpresaHolding,
      incluirEmpresaHolding,
      EditarEmpresaHolding,
      ExcluirEmpresaHolding,

      visualizarPerfilPermissao,
      incluirPerfilPermissao,
      EditarPerfilPermissao,
      ExcluirPerfilPermissao,


      visualizarUsuario,
      incluirUsuario,
      EditarUsuario,
      ExcluirUsuario,
      AcessoTotalUsuario,

      visualizarCodSistema,
      incluirCodSistema,
      EditarCodSistema,
      ExcluirCodSistema,

      visualizarFilial,
      incluirFilial,
      EditarFilial,
      ExcluirFilial,

      visualizarDepositante,
      incluirDepositante,
      EditarDepositante,
      ExcluirDepositante,

      visualizarEquipeOperacional,
      incluirEquipeOperacional,
      EditarEquipeOperacional,
      ExcluirEquipeOperacional,

      visualizarTempoOperacional,
      incluirTempoOperacional,
      EditarTempoOperacional,
      ExcluirTempoOperacional,

      visualizarAgendaEquipe,
      incluirAgendaEquipe,
      EditarAgendaEquipe,
      ExcluirAgendaEquipe,

      visualizarEstoqueSintetico,
      visualizarEstoqueValidadeLote,

      visualizarEnviarPedido,
      visualizarPedidoStatus,
      visualizarPedidoResumido,
      visualizarPedidoValidadeLote,
      visualizarPedidoBarra,

      visualizarAgendamentoDeEquipe,
      incluirAgendamentoDeEquipe,
      EditarAgendamentoDeEquipeUsuario,
      EditarAgendamentoDeEquipeTodos,
      ExcluirAgendamentoDeEquipeUsuario,
      ExcluirAgendamentoDeEquipeTodos,
      AcessoTotalAgendamentoDeEquipe,

      NivelPerfil,
      visualizarResumidoValidade,
      visualizarDetalhadoBarra,
      DashBoard,
      TorreControle,
      visualizarUsuarioFilial,
      incluirUsuarioFilial,
      EditarUsuarioFilial,
      ExcluirUsuarioFilial,

      usuarioLogado: getSessionData("NomeUsuario")
    };
    ApiPost("/perfil/gravar", dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idperfil === "novo") {
      gravarDados(nome_perfil, ativo ? 1 : 0, master ? 1 : 0,
        visualizarEmpresaHolding ? 1 : 0,
        incluirEmpresaHolding ? 1 : 0,
        EditarEmpresaHolding ? 1 : 0,
        ExcluirEmpresaHolding ? 1 : 0,
        visualizarPerfilPermissao ? 1 : 0,
        incluirPerfilPermissao ? 1 : 0,
        EditarPerfilPermissao ? 1 : 0,
        ExcluirPerfilPermissao ? 1 : 0,
        visualizarUsuario ? 1 : 0,
        incluirUsuario ? 1 : 0,
        EditarUsuario ? 1 : 0,
        ExcluirUsuario ? 1 : 0,
        visualizarCodSistema ? 1 : 0,
        incluirCodSistema ? 1 : 0,
        EditarCodSistema ? 1 : 0,
        ExcluirCodSistema ? 1 : 0,
        visualizarFilial ? 1 : 0,
        incluirFilial ? 1 : 0,
        EditarFilial ? 1 : 0,
        ExcluirFilial ? 1 : 0,
        visualizarDepositante ? 1 : 0,
        incluirDepositante ? 1 : 0,
        EditarDepositante ? 1 : 0,
        ExcluirDepositante ? 1 : 0,
        visualizarEquipeOperacional ? 1 : 0,
        incluirEquipeOperacional ? 1 : 0,
        EditarEquipeOperacional ? 1 : 0,
        ExcluirEquipeOperacional ? 1 : 0,
        visualizarTempoOperacional ? 1 : 0,
        incluirTempoOperacional ? 1 : 0,
        EditarTempoOperacional ? 1 : 0,
        ExcluirTempoOperacional ? 1 : 0,
        visualizarAgendaEquipe ? 1 : 0,
        incluirAgendaEquipe ? 1 : 0,
        EditarAgendaEquipe ? 1 : 0,
        ExcluirAgendaEquipe ? 1 : 0,
        visualizarEstoqueSintetico ? 1 : 0,
        visualizarEstoqueValidadeLote ? 1 : 0,
        visualizarEnviarPedido ? 1 : 0,
        visualizarPedidoStatus ? 1 : 0,
        visualizarPedidoResumido ? 1 : 0,
        visualizarPedidoValidadeLote ? 1 : 0,
        visualizarPedidoBarra ? 1 : 0,
        visualizarAgendamentoDeEquipe ? 1 : 0,
        incluirAgendamentoDeEquipe ? 1 : 0,
        EditarAgendamentoDeEquipeUsuario ? 1 : 0,
        EditarAgendamentoDeEquipeTodos ? 1 : 0,
        ExcluirAgendamentoDeEquipeUsuario ? 1 : 0,
        ExcluirAgendamentoDeEquipeTodos ? 1 : 0,
        AcessoTotalAgendamentoDeEquipe ? 1 : 0,
        NivelPerfil,
        visualizarResumidoValidade ? 1 : 0,
        visualizarDetalhadoBarra ? 1 : 0,
        DashBoard ? 1 : 0,
        TorreControle ? 1 : 0,
        visualizarUsuarioFilial ? 1 : 0,
        incluirUsuarioFilial ? 1 : 0,
        EditarUsuarioFilial ? 1 : 0,
        ExcluirUsuarioFilial ? 1 : 0,

        getSessionData("NomeUsuario")
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(id_perfil, nome_perfil, ativo ? 1 : 0,
        visualizarEmpresaHolding ? 1 : 0,
        incluirEmpresaHolding ? 1 : 0,
        EditarEmpresaHolding ? 1 : 0,
        ExcluirEmpresaHolding ? 1 : 0,
        visualizarPerfilPermissao ? 1 : 0,
        incluirPerfilPermissao ? 1 : 0,
        EditarPerfilPermissao ? 1 : 0,
        ExcluirPerfilPermissao ? 1 : 0,
        visualizarUsuario ? 1 : 0,
        incluirUsuario ? 1 : 0,
        EditarUsuario ? 1 : 0,
        ExcluirUsuario ? 1 : 0,
        visualizarCodSistema ? 1 : 0,
        incluirCodSistema ? 1 : 0,
        EditarCodSistema ? 1 : 0,
        ExcluirCodSistema ? 1 : 0,
        visualizarFilial ? 1 : 0,
        incluirFilial ? 1 : 0,
        EditarFilial ? 1 : 0,
        ExcluirFilial ? 1 : 0,
        visualizarDepositante ? 1 : 0,
        incluirDepositante ? 1 : 0,
        EditarDepositante ? 1 : 0,
        ExcluirDepositante ? 1 : 0,
        visualizarEquipeOperacional ? 1 : 0,
        incluirEquipeOperacional ? 1 : 0,
        EditarEquipeOperacional ? 1 : 0,
        ExcluirEquipeOperacional ? 1 : 0,
        visualizarTempoOperacional ? 1 : 0,
        incluirTempoOperacional ? 1 : 0,
        EditarTempoOperacional ? 1 : 0,
        ExcluirTempoOperacional ? 1 : 0,
        visualizarAgendaEquipe ? 1 : 0,
        incluirAgendaEquipe ? 1 : 0,
        EditarAgendaEquipe ? 1 : 0,
        ExcluirAgendaEquipe ? 1 : 0,
        visualizarEstoqueSintetico ? 1 : 0,
        visualizarEstoqueValidadeLote ? 1 : 0,
        visualizarEnviarPedido ? 1 : 0,
        visualizarPedidoStatus ? 1 : 0,
        visualizarPedidoResumido ? 1 : 0,
        visualizarPedidoValidadeLote ? 1 : 0,
        visualizarPedidoBarra ? 1 : 0,
        visualizarAgendamentoDeEquipe ? 1 : 0,
        incluirAgendamentoDeEquipe ? 1 : 0,
        EditarAgendamentoDeEquipeUsuario ? 1 : 0,
        EditarAgendamentoDeEquipeTodos ? 1 : 0,
        ExcluirAgendamentoDeEquipeUsuario ? 1 : 0,
        ExcluirAgendamentoDeEquipeTodos ? 1 : 0,
        AcessoTotalAgendamentoDeEquipe ? 1 : 0,
        NivelPerfil,
        visualizarResumidoValidade ? 1 : 0,
        visualizarDetalhadoBarra ? 1 : 0,
        DashBoard ? 1 : 0,
        TorreControle ? 1 : 0,
        visualizarUsuarioFilial ? 1 : 0,
        incluirUsuarioFilial ? 1 : 0,
        EditarUsuarioFilial ? 1 : 0,
        ExcluirUsuarioFilial ? 1 : 0,
        getSessionData("NomeUsuario")
      );
      toast.info("Atualizando dados do registro...");
    }
  };


  //controle "aba" ADMINISTRATIVO - inicio

  //informações de permissões
  const [visualizarEmpresaHolding, setVisualizarEmpresaHolding] = useState(false)
  const [incluirEmpresaHolding, setIncluirEmpresaHolding] = useState(false)
  const [EditarEmpresaHolding, setEditarEmpresaHolding] = useState(false)
  const [ExcluirEmpresaHolding, setExcluirEmpresaHolding] = useState(false)
  const [AcessoTotalEmpresaHolding, setAcessoTotalEmpresaHolding] = useState(false)

  const [visualizarPerfilPermissao, setVisualizarPerfilPermissao] = useState(false)
  const [incluirPerfilPermissao, setIncluirPerfilPermissao] = useState(false)
  const [EditarPerfilPermissao, setEditarPerfilPermissao] = useState(false)
  const [ExcluirPerfilPermissao, setExcluirPerfilPermissao] = useState(false)
  const [AcessoTotalPerfilPermissao, setAcessoTotalPerfilPermissao] = useState(false)

  const [visualizarUsuario, setVisualizarUsuario] = useState(false)
  const [incluirUsuario, setIncluirUsuario] = useState(false)
  const [EditarUsuario, setEditarUsuario] = useState(false)
  const [ExcluirUsuario, setExcluirUsuario] = useState(false)
  const [AcessoTotalUsuario, setAcessoTotalUsuario] = useState(false)

  const [visualizarUsuarioFilial, setVisualizarUsuarioFilial] = useState(false)
  const [incluirUsuarioFilial, setIncluirUsuarioFilial] = useState(false)
  const [EditarUsuarioFilial, setEditarUsuarioFilial] = useState(false)
  const [ExcluirUsuarioFilial, setExcluirUsuarioFilial] = useState(false)
  const [AcessoTotalUsuarioFilial, setAcessoTotalUsuarioFilial] = useState(false)

  const [visualizarCodSistema, setVisualizarCodSistema] = useState(false)
  const [incluirCodSistema, setIncluirCodSistema] = useState(false)
  const [EditarCodSistema, setEditarCodSistema] = useState(false)
  const [ExcluirCodSistema, setExcluirCodSistema] = useState(false)
  const [AcessoTotalCodSistema, setAcessoTotalCodSistema] = useState(false)



  //controle "aba" ADMINISTRATIVO - fim


  //controle "aba" CADASTRO - inicio
  const [visualizarFilial, setVisualizarFilial] = useState(false)
  const [incluirFilial, setIncluirFilial] = useState(false)
  const [EditarFilial, setEditarFilial] = useState(false)
  const [ExcluirFilial, setExcluirFilial] = useState(false)
  const [AcessoTotalFilial, setAcessoTotalFilial] = useState(false)

  const [visualizarDepositante, setVisualizarDepositante] = useState(false)
  const [incluirDepositante, setIncluirDepositante] = useState(false)
  const [EditarDepositante, setEditarDepositante] = useState(false)
  const [ExcluirDepositante, setExcluirDepositante] = useState(false)
  const [AcessoTotalDepositante, setAcessoTotalDepositante] = useState(false)

  const [visualizarEquipeOperacional, setVisualizarEquipeOperacional] = useState(false)
  const [incluirEquipeOperacional, setIncluirEquipeOperacional] = useState(false)
  const [EditarEquipeOperacional, setEditarEquipeOperacional] = useState(false)
  const [ExcluirEquipeOperacional, setExcluirEquipeOperacional] = useState(false)
  const [AcessoTotalEquipeOperacional, setAcessoTotalEquipeOperacional] = useState(false)

  const [visualizarTempoOperacional, setVisualizarTempoOperacional] = useState(false)
  const [incluirTempoOperacional, setIncluirTempoOperacional] = useState(false)
  const [EditarTempoOperacional, setEditarTempoOperacional] = useState(false)
  const [ExcluirTempoOperacional, setExcluirTempoOperacional] = useState(false)
  const [AcessoTotalTempoOperacional, setAcessoTotalTempoOperacional] = useState(false)


  const [visualizarAgendaEquipe, setVisualizarAgendaEquipe] = useState(false)
  const [incluirAgendaEquipe, setIncluirAgendaEquipe] = useState(false)
  const [EditarAgendaEquipe, setEditarAgendaEquipe] = useState(false)
  const [ExcluirAgendaEquipe, setExcluirAgendaEquipe] = useState(false)
  const [AcessoTotalAgendaEquipe, setAcessoTotalAgendaEquipe] = useState(false)

  //controle "aba" CADASTRO - fim

  //controle "aba" ESTOQUE - INICIO
  const [visualizarEstoqueSintetico, setVisualizarEstoqueSintetico] = useState(false)
  const [visualizarEstoqueValidadeLote, setVisualizarEstoqueValidadeLote] = useState(false)
  //controle "aba" ESTOQUE - fim

  //controle "aba" PEDIDO - inico
  const [visualizarEnviarPedido, setVisualizarEnviarPedido] = useState(false)
  const [visualizarPedidoStatus, setVisualizarPedidoStatus] = useState(false)
  const [visualizarPedidoResumido, setVisualizarPedidoResumido] = useState(false)
  const [visualizarPedidoValidadeLote, setVisualizarPedidoValidadeLote] = useState(false)
  const [visualizarPedidoBarra, setVisualizarPedidoBarra] = useState(false)


  //controle "aba" PEDIDO - fim

  //controle 'aba' RECEBIMENTO = INICIO
  const [visualizarResumidoValidade, setVisualizarResumidoValidade] = useState(false)
  const [visualizarDetalhadoBarra, setVisualizarDetalhadoBarra] = useState(false)
  //controle 'aba' RECEBIEMNTO = FIM

  //controle "aba" AGENDAMENTO  - inicio
  const [visualizarAgendamentoDeEquipe, setVisualizarAgendamentoDeEquipe] = useState(false)
  const [incluirAgendamentoDeEquipe, setIncluirAgendamentoDeEquipe] = useState(false)
  const [EditarAgendamentoDeEquipeUsuario, setEditarAgendamentoDeEquipeUsuario] = useState(false)
  const [EditarAgendamentoDeEquipeTodos, setEditarAgendamentoDeEquipeTodos] = useState(false)
  const [ExcluirAgendamentoDeEquipeUsuario, setExcluirAgendamentoDeEquipeUsuario] = useState(false)
  const [ExcluirAgendamentoDeEquipeTodos, setExcluirAgendamentoDeEquipeTodos] = useState(false)
  const [AcessoTotalAgendamentoDeEquipe, setAcessoTotalAgendamentoDeEquipe] = useState(false)

  //controle "aba" AGENDAMENTO - fim

  //controle "aba" DASHBOARD - fim
  const [DashBoard, setDashBoard] = useState(false)
  const [TorreControle, set_TorreControle] = useState(false)


  //controle do acesso total perfil MASTER
  function setAllPermissaoPerfilMaster(status_master: any) {
    if (status_master == true) {
      setVisualizarEmpresaHolding(true)
      setIncluirEmpresaHolding(true)
      setEditarEmpresaHolding(true)
      setExcluirEmpresaHolding(true)
      setAcessoTotalEmpresaHolding(true)

      setVisualizarPerfilPermissao(true)
      setIncluirPerfilPermissao(true)
      setEditarPerfilPermissao(true)
      setExcluirPerfilPermissao(true)
      setAcessoTotalPerfilPermissao(true)

      setVisualizarUsuario(true)
      setIncluirUsuario(true)
      setEditarUsuario(true)
      setExcluirUsuario(true)
      setAcessoTotalUsuario(true)

      setVisualizarCodSistema(true)
      setIncluirCodSistema(true)
      setEditarCodSistema(true)
      setExcluirCodSistema(true)
      setAcessoTotalCodSistema(true)

      setVisualizarFilial(true)
      setIncluirFilial(true)
      setEditarFilial(true)
      setExcluirFilial(true)
      setAcessoTotalFilial(true)

      setVisualizarDepositante(true)
      setIncluirDepositante(true)
      setEditarDepositante(true)
      setExcluirDepositante(true)
      setAcessoTotalDepositante(true)

      setVisualizarEquipeOperacional(true)
      setIncluirEquipeOperacional(true)
      setEditarEquipeOperacional(true)
      setExcluirEquipeOperacional(true)
      setAcessoTotalEquipeOperacional(true)

      setVisualizarTempoOperacional(true)
      setIncluirTempoOperacional(true)
      setEditarTempoOperacional(true)
      setExcluirTempoOperacional(true)
      setAcessoTotalTempoOperacional(true)

      setVisualizarAgendaEquipe(true)
      setIncluirAgendaEquipe(true)
      setEditarAgendaEquipe(true)
      setExcluirAgendaEquipe(true)
      setAcessoTotalAgendaEquipe(true)

      setVisualizarEstoqueSintetico(true)
      setVisualizarEstoqueValidadeLote(true)

      setVisualizarEnviarPedido(true)
      setVisualizarPedidoStatus(true)
      setVisualizarPedidoResumido(true)
      setVisualizarPedidoValidadeLote(true)
      setVisualizarPedidoBarra(true)

      setVisualizarAgendamentoDeEquipe(true)
      setIncluirAgendamentoDeEquipe(true)
      setEditarAgendamentoDeEquipeUsuario(true)
      setEditarAgendamentoDeEquipeTodos(true)
      setExcluirAgendamentoDeEquipeUsuario(true)
      setExcluirAgendamentoDeEquipeTodos(true)
      setAcessoTotalAgendamentoDeEquipe(true)

      setVisualizarUsuarioFilial(true)
      setIncluirUsuarioFilial(true)
      setEditarUsuarioFilial(true)
      setExcluirUsuarioFilial(true)
      setAcessoTotalUsuarioFilial(true)

      setVisualizarResumidoValidade(true)
      setVisualizarDetalhadoBarra(true)

      setDashBoard(true)
      set_TorreControle(false)
    }
    else {
      setVisualizarEmpresaHolding(false)
      setIncluirEmpresaHolding(false)
      setEditarEmpresaHolding(false)
      setExcluirEmpresaHolding(false)
      setAcessoTotalEmpresaHolding(false)

      setVisualizarPerfilPermissao(false)
      setIncluirPerfilPermissao(false)
      setEditarPerfilPermissao(false)
      setExcluirPerfilPermissao(false)
      setAcessoTotalPerfilPermissao(false)

      setVisualizarUsuario(false)
      setIncluirUsuario(false)
      setEditarUsuario(false)
      setExcluirUsuario(false)
      setAcessoTotalUsuario(false)

      setVisualizarUsuarioFilial(false)
      setIncluirUsuarioFilial(false)
      setEditarUsuarioFilial(false)
      setExcluirUsuarioFilial(false)
      setAcessoTotalUsuarioFilial(false)

      setVisualizarCodSistema(false)
      setIncluirCodSistema(false)
      setEditarCodSistema(false)
      setExcluirCodSistema(false)
      setAcessoTotalCodSistema(false)

      setVisualizarFilial(false)
      setIncluirFilial(false)
      setEditarFilial(false)
      setExcluirFilial(false)
      setAcessoTotalFilial(false)

      setVisualizarDepositante(false)
      setIncluirDepositante(false)
      setEditarDepositante(false)
      setExcluirDepositante(false)
      setAcessoTotalDepositante(false)


      setVisualizarEquipeOperacional(false)
      setIncluirEquipeOperacional(false)
      setEditarEquipeOperacional(false)
      setExcluirEquipeOperacional(false)
      setAcessoTotalEquipeOperacional(false)

      setVisualizarTempoOperacional(false)
      setIncluirTempoOperacional(false)
      setEditarTempoOperacional(false)
      setExcluirTempoOperacional(false)
      setAcessoTotalTempoOperacional(false)

      setVisualizarAgendaEquipe(false)
      setIncluirAgendaEquipe(false)
      setEditarAgendaEquipe(false)
      setExcluirAgendaEquipe(false)
      setAcessoTotalAgendaEquipe(false)

      setVisualizarEstoqueSintetico(false)
      setVisualizarEstoqueValidadeLote(false)

      setVisualizarEnviarPedido(false)
      setVisualizarPedidoStatus(false)
      setVisualizarPedidoResumido(false)
      setVisualizarPedidoValidadeLote(false)
      setVisualizarPedidoBarra(false)


      setVisualizarAgendamentoDeEquipe(false)
      setIncluirAgendamentoDeEquipe(false)
      setEditarAgendamentoDeEquipeUsuario(false)
      setEditarAgendamentoDeEquipeTodos(false)
      setExcluirAgendamentoDeEquipeUsuario(false)
      setExcluirAgendamentoDeEquipeTodos(false)
      setAcessoTotalAgendamentoDeEquipe(false)

      setVisualizarResumidoValidade(false)
      setVisualizarDetalhadoBarra(false)

      setDashBoard(false)
      set_TorreControle(false)
    }

  }

  //manipulando o "acesso total" de cada modulo
  function acessoTotalCheked() {
    if (visualizarEmpresaHolding == true && incluirEmpresaHolding == true && EditarEmpresaHolding == true && ExcluirEmpresaHolding == true) {
      setAcessoTotalEmpresaHolding(true)
    }
    if (visualizarPerfilPermissao == true && incluirPerfilPermissao == true && EditarPerfilPermissao == true && ExcluirPerfilPermissao == true) {
      setAcessoTotalPerfilPermissao(true)
    }
    if (visualizarUsuario == true && incluirUsuario == true && EditarUsuario == true && ExcluirUsuario == true) {
      setAcessoTotalUsuario(true)
    }
    if (visualizarUsuarioFilial == true && incluirUsuarioFilial == true && EditarUsuarioFilial == true && ExcluirUsuarioFilial == true) {
      setAcessoTotalUsuarioFilial(true)
    }
    if (visualizarCodSistema == true && incluirCodSistema == true && EditarCodSistema == true && ExcluirCodSistema == true) {
      setAcessoTotalCodSistema(true)
    }
    if (visualizarFilial == true && incluirFilial == true && EditarFilial == true && ExcluirFilial == true) {
      setAcessoTotalFilial(true)
    }
    if (visualizarDepositante == true && incluirDepositante == true && EditarDepositante == true && ExcluirDepositante == true) {
      setAcessoTotalDepositante(true)
    }
    if (visualizarEquipeOperacional == true && incluirEquipeOperacional == true && EditarEquipeOperacional == true && ExcluirEquipeOperacional == true) {
      setAcessoTotalEquipeOperacional(true)
    }
    if (visualizarTempoOperacional == true && incluirTempoOperacional == true && EditarTempoOperacional == true && ExcluirTempoOperacional == true) {
      setAcessoTotalTempoOperacional(true)
    }
    if (visualizarAgendaEquipe == true && incluirAgendaEquipe == true && EditarAgendaEquipe == true && ExcluirAgendaEquipe == true) {
      setAcessoTotalAgendaEquipe(true)
    }
    if (visualizarAgendamentoDeEquipe == true &&
      incluirAgendamentoDeEquipe == true &&
      EditarAgendamentoDeEquipeUsuario == true &&
      EditarAgendamentoDeEquipeTodos == true &&
      ExcluirAgendamentoDeEquipeUsuario == true &&
      ExcluirAgendamentoDeEquipeTodos == true
    ) {
      setAcessoTotalAgendamentoDeEquipe(true)
    }
  }


  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Geral" value="1" />
                    <Tab label="Permissões" value="2" onClick={function () {
                      acessoTotalCheked()
                    }} />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col>
                      <h4>Informações sobre campo Nivel Perfil</h4>
                      <tr>0 - Perfil Master</tr>
                      <tr>1 - Adm Filial</tr>
                      <tr>3 - Operacional</tr>
                      <tr>4 - Depositante</tr>
                      <tr><i>*Quanto menor o nivel mais permissões terá.</i></tr>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Cód. Perfil"
                        name="codperfil"
                        type="text"
                        placeholder="Cód. Perfil"
                        readonly
                        value={id_perfil || "novo"}
                        onChange={setIdPerfil}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <div className="form-floating w-100">

                        <input type="text" list="datalistNivelPerfil"
                          className="form-control bordasInferiorInput text-black text-capitalize"
                          placeholder="Nivel Perfil"
                          value={NivelPerfil}
                          aria-label="Nivel Perfil"
                          onChange={function (e: any) {

                            if (e.target.value / 1 != e.target.value) {
                              toast.error("Valor inválido para o campo Nivel Perfil")
                            } else if (e.target.value > 4) {
                              toast.error("Valor inválido para o campo Nivel Perfil")
                            }
                            else {
                              setNivelPerfil(e.target.value)
                            }
                          }}
                        />
                        <label htmlFor="">Nivel Perfil</label>
                      </div>

                      <datalist id="datalistNivelPerfil">
                        {/* Opções do datalist */}
                        <option value="0" />
                        <option value="1" />
                        <option value="2" />
                        <option value="3" />
                        <option value="4" />
                      </datalist>
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Nome Perfil"
                        name="nomeperfil"
                        type="text"
                        placeholder="Nome Perfil"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={nome_perfil}
                        onChange={setNomePerfil}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={ativo}
                        onChange={() => setAtivo(!ativo)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Perfil Master
                      </label>
                      <Switch
                        checked={master}
                        onChange={function () {
                          setMaster(!master)
                          setAllPermissaoPerfilMaster(!master)
                        }}
                        disabled={
                          acao === "Visualizar" || isSuper === "0"
                            ? true
                            : false
                        }
                        color="error"
                      />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  {/*INICIO - ADMINISTRATIVO */}
                  <div className="accordion" id="acordeao-exemplo">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-1">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-1"

                          aria-expanded="true"
                          aria-controls="item-1"

                        >

                          Administrativo

                        </h4>

                      </h2>
                      <div

                        id="item-1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-1"
                        data-bs-parent="#acordeao-exemplo"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            {/*INICIO EMPRESA HOLDING */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Empresa (Holding)</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEmpresaHolding"
                                      checked={visualizarEmpresaHolding}
                                      onChange={function (e: any) {
                                        setVisualizarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirEmpresaHolding"
                                      checked={incluirEmpresaHolding}
                                      onChange={function (e: any) {
                                        setIncluirEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarEmpresaHolding"
                                      checked={EditarEmpresaHolding}
                                      onChange={function (e: any) {
                                        setEditarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirEmpresaHolding"
                                      checked={ExcluirEmpresaHolding}
                                      onChange={function (e: any) {
                                        setExcluirEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalEmpresaHolding"
                                      checked={AcessoTotalEmpresaHolding}
                                      onChange={function (e: any) {
                                        setAcessoTotalEmpresaHolding(e.target.checked)
                                        setExcluirEmpresaHolding(e.target.checked)
                                        setEditarEmpresaHolding(e.target.checked)
                                        setIncluirEmpresaHolding(e.target.checked)
                                        setVisualizarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* FIM EMPRESA HOLDING */}
                            {/*INICIO PERFIL / PERMISSÃO */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Perfil / Permissão</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuPerfilPermissao"
                                      checked={visualizarPerfilPermissao}
                                      onChange={function (e: any) {
                                        setVisualizarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirPerfilPermissao"
                                      checked={incluirPerfilPermissao}
                                      onChange={function (e: any) {
                                        setIncluirPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarPerfilPermissao"
                                      checked={EditarPerfilPermissao}
                                      onChange={function (e: any) {
                                        setEditarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirPerfilPermissao"
                                      checked={ExcluirPerfilPermissao}
                                      onChange={function (e: any) {
                                        setExcluirPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalPerfilPermissao"
                                      checked={AcessoTotalPerfilPermissao}
                                      onChange={function (e: any) {
                                        setAcessoTotalPerfilPermissao(e.target.checked)
                                        setExcluirPerfilPermissao(e.target.checked)
                                        setEditarPerfilPermissao(e.target.checked)
                                        setIncluirPerfilPermissao(e.target.checked)
                                        setVisualizarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM PERFIL / PERMISSÃO */}
                            {/*INICIO USUARIO */}
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Usuario</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuUsuario"
                                      checked={visualizarUsuario}
                                      onChange={function (e: any) {
                                        setVisualizarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirUsuario"
                                      checked={incluirUsuario}
                                      onChange={function (e: any) {
                                        setIncluirUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarUsuario"
                                      checked={EditarUsuario}
                                      onChange={function (e: any) {
                                        setEditarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirUsuario"
                                      checked={ExcluirUsuario}
                                      onChange={function (e: any) {
                                        setExcluirUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalUsuario"
                                      checked={AcessoTotalUsuario}
                                      onChange={function (e: any) {
                                        setAcessoTotalUsuario(e.target.checked)
                                        setExcluirUsuario(e.target.checked)
                                        setEditarUsuario(e.target.checked)
                                        setIncluirUsuario(e.target.checked)
                                        setVisualizarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM USUARIO */}
                            {/*INICIO USUARIO FILIAL */}
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Usuarios/Filial</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuUsuario"
                                      checked={visualizarUsuarioFilial}
                                      onChange={function (e: any) {
                                        setVisualizarUsuarioFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirUsuarioFilial"
                                      checked={incluirUsuarioFilial}
                                      onChange={function (e: any) {
                                        setIncluirUsuarioFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarUsuarioFilial"
                                      checked={EditarUsuarioFilial}
                                      onChange={function (e: any) {
                                        setEditarUsuarioFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirUsuarioFilial"
                                      checked={ExcluirUsuarioFilial}
                                      onChange={function (e: any) {
                                        setExcluirUsuarioFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalUsuarioFilial"
                                      checked={AcessoTotalUsuarioFilial}
                                      onChange={function (e: any) {
                                        setAcessoTotalUsuarioFilial(e.target.checked)
                                        setExcluirUsuarioFilial(e.target.checked)
                                        setEditarUsuarioFilial(e.target.checked)
                                        setIncluirUsuarioFilial(e.target.checked)
                                        setVisualizarUsuarioFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM USUARIO FILIAL */}
                            {/*INICIO CODIGO SISTEMA */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Código do Sistema</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuCodSistema"
                                      checked={visualizarCodSistema}
                                      onChange={function (e: any) {
                                        setVisualizarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirCodSistema"
                                      checked={incluirCodSistema}
                                      onChange={function (e: any) {
                                        setIncluirCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarCodSistema"
                                      checked={EditarCodSistema}
                                      onChange={function (e: any) {
                                        setEditarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirCodSistema"
                                      checked={ExcluirCodSistema}
                                      onChange={function (e: any) {
                                        setExcluirCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalCodSistema"
                                      checked={AcessoTotalCodSistema}
                                      onChange={function (e: any) {
                                        setAcessoTotalCodSistema(e.target.checked)
                                        setExcluirCodSistema(e.target.checked)
                                        setEditarCodSistema(e.target.checked)
                                        setIncluirCodSistema(e.target.checked)
                                        setVisualizarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM CODIGO SISTEMA */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - ADMINISTRATIVO */}
                  <br />
                  {/*INICIO - CADASTRO */}
                  <div className="accordion" id="acordeao-exemplo2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-2">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-2"

                          aria-expanded="true"
                          aria-controls="item-2"

                        >

                          Cadastro

                        </h4>

                      </h2>
                      <div

                        id="item-2"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-2"
                        data-bs-parent="#acordeao-exemplo2"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Filial</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuFilial"
                                      checked={visualizarFilial}
                                      onChange={function (e: any) {
                                        setVisualizarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirFilial"
                                      checked={incluirFilial}
                                      onChange={function (e: any) {
                                        setIncluirFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarFilial"
                                      checked={EditarFilial}
                                      onChange={function (e: any) {
                                        setEditarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFilial"
                                      checked={ExcluirFilial}
                                      onChange={function (e: any) {
                                        setExcluirFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalFilial"
                                      checked={AcessoTotalFilial}
                                      onChange={function (e: any) {
                                        setAcessoTotalFilial(e.target.checked)
                                        setExcluirFilial(e.target.checked)
                                        setEditarFilial(e.target.checked)
                                        setIncluirFilial(e.target.checked)
                                        setVisualizarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr id="tpr-1">
                                  <td className="w-25">Depositante</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuDepositante"
                                      checked={visualizarDepositante}
                                      onChange={function (e: any) {
                                        setVisualizarDepositante(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirDepositante"
                                      checked={incluirDepositante}
                                      onChange={function (e: any) {
                                        setIncluirDepositante(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarDepositante"
                                      checked={EditarDepositante}
                                      onChange={function (e: any) {
                                        setEditarDepositante(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirDepositante"
                                      checked={ExcluirDepositante}
                                      onChange={function (e: any) {
                                        setExcluirDepositante(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalDepositante"
                                      checked={AcessoTotalDepositante}
                                      onChange={function (e: any) {
                                        setAcessoTotalDepositante(e.target.checked)
                                        setExcluirDepositante(e.target.checked)
                                        setEditarDepositante(e.target.checked)
                                        setIncluirDepositante(e.target.checked)
                                        setVisualizarDepositante(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr id="tpr-1">
                                  <td className="w-25">Equipe Operacional</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEquipeOperacional"
                                      checked={visualizarEquipeOperacional}
                                      onChange={function (e: any) {
                                        setVisualizarEquipeOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirEquipeOperacional"
                                      checked={incluirEquipeOperacional}
                                      onChange={function (e: any) {
                                        setIncluirEquipeOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarEquipeOperacional"
                                      checked={EditarEquipeOperacional}
                                      onChange={function (e: any) {
                                        setEditarEquipeOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirEquipeOperacional"
                                      checked={ExcluirEquipeOperacional}
                                      onChange={function (e: any) {
                                        setExcluirEquipeOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalEquipeOperacional"
                                      checked={AcessoTotalEquipeOperacional}
                                      onChange={function (e: any) {
                                        setAcessoTotalEquipeOperacional(e.target.checked)
                                        setExcluirEquipeOperacional(e.target.checked)
                                        setEditarEquipeOperacional(e.target.checked)
                                        setIncluirEquipeOperacional(e.target.checked)
                                        setVisualizarEquipeOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr id="tpr-1">
                                  <td className="w-25">Tempo Operacional</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuTempoOperacional"
                                      checked={visualizarTempoOperacional}
                                      onChange={function (e: any) {
                                        setVisualizarTempoOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirTempoOperacional"
                                      checked={incluirTempoOperacional}
                                      onChange={function (e: any) {
                                        setIncluirTempoOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarTempoOperacional"
                                      checked={EditarTempoOperacional}
                                      onChange={function (e: any) {
                                        setEditarTempoOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirTempoOperacional"
                                      checked={ExcluirTempoOperacional}
                                      onChange={function (e: any) {
                                        setExcluirTempoOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalTempoOperacional"
                                      checked={AcessoTotalTempoOperacional}
                                      onChange={function (e: any) {
                                        setAcessoTotalTempoOperacional(e.target.checked)
                                        setExcluirTempoOperacional(e.target.checked)
                                        setEditarTempoOperacional(e.target.checked)
                                        setIncluirTempoOperacional(e.target.checked)
                                        setVisualizarTempoOperacional(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr id="tpr-1">
                                  <td className="w-25">Agenda Equipe</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuTempoOperacional"
                                      checked={visualizarAgendaEquipe}
                                      onChange={function (e: any) {
                                        setVisualizarAgendaEquipe(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirAgendaEquipe"
                                      checked={incluirAgendaEquipe}
                                      onChange={function (e: any) {
                                        setIncluirAgendaEquipe(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarAgendaEquipe"
                                      checked={EditarAgendaEquipe}
                                      onChange={function (e: any) {
                                        setEditarAgendaEquipe(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirAgendaEquipe"
                                      checked={ExcluirAgendaEquipe}
                                      onChange={function (e: any) {
                                        setExcluirAgendaEquipe(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalAgendaEquipe"
                                      checked={AcessoTotalAgendaEquipe}
                                      onChange={function (e: any) {
                                        setAcessoTotalAgendaEquipe(e.target.checked)
                                        setExcluirAgendaEquipe(e.target.checked)
                                        setEditarAgendaEquipe(e.target.checked)
                                        setIncluirAgendaEquipe(e.target.checked)
                                        setVisualizarAgendaEquipe(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - CADASTROS*/}
                  <br />
                  {/*INICIO ESTOQUE */}
                  <div className="accordion" id="acordeao-exemplo2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-2">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-3"

                          aria-expanded="true"
                          aria-controls="item-3"

                        >

                          Estoque

                        </h4>
                      </h2>
                      <div

                        id="item-3"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-3"
                        data-bs-parent="#acordeao-exemplo3"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Estoque Sintético</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueSintetico"
                                      checked={visualizarEstoqueSintetico}
                                      onChange={function (e: any) {
                                        setVisualizarEstoqueSintetico(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <div className="w-100"></div>
                                <tr id="tpr-1">
                                  <td className="w-25">Estoque Validade/Lote</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueValidadeLote"
                                      checked={visualizarEstoqueValidadeLote}
                                      onChange={function (e: any) {
                                        setVisualizarEstoqueValidadeLote(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/*FIM ESTOQUE */}
                  {/*INICIO PEDIDO */}
                  <div className="accordion" id="acordeao-exemplo2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-2">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-4"

                          aria-expanded="true"
                          aria-controls="item-4"

                        >

                          Pedidos

                        </h4>
                      </h2>
                      <div

                        id="item-4"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-4"
                        data-bs-parent="#acordeao-exemplo3"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Enviar Pedido</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueSintetico"
                                      checked={visualizarEnviarPedido}
                                      onChange={function (e: any) {
                                        setVisualizarEnviarPedido(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <div className="w-100"></div>
                                <tr id="tpr-1">
                                  <td className="w-25">Consultar Pedido Status</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueValidadeLote"
                                      checked={visualizarPedidoStatus}
                                      onChange={function (e: any) {
                                        setVisualizarPedidoStatus(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <div className="w-100"></div>
                                <tr id="tpr-1">
                                  <td className="w-25">Consultar Pedido Resumido</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueValidadeLote"
                                      checked={visualizarPedidoResumido}
                                      onChange={function (e: any) {
                                        setVisualizarPedidoResumido(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <div className="w-100"></div>
                                <tr id="tpr-1">
                                  <td className="w-25">Consultar Pedido Validade/Lote</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueValidadeLote"
                                      checked={visualizarPedidoValidadeLote}
                                      onChange={function (e: any) {
                                        setVisualizarPedidoValidadeLote(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <div className="w-100"></div>
                                <tr id="tpr-1">
                                  <td className="w-25">Consultar Pedido Barra</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueValidadeLote"
                                      checked={visualizarPedidoBarra}
                                      onChange={function (e: any) {
                                        setVisualizarPedidoBarra(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*FIM pedido */}
                  <br />
                  {/*INICIO PEDIDO */}
                  <div className="accordion" id="acordeao-exemplo2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-2">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-4"

                          aria-expanded="true"
                          aria-controls="item-4"

                        >

                          Recebimentos

                        </h4>
                      </h2>
                      <div

                        id="item-4"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-4"
                        data-bs-parent="#acordeao-exemplo3"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Resumido Validade</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueSintetico"
                                      checked={visualizarResumidoValidade}
                                      onChange={function (e: any) {
                                        setVisualizarResumidoValidade(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <div className="w-100"></div>
                                <tr id="tpr-1">
                                  <td className="w-25">Detalhado por Barra</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEstoqueValidadeLote"
                                      checked={visualizarDetalhadoBarra}
                                      onChange={function (e: any) {
                                        setVisualizarDetalhadoBarra(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*FIM pedido */}
                  <br />
                  {/*INICIO AGENDAMENTO */}

                  <div className="accordion" id="acordeao-exemplo2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-2">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-2"

                          aria-expanded="true"
                          aria-controls="item-2"

                        >

                          Agendamento

                        </h4>

                      </h2>
                      <div

                        id="item-2"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-2"
                        data-bs-parent="#acordeao-exemplo2"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar Agnd Usuário</th>
                                  <th className="text-center">Editar Agnd Todos</th>
                                  <th className="text-center">Excluir Agnd Usuario</th>
                                  <th className="text-center">Excluir Agnd Todos</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Agendamento de Equipes</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuFilial"
                                      checked={visualizarAgendamentoDeEquipe}
                                      onChange={function (e: any) {
                                        setVisualizarAgendamentoDeEquipe(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirFilial"
                                      checked={incluirAgendamentoDeEquipe}
                                      onChange={function (e: any) {
                                        setIncluirAgendamentoDeEquipe(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarFilial"
                                      checked={EditarAgendamentoDeEquipeUsuario}
                                      onChange={function (e: any) {
                                        setEditarAgendamentoDeEquipeUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFilial"
                                      checked={EditarAgendamentoDeEquipeTodos}
                                      onChange={function (e: any) {
                                        setEditarAgendamentoDeEquipeTodos(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalFilial"
                                      checked={ExcluirAgendamentoDeEquipeUsuario}
                                      onChange={function (e: any) {
                                        setExcluirAgendamentoDeEquipeUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFilial"
                                      checked={ExcluirAgendamentoDeEquipeTodos}
                                      onChange={function (e: any) {
                                        setExcluirAgendamentoDeEquipeTodos(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFilial"
                                      checked={AcessoTotalAgendamentoDeEquipe}
                                      onChange={function (e: any) {
                                        setAcessoTotalAgendamentoDeEquipe(e.target.checked)
                                        setVisualizarAgendamentoDeEquipe(e.target.checked)
                                        setIncluirAgendamentoDeEquipe(e.target.checked)
                                        setEditarAgendamentoDeEquipeUsuario(e.target.checked)
                                        setEditarAgendamentoDeEquipeTodos(e.target.checked)
                                        setExcluirAgendamentoDeEquipeUsuario(e.target.checked)
                                        setExcluirAgendamentoDeEquipeTodos(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*FIM AGENDAMENTO */}
                  <br />
                  {/*DASHBOARD INICIO */}
                  <div className="accordion" id="acordeao-exemplo2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-2">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-2"

                          aria-expanded="true"
                          aria-controls="item-2"

                        >

                          Relatórios

                        </h4>

                      </h2>
                      <div

                        id="item-2"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-2"
                        data-bs-parent="#acordeao-exemplo2"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">DashBoard Agenda</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFilial"
                                      checked={DashBoard}
                                      onChange={function (e: any) {
                                        setDashBoard(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr id="tpr-1">
                                  <td className="w-25">Torre de controle</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFilial"
                                      checked={TorreControle}
                                      onChange={function (e: any) {
                                        set_TorreControle(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*DASHBOARD FIM */}
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <Button
                invisivel={false}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalLoading show={loading} label="Carregado dados do perfil ..." />
    </>
  );
};
export default FormPerfil;
