const FooterBar = (): JSX.Element => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            {new Date().getFullYear()}©FKL Service Tecnologia - v1.0.1
          </div>
          <div className="col-md-6">
            <div className="text-md-end footer-links d-none d-md-block">
              <a href="#">Sobre</a>
              <a href="#">Suporte</a>
              <a href="#">Contato</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export { FooterBar };
