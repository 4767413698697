import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../components/Formularios/Modal/ModalUpload";
import ConverterBase64 from "../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../components/Auxiliar/ConvTextoUpper";
import { getSessionData } from "../../../../../utils/storageUtils";
import ApiGet from "../../../../../api/endPoints/useGet";
import axios from "axios";

interface FormEmpresaProps {
  idempresa?: string;
  acao?: string;
}

const FormEmpresa = ({ idempresa, acao }: FormEmpresaProps) => {
  const tokenAuth = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_perfil = getSessionData("id_token")

  const navigate = useNavigate();

  const [expandir, setExpandir] = useState<string | false>(false);

  const abrirOpcoes =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandir(isExpanded ? panel : false);
    };

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);

  const [id_empresa, setIdEmpresa] = useState<any>(idempresa || "");
  const [cnpj, setCNPJ] = useState<string>("");
  const [ie, setIE] = useState<string>("");
  const [razaoSocial, setRazaoSocial] = useState<string>("");
  const [nomeFantasia, setNomeFantasia] = useState<string>("");
  const [filial, setFilial] = useState<boolean>(true);
  const [ativo, setAtivo] = useState<boolean>(true);
  const [fone, setFone] = useState<string>("");
  const [celular, setCelular] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [cep, setCEP] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [complemento, setComplemento] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [codIbge, setCodIbge] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [uf, setUF] = useState<string>("");
  const [pais, setPais] = useState<string>("1058" || "");
  const [avatar, setAvatar] = useState<string>("");
  const [itensPorPagina, setItensPorPagina] = useState<string>("");
  const [qtdeFilial, setQtdeFilial] = useState<string>("");
  const [tempo_ocioso, setTempo_Ocioso] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const carregar = (url: string, idempresa: any) => {
    setLoading(true);
    CarregarDados(url, idempresa)
      .then((retorno) => {
        setLoading(false);
        setIdEmpresa(retorno[0].id);
        setCNPJ(retorno[0].cnpj || "");
        setIE(retorno[0].ie || "");
        setNomeFantasia(retorno[0].fantasia || "");
        setRazaoSocial(retorno[0].razao_social || "");
        setLogradouro(retorno[0].logradouro || "");
        setComplemento(retorno[0].complemento || "");
        setNumero(retorno[0].numero || "");
        setBairro(retorno[0].bairro || "");
        setCidade(retorno[0].cidade || "");
        setCodIbge(retorno[0].cmun || "");
        setCEP(retorno[0].cep || "");
        setUF(retorno[0].uf || "");
        setPais(retorno[0].cpais || "");
        setEmail(retorno[0].email || "");
        setFilial(retorno[0].filial === 1 ? true : false);
        setAtivo(retorno[0].situacao === 1 ? true : false);
        setToken(retorno[0].token || "");
        setAvatar(retorno[0].avatar || "");
        setItensPorPagina(retorno[0].itempagina || "");
        setQtdeFilial(retorno[0].num_filial || "");
        setTempo_Ocioso(retorno[0].tempo_ocioso || "");
        setFone(retorno[0].fone || "")
        setCelular(retorno[0].celular || "")
        setSite(retorno[0].site || "")
      })
      .catch((erro) => {
        setLoading(false);
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response?.data.message;
        toast.error("Falha ao Carregar Dados. Motivo", msg);
      });
  };

  useEffect(() => {
    if (idempresa !== "novo") {
      carregar(url_CarregarDados_ID, idempresa);
    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    id_empresa: string,
    ie: string,
    fantasia: string,
    razao_social: string,
    logradouro: string,
    numero: string,
    complemento: string,
    bairro: string,
    cidade: string,
    cmun: string,
    cep: string,
    cuf: string,
    cpais: string,
    email: string,
    filial: number,
    token: string,
    situacao: number,
    avatar: string,
    itensporpagina: string,
    qtdefilial: string,
    tempo_ocioso: string,
    fone: string,
    celular: string,
    site: string,
    usuarioLogado: any
  ) => {
    const dados = {
      ie,
      fantasia,
      razao_social,
      logradouro,
      numero,
      complemento,
      bairro,
      cidade,
      cmun,
      cep,
      cuf,
      cpais,
      email,
      filial,
      token,
      situacao,
      avatar,
      itensporpagina,
      qtdefilial,
      tempo_ocioso,
      fone,
      celular,
      site,
      usuarioLogado: getSessionData("NomeUsuario")
    };

    ApiPut(`/empresa/atualizar/${id_empresa}`, dados, tokenAuth)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    cnpj: string,
    ie: string,
    fantasia: string,
    razao_social: string,
    logradouro: string,
    numero: string,
    complemento: string,
    bairro: string,
    cidade: string,
    cmun: string,
    cep: string,
    cuf: string,
    cpais: string,
    email: string,
    filial: number,
    token: string,
    situacao: number,
    avatar: string,
    itensporpagina: string,
    qtdefilial: string,
    tempo_ocioso: string,
    fone: string,
    celular: string,
    site: string,
    usuarioLogado: any
  ) => {
    const dados = {
      cnpj,
      ie,
      fantasia,
      razao_social,
      logradouro,
      numero,
      complemento,
      bairro,
      cidade,
      cmun,
      cep,
      cuf,
      cpais,
      email,
      filial,
      token,
      situacao,
      avatar,
      itensporpagina,
      qtdefilial,
      tempo_ocioso,
      fone,
      celular,
      site,
      usuarioLogado: getSessionData("NomeUsuario")
    };
    ApiPost("/empresa/gravar", dados, tokenAuth)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idempresa === "novo") {
      gravarDados(
        cnpj,
        ie,
        nomeFantasia,
        razaoSocial,
        logradouro,
        numero,
        complemento,
        bairro,
        cidade,
        codIbge,
        cep,
        uf,
        pais,
        email,
        filial ? 1 : 0,
        token,
        ativo ? 1 : 0,
        avatar,
        itensPorPagina,
        qtdeFilial,
        tempo_ocioso,
        fone,
        celular,
        site,
        getSessionData("NomeUsuario")
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        id_empresa,
        ie,
        nomeFantasia,
        razaoSocial,
        logradouro,
        numero,
        complemento,
        bairro,
        cidade,
        codIbge,
        cep,
        uf,
        pais,
        email,
        filial ? 1 : 0,
        token,
        ativo ? 1 : 0,
        avatar,
        itensPorPagina,
        qtdeFilial,
        tempo_ocioso,
        fone,
        celular,
        site,
        getSessionData("NomeUsuario")
      );
      toast.info("Atualizando dados do registro...");
    }
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setShowUpload(true);
      ConverterBase64(file)
        .then((retorno) => {
          setShowUpload(false);
          setAvatar(retorno);
        })
        .catch((erro) => {
          setShowUpload(false);
          toast.error(erro);
        });
    }
  };
  const cepRef = useRef<HTMLInputElement>(null);
  const handleRemoveAvatar = () => {
    setAvatar("");
  };

  const buscarCep = async (cep: string) => {
    if (cep.length < 8) {
      toast.error("CEP inválido!");
    } else if (cep.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((retorno) => {
          if (retorno.data?.erro) {
            toast.error("CEP inválido ou Não Localizado!");
          } else {
            const dadosCep = retorno.data;

            setLogradouro(dadosCep.logradouro);
            setComplemento(dadosCep.complemento);
            setBairro(dadosCep.bairro);
            setCidade(dadosCep.localidade);
            setUF(dadosCep.uf);
            setCodIbge(dadosCep.ibge);
          }
        })
        .catch((erro) => {
          console.log("erro: ", erro);
          const msg =
            erro.response?.data.message === undefined
              ? erro.message
              : erro.response.data.message;
          toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
        });
    }
  };


  async function carregarDadosCNPJ(event: any) {

    setCNPJ(event)
    if (event.length == 14) {
      const response = await axios.get(`https://publica.cnpj.ws/cnpj/${event}`)

      setCNPJ(response.data.estabelecimento.cnpj)
      setNomeFantasia(response.data.estabelecimento.nome_fantasia)
      setLogradouro(response.data.estabelecimento.logradouro)
      setNumero(response.data.estabelecimento.numero)
      setComplemento(response.data.estabelecimento.complemento)
      setBairro(response.data.estabelecimento.bairro)
      setCidade(response.data.estabelecimento.cidade.nome)
      setCodIbge(response.data.estabelecimento.cidade.ibge_id)
      setCEP(response.data.estabelecimento.cep)
      setUF(response.data.estabelecimento.estado.sigla)
      setPais(response.data.estabelecimento.pais.id)
      setRazaoSocial(response.data.razao_social)
      setIE(response.data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual)
    }
  }

  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Dados Básicos" value="1" />
                    <Tab label="Configurações Básicas" value="2" />

                    {isSuper !== "0" && (
                      <Tab label="Acesso Master" value="99" />
                    )}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cód. Empresa"
                        name="codempresa"
                        type="text"
                        placeholder="Cód. Empresa"
                        readonly
                        value={id_empresa}
                        onChange={setIdEmpresa}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="CNPJ"
                        name="cnpj"
                        type="text"
                        placeholder="CNPJ"
                        required
                        readonly={
                          acao === "Visualizar" || acao === "Editar"
                            ? true
                            : false
                        }
                        onBlur={function (e: any) {
                          const cnpj_formatado = cnpj.replace(/[.\-/]/g, '');
                          carregarDadosCNPJ(cnpj_formatado)
                        }}
                        value={cnpj || ""}
                        onChange={function (e: any) {
                          setCNPJ(e)
                        }}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Inscrição Estadual"
                        name="nomeperfil"
                        type="text"
                        placeholder="Inscrição Estadual"
                        required
                        onBlur={function (e: any) {
                          const ie_formatado = ie.replace(/[.\-/]/g, '')
                          setIE(ie_formatado)
                        }}
                        readonly={acao === "Visualizar" ? true : false}
                        value={ie || ""}
                        onChange={setIE}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-uppercase"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        É Filial?
                      </label>
                      <Switch
                        checked={filial}
                        onChange={() => setFilial(!filial)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={ativo}
                        onChange={() => setAtivo(!ativo)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Razão Social"
                        name="razaosocial"
                        type="text"
                        placeholder="Razão Social"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={razaoSocial || ""}
                        onChange={setRazaoSocial}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Nome Fantasia"
                        name="nomefantasia"
                        type="text"
                        placeholder="Nome Fantasia"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={nomeFantasia || ""}
                        onChange={setNomeFantasia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Telefone"
                        readonly={acao === "Visualizar" ? true : false}
                        value={fone || ""}
                        onChange={setFone}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Celular"
                        name="celular"
                        type="text"
                        placeholder="Celular"
                        readonly={acao === "Visualizar" ? true : false}
                        value={celular || ""}
                        onChange={setCelular}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="E-mail"
                        name="email"
                        type="email"
                        placeholder="E-mail"
                        readonly={acao === "Visualizar" ? true : false}
                        value={email || ""}
                        onChange={setEmail}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Site"
                        name="site"
                        type="text"
                        placeholder="Site"
                        readonly={acao === "Visualizar" ? true : false}
                        value={site || ""}
                        onChange={setSite}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        readonly={acao === "Visualizar" ? true : false}
                        value={cep || ""}
                        onChange={setCEP}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                        onBlur={function (e: any) {
                          const cep_formatado = cep.replace(/[.\-/]/g, '')
                          setCEP(cep_formatado)
                          buscarCep(cep_formatado)
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Complemento"
                        name="complemento"
                        type="text"
                        placeholder="Complemento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={complemento || ""}
                        onChange={setComplemento}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        readonly={acao === "Visualizar" ? true : false}
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cidade"
                        name="cidade"
                        type="text"
                        placeholder="Cidade"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cidade || ""}
                        onChange={setCidade}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel>UF</InputLabel>
                        <Select
                          value={uf}
                          onChange={(e: SelectChangeEvent) =>
                            setUF(e.target.value)
                          }
                          label="UF"
                          required
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Cód. IBGE"
                        name="codibge"
                        type="text"
                        placeholder="Cód. IBGE"
                        readonly={acao === "Visualizar" ? true : false}
                        value={codIbge || ""}
                        onChange={setCodIbge}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel>País</InputLabel>
                        <Select
                          value={pais}
                          onChange={(e: SelectChangeEvent) =>
                            setPais(e.target.value)
                          }
                          label="País"
                          required
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value={pais}>
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosPais.map((item: any) => (
                            <MenuItem value={item.codpais}>
                              {item.pais} ({item.codpais})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    {/*
                    <Col sm={2}>
                      <InputSemBorda
                        label="País"
                        name="cnum"
                        type="text"
                        placeholder="País"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={pais || ""}
                        onChange={setPais}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    */}
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  <Row>
                    <Accordion
                      expanded={expandir === "confBasica"}
                      onChange={abrirOpcoes("confBasica")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="confBasicabh-content"
                        id="confBasicabh-header"
                      >
                        <Typography
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                            fontWeight: "bold",
                          }}
                        >
                          Geral
                        </Typography>
                        <Typography sx={{ color: "text.secondary" }}>
                          Configurações básicas da empresa
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Row>
                          <Col>
                            <table className="table-hover border-bottom hover-bg-blue">
                              <tr>
                                <th>Itens Por Página:</th>
                                <td>
                                  <input
                                    name="itenspagina"
                                    value={itensPorPagina}
                                    type="number"
                                    placeholder="Itens Por Página"
                                    onChange={(e) =>
                                      setItensPorPagina(e.target.value)
                                    }
                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                  />
                                </td>
                                <td>
                                  Informe a quantidade de itens que serão
                                  exibidos nos grid's. (Por padrão serão
                                  exibidos 5 itens por página)
                                </td>
                              </tr>
                              <tr>
                                <th>Tempo Ocioso:</th>
                                <td>
                                  <input
                                    name="tempo_ocioso"
                                    value={tempo_ocioso}
                                    type="number"
                                    placeholder="Tempo Ocioso"
                                    onChange={(e) =>
                                      setTempo_Ocioso(e.target.value)
                                    }
                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                  />
                                </td>
                                <td>
                                  Tempo que o sistema ficará ocioso até que o
                                  usuário seja deslogado automaticamente.
                                  (Minutos)
                                </td>
                              </tr>
                            </table>
                          </Col>
                        </Row>
                        <hr />
                        <Typography>Informe o logotipo da empresa.</Typography>
                        <Row className="mb-2">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {avatar ? (
                              <img
                                alt=""
                                style={{
                                  width: "70%",
                                  height: "250px",
                                  objectFit: "contain",
                                  backgroundImage: `url(data:image/png;base64,${avatar})`,
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              />
                            ) : (
                              <Avatar
                                src={""}
                                alt="Avatar"
                                sx={{ width: 240, height: 240 }}
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              id="avatar-input"
                              onChange={handleAvatarChange}
                              style={{ display: "none" }}
                              disabled={acao === "Visualizar" ? true : false}
                            />
                            <label htmlFor="avatar-input">
                              <IconButton color="primary" component="span">
                                <PhotoCameraIcon />
                              </IconButton>
                            </label>
                            {avatar && acao !== "Visualizar" && (
                              <Button
                                variant="outlined"
                                onClick={handleRemoveAvatar}
                              >
                                Remover Logo
                              </Button>
                            )}
                          </Stack>
                        </Row>
                      </AccordionDetails>
                    </Accordion>
                    {/* <Stack spacing={3}>
                      <Item>Configurações Básicas.</Item>
                    </Stack> */}
                  </Row>
                  {/* <Row>
                    <Col>
                      <table className="table-hover border-bottom hover-bg-blue">
                        <tr>
                          <th>Itens Por Página:</th>
                          <td>
                            <input
                              name="itenspagina"
                              value={itensPorPagina}
                              type="number"
                              placeholder="Itens Por Página"
                              onChange={(e) =>
                                setItensPorPagina(e.target.value)
                              }
                              className="form-control bordasInferiorInput text-black text-capitalize"
                            />
                          </td>
                          <td>
                            Informe a quantidade de itens que serão exibidos nos
                            grid's. (Por padrão serão exibidos 5 itens por
                            página)
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Stack spacing={3}>
                      <Item>Logo - Informe a logomarca da empresa.</Item>
                    </Stack>
                  </Row> */}
                  {/* <br /> */}
                  {/* <Row className="mb-2">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {avatar ? (
                        <img
                          alt=""
                          style={{
                            width: "70%",
                            height: "250px",
                            objectFit: "contain",
                            backgroundImage: `url(data:image/png;base64,${avatar})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                      ) : (
                        <Avatar
                          src={""}
                          alt="Avatar"
                          sx={{ width: 240, height: 240 }}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        id="avatar-input"
                        onChange={handleAvatarChange}
                        style={{ display: "none" }}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                      <label htmlFor="avatar-input">
                        <IconButton color="primary" component="span">
                          <PhotoCameraIcon />
                        </IconButton>
                      </label>
                      {avatar && acao !== "Visualizar" && (
                        <Button variant="outlined" onClick={handleRemoveAvatar}>
                          Remover Logo
                        </Button>
                      )}
                    </Stack>
                  </Row> */}
                  {/* <Row>
                    <Stack spacing={3}>
                      <Item>Dados de Integração</Item>
                    </Stack>
                  </Row>
                  <br /> */}
                  {/* <Row> */}
                  {/* CAMPO PARA CLICAR NO BOTAO GERAR TOKEN E O MESMO SER GERADO */}
                  {/* <Col sm={12}>
                      <InputGroup>
                        <ButtonCustom
                        invisivel={false}
                          type="button"
                          className="btn btn-info "
                          icone={mdiLockCheckOutline}
                          descricaoBotao="Gerar Token"
                        />
                        <InputSemBorda
                          label="Token"
                          name="token"
                          type="text"
                          placeholder="token"
                          readonly
                          value={token}
                          onChange={setToken}
                          classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize "
                        />
                      </InputGroup>
                    </Col>
                  </Row> */}
                </TabPanel>
                <TabPanel value="99">
                  <Accordion
                    expanded={expandir === "panel1"}
                    onChange={abrirOpcoes("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        sx={{
                          width: "33%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                      >
                        Controle de Empresa / Filial
                      </Typography>
                      <Typography sx={{ color: "text.secondary" }}>
                        Configurações destinadas a acesso master para controle
                        de empresa/filial
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row>
                        <Col>
                          <table className="table-hover border-bottom hover-bg-blue">
                            <tr>
                              <th>Quantidade de Filiais</th>
                              <td>
                                <input
                                  name="qtdefilial"
                                  value={qtdeFilial}
                                  type="number"
                                  placeholder="Quantidade de Filiais"
                                  onChange={(e) =>
                                    setQtdeFilial(e.target.value)
                                  }
                                  className="form-control bordasInferiorInput text-black text-capitalize"
                                />
                              </td>
                              <td>
                                Quantidade de filiais que a empresa
                                possui.
                              </td>
                            </tr>
                          </table>
                        </Col>
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                invisivel={false}

                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card >
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregado dados da empresa ..." />
    </>
  );
};
export default FormEmpresa;
