import { Card, Col, Row } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { getSessionData, setSessionData } from "../../../utils/storageUtils";
import { useEffect, useState } from "react";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { computeShrinkWidth } from "@fullcalendar/core/internal";

export function AgendaSalaAtendimento() {

  const id_empresa_usuario = getSessionData("isPerfilVinculo")
  const id_filial_usuario = getSessionData("DadosIdfilial")
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_perfil = getSessionData("id_perfil")
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");
  const [dadosSalas, setDadosSalas] = useState<any[]>([]);

  const buscarSalasAtenimento = (id_filial: any) => {
    setLoading(true);
    setTextoLoading("Carregando Equipes...");

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/equipes/by/filial?app=1&id_filial=${id_filial}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      setDadosSalas(resposta.data.agenda_equipes)
    }).catch(function (erro: any) {

      toast.error(erro)
    })

    setLoading(false)

  }

  const selecionarSala = (dados: any) => {
    setSessionData("DadosEquipe", dados);
    navigate("/agendamento/equipe/agenda")
  }

  //verificando permissões
  const id_perfil_usuario = getSessionData("id_perfil")
  const [visualizarAgendamentoDeEquipe, setvisualizarAgendamentoDeEquipe] = useState(true)
  function carregarPermissões() {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil_usuario}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

        if (resposta.data[0].visualizarAgendamentoDeEquipe == 1) {
          //dar permissão para visualizarAgendamento de Equipes
          setvisualizarAgendamentoDeEquipe(false)
        }
        else{
          toast.error("Perfil sem permissão para visualizar agenda")
        }

      }).catch(function (erro) {
        toast.error(erro)
      })
  }

  useEffect(() => {
    buscarSalasAtenimento(id_filial_usuario)
  }, [id_filial_usuario])

  useEffect(function(){
    carregarPermissões()
  }, [])

  return (
    <>
      <div className="page-content">
        <ContainerTitulo titulo="Agenda de Equipes" />
        <Card>
          <Row>
            {dadosSalas.map((equipe: any) => (
              <Col sm={12} md={6} lg={3} key={equipe.id}>
                <Card className="cardSalasAtendimento" onClick={() => { selecionarSala(equipe) }} hidden={visualizarAgendamentoDeEquipe == true ? true : false}>
                  <label className="text-center text-black">{equipe.equipe}</label>
                  <hr className="margeLinha" />
                  <label className="text-black m-1">
                    Tipo Operação: <label htmlFor="">{equipe.operacao}</label>
                  </label>
                  {/*<label className="text-black m-1">
                    Profisional: {equipe.nome}
                  </label> */}
                  <label className="text-black m-1">
                    Horário: {equipe.hora_inicio} às {equipe.hora_fim}
                  </label>
                  {/*<label className="text-black m-1">
                    Duração: {equipe.duracao_janela}
                  </label> */}
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </div>
      <ModalLoading show={loading} label={textoLoading} />
    </>
  )
}

export default AgendaSalaAtendimento
