import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../../components/Formularios/Inputs/InputsSemBorda";
// import applyCepMask  from "../../../../../../components/Formularios/Inputs/InputComMascara";
import { mascaraCEP, mascaraTelefoneCelular, mascaraTelefoneFixo } from "../../../../../../hooks/mascaras";

import {
  Box,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Stack,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../../../components/Formularios/Buttons/Button";
import {
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiLockCheckOutline,
} from "@mdi/js";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ApiPost from "../../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../../api/endPoints/usePut";
import ModalUploadImage from "../../../../../../components/Formularios/Modal/ModalUpload";
import { styled } from "@mui/material/styles";
import ConverterBase64 from "../../../../../../components/ConversorBase64";
import CarregarDados from "../CarregarDados";
// import { InterfaceDadosEmpresa } from "../../../../../types/EmpresaInteface";
import { url_CarregarDados_Empresa, url_CarregarDados_ID } from "../auxiliar";
import { dadosUF } from "../../../../../../components/dadosFixos/UF";
import { dadosPais } from "../../../../../../components/dadosFixos/Pais";
import { TextoFormatoCaptalize } from "../../../../../../components/Auxiliar/ConvTextoCaptalize";
import { TextoFormatoLowerCase } from "../../../../../../components/Auxiliar/ConvTextoLower";
import { TextoFormatoUpperCase } from "../../../../../../components/Auxiliar/ConvTextoUpper";
import useSelectOptions from "../../../../../../hooks/useSelectOptions";
import SelectInput from "../../../../../../shared/SelectInputs";
import CarregarDadosEmpresa from "../CarregarEmpresa";
import axios from "axios";
import { getSessionData } from "../../../../../../utils/storageUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "#616161",
  borderRadius: "5px",
  fontWeight: "bold",
  fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
}))

interface FormfilialProps {
  idfilial?: string;
  acao?: string;
}

const Formfilial = ({ idfilial, acao }: FormfilialProps) => {

  const isSuper = getSessionData("isPerfilSuper")

  const navigate = useNavigate();
  const isVinculo = getSessionData("isPerfilVinculo") || "0"

  //#region Controla as guias
  const [guia, setGuia] = useState("1")

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [showUpload, setShowUpload] = useState<boolean>(false);

  //const [id_filial, setId_filial] = useState<any>(idfilial || "");
  const [CNPJ, setCNPJ] = useState<string>("")
  const [status, setStatus] = useState<boolean>(true);
  const [dtcadastro, setDtcadastro] = useState<string>("");
  const [id_filial, setId_filial] = useState<string>(idfilial || "");
  const [tipocontratacao, setTipocontratacao] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [logradouro, setLogradouro] = useState<string>("");
  const [numero, setNumero] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [compl, setCompl] = useState<string>("");
  const [cnum, setCnum] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [cuf, setCuf] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [responsavel, setResponsavel] = useState<string>("");
  const [tipoconselho, setTipoconselho] = useState<string>("");
  const [ufconselho, setUfconselho] = useState<string>("");
  const [codconselho, setCodconselho] = useState<string>("");
  const [unidprofissional, setUnidprofissional] = useState<string>("");
  const [usuario_cadastro, setUsuario_cadastro] = useState<string>("");
  const [fantasia, setFantasia] = useState<string>("");
  const [telfixo, setTelfixo] = useState<string>("");
  const [telcelular, setTelcelular] = useState<string>("");
  const [empresaVinculada, setEmpresaVinculada] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [smtp_Servidor, setSmtp_Servidor] = useState<string>("");
  const [smtp_Porta, setSmtp_Porta] = useState<string>("");
  const [smtp_Email, setSmtp_Email] = useState<string>("");
  const [smtp_Usuario, setSmtp_Usuario] = useState<string>("");
  const [smtp_Senha, setSmtp_Senha] = useState<string>("");
  const [pais, setPais] = useState<string>("")
  const [conteudoEmailPadrao, setconteudoEmailPadrao] = useState("")
  const [depositanteSisclWMS, set_depositanteSisclWMS] = useState(false)

  const [loading, setLoading] = useState<boolean>(false);

  // const handleCepChange = (value: string) => {
  //   const formattedCep = applyCepMask(value);
  //   setCep(formattedCep);
  // };

  //#region ==> Select Options
  // Usando o hook useSelectOptions para obter as opções diretamente
  const [selectedTipoContratacao, setSelectedTipoContratacao] = useState("");


  //#endregion

  //dados banco de dados
  const [servidor_db, set_servidor_db] = useState("")
  const [banco, set_banco] = useState("")
  const [usuario, set_usuario] = useState("")
  const [senha_db, set_senha_db] = useState("")
  const [porta_db, set_porta_db] = useState("")
  const [ativo, set_ativo] = useState(false)

  const [permissao, set_permissao] = useState(true) //true = invisivel

  function set_ver_banco() {
    if (isSuper == "1") {
      set_permissao(false)//false = visivel
    }
  }

  //preencher com base no CNPJ consultando api de terceiros
  async function carregarDadosCNPJ(event: any) {

    setCNPJ(event)
    if (event.length == 14) {
      const response = await axios.get(`https://publica.cnpj.ws/cnpj/${event}`)

      setCNPJ(response.data.estabelecimento.cnpj)
      setFantasia(response.data.estabelecimento.nome_fantasia)
      setLogradouro(response.data.estabelecimento.logradouro)
      setNumero(response.data.estabelecimento.numero)
      setCompl(response.data.estabelecimento.complemento)
      setBairro(response.data.estabelecimento.bairro)
      setCidade(response.data.estabelecimento.cidade.nome)
      setCnum(response.data.estabelecimento.cidade.ibge_id)
      setCep(response.data.estabelecimento.cep)
      setCuf(response.data.estabelecimento.estado.sigla)
      setPais(response.data.estabelecimento.pais.nome)
    }
  }

  const carregar = (url: string, idfilial: any) => {
    setLoading(true);
    CarregarDados(url, idfilial)
      .then((retorno) => {

        //setId_filial(retorno[0].id_filial || "");
        setStatus(retorno[0].status === 1 ? true : false);
        setCNPJ(retorno[0].cnpj || "")
        setDtcadastro(retorno[0].dtcadastro || "");
        setId_filial(idfilial)

        // setTipocontratacao(retorno[0].tipocontratacao || '');
        setSelectedTipoContratacao(retorno[0].tipocontratacao || "");
        setEmail(retorno[0].email || "");
        setLogradouro(retorno[0].logradouro || "");
        setNumero(retorno[0].numero || "");
        setBairro(retorno[0].bairro || "");
        setCompl(retorno[0].complemento || "");
        setCnum(retorno[0].cmun || "");
        setCidade(retorno[0].municipio || "")
        setCuf(retorno[0].uf || "");
        setCep(retorno[0].cep || "");
        setResponsavel(retorno[0].contato || "");
        setTipoconselho(retorno[0].tipoconselho || "");
        setUfconselho(retorno[0].ufconselho || "");
        setCodconselho(retorno[0].codconselho || "");
        setUnidprofissional(retorno[0].unidprofissional || "");
        setUsuario_cadastro(retorno[0].usuario_cadastro || "");
        setFantasia(retorno[0].fantasia || "");
        setTelfixo(retorno[0].telfixo || "");
        setTelcelular(retorno[0].telcelular || "");
        setEmpresaVinculada(retorno[0].id_empresa || "");
        setAvatar(retorno[0].avatar || "");
        setPais(retorno[0].pais || "")
        setSmtp_Servidor(retorno[0].servidor_smtp || "");
        setSmtp_Porta(retorno[0].porta_smtp || "");
        setSmtp_Email(retorno[0].email_smtp || "");
        setSmtp_Usuario(retorno[0].usuario_smtp || "");
        setSmtp_Senha(retorno[0].senha_smtp || "");
        setconteudoEmailPadrao(retorno[0].email_conteudo_padrao || "")
        setLoading(false);
        set_depositanteSisclWMS(retorno[0].depositante_siscl_wms)

        set_servidor_db(retorno[0].servidor_db || "")
        set_banco(retorno[0].banco || "")
        set_usuario(retorno[0].usuario_db || "")
        set_senha_db(retorno[0].senha_db || "")
        set_porta_db(retorno[0].porta_db || "")
        set_ativo(retorno[0].ativo || "")
      })
      .catch((erro) => {
        setLoading(false);
        toast.error("Falha ao carregar dados da filial. Motivo: ", erro);
      });
  };
  useEffect(() => {
    if (idfilial !== "novo") {

      carregar(url_CarregarDados_ID, id_filial);

    }
  }, []);

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS
  const atualizarDados = async (
    status: string,
    dtcadastro: string,
    id_filial: string,
    tipocontratacao: string,
    email: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    responsavel: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    unidprofissional: string,
    usuario_cadastro: string,
    fantasia: string,
    telfixo: string,
    telcelular: string,
    id_empresa: string,
    pais: string,
    smtp_servidor: string,
    smtp_porta: string,
    smtp_email: string,
    smtp_usuario: string,
    smtp_senha: string,
    avatar: string,
    conteudoEmailPadrao: string,
    depositanteSisclWMS: boolean,
    cnpj: string,

    //info banco
    servidor: string,
    banco: string,
    usuario: string,
    senha: string,
    porta: string,
    ativo: boolean

  ) => {
    const dados = {
      status,
      dtcadastro,
      id_filial,
      tipocontratacao: selectedTipoContratacao,
      email,
      logradouro,
      numero,
      bairro,
      compl,
      cnum,
      cidade,
      cuf,
      cep,
      responsavel,
      tipoconselho,
      ufconselho,
      codconselho,
      unidprofissional,
      usuario_cadastro,
      fantasia,
      telfixo,
      telcelular,
      id_empresa,
      pais,
      smtp_servidor,
      smtp_porta,
      smtp_email,
      smtp_usuario,
      smtp_senha,
      avatar,
      conteudoEmailPadrao,
      depositanteSisclWMS,
      cnpj,
      //info banco
      servidor_db,
      banco,
      usuario,
      senha_db,
      porta_db,
      ativo

    };

    ApiPut(`/filial/atualizar/${idfilial}`, dados)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(`Erro ao atualizar. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS
  const gravarDados = (
    //id_filial: string,
    status: string,
    cnpj: string,
    dtcadastro: string,
    id_filial: string,
    tipocontratacao: string,
    email: string,
    logradouro: string,
    numero: string,
    bairro: string,
    compl: string,
    cnum: string,
    cidade: string,
    cuf: string,
    cep: string,
    responsavel: string,
    tipoconselho: string,
    ufconselho: string,
    codconselho: string,
    unidprofissional: string,
    usuario_cadastro: string,
    fantasia: string,
    telfixo: string,
    telcelular: string,
    id_empresa: string,
    pais: string,
    smtp_servidor: string,
    smtp_porta: string,
    smtp_email: string,
    smtp_usuario: string,
    smtp_senha: string,
    avatar: string,
    conteudoEmailPadrao: string,
    depositanteSisclWMS: boolean,

    //dados banco
    servidor: string,
    banco: string,
    usuario: string,
    senha: string,
    porta: string,
    ativo: boolean
  ) => {
    const dados = {
      //id_filial,
      status,
      cnpj,
      dtcadastro,
      id_filial,
      tipocontratacao: selectedTipoContratacao,
      email,
      logradouro,
      numero,
      bairro,
      compl,
      cnum,
      cidade,
      cuf,
      cep,
      responsavel,
      tipoconselho,
      ufconselho,
      codconselho,
      unidprofissional,
      usuario_cadastro: getSessionData("DadosNomefilial"),
      fantasia,
      telfixo,
      telcelular,
      id_empresa,
      pais,
      smtp_servidor,
      smtp_porta,
      smtp_email,
      smtp_usuario,
      smtp_senha,
      avatar,
      conteudoEmailPadrao,
      depositanteSisclWMS,

      //config banco
      servidor_db,
      banco,
      usuario,
      senha_db,
      porta_db,
      ativo
    };
    ApiPost("/filial/criar", dados)
      .then((retorno) => {

        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {

        toast.error(`Erro ao gravar dados. Motivo: ${erro.response.data.message}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (idfilial === "novo") {
      gravarDados(
        //id_filial,
        status ? "1" : "0",
        CNPJ,
        dtcadastro,
        id_filial || "2",
        tipocontratacao,
        email,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        responsavel,
        tipoconselho,
        ufconselho,
        codconselho,
        unidprofissional,
        usuario_cadastro,
        fantasia,
        telfixo,
        telcelular,
        empresaVinculada,
        pais,
        smtp_Servidor,
        smtp_Porta,
        smtp_Email,
        smtp_Usuario,
        smtp_Senha,
        avatar,
        conteudoEmailPadrao,
        depositanteSisclWMS,

        //config banco
        servidor_db,
        banco,
        usuario,
        senha_db,
        porta_db,
        ativo
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(
        //id_filial,
        status ? "1" : "0",
        dtcadastro,
        id_filial,
        tipocontratacao,
        email,
        logradouro,
        numero,
        bairro,
        compl,
        cnum,
        cidade,
        cuf,
        cep,
        responsavel,
        tipoconselho,
        ufconselho,
        codconselho,
        unidprofissional,
        usuario_cadastro,
        fantasia,
        telfixo,
        telcelular,
        empresaVinculada,
        pais,
        smtp_Servidor,
        smtp_Porta,
        smtp_Email,
        smtp_Usuario,
        smtp_Senha,
        avatar,
        conteudoEmailPadrao,
        depositanteSisclWMS,
        CNPJ,
        //config banco
        servidor_db,
        banco,
        usuario,
        senha_db,
        porta_db,
        ativo
      );
      toast.info("Atualizando dados do registro...");
    }
  };

  //#region Listar Empresa Contratante
  const [empresa, setEmpresa] = useState<any[]>([]);
  const listarEmpresa = async () => {
    CarregarDadosEmpresa(url_CarregarDados_Empresa)
      .then((retorno) => {
        setEmpresa(retorno);
      })
      .catch((erro) => {
        const msg = erro.response?.data.message;
        toast.error(`Erro ao listar dados empresa. Motivo: ${msg}`);
      });
  };
  useEffect(() => {
    set_ver_banco()
    listarEmpresa();
  }, []);

  //#endregion

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setShowUpload(true);
      ConverterBase64(file)
        .then((retorno) => {
          setShowUpload(false);
          setAvatar(retorno);
        })
        .catch((erro) => {
          setShowUpload(false);
          toast.error(erro);
        });
    }
  };

  const handleRemoveAvatar = () => {
    setAvatar("");
  };

  const buscarCep = async (cep: string) => {
    const _cep = cep.replace("-", "");
    if (_cep.length < 8) {
      toast.error("CEP inválido!");
    } else if (_cep.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${_cep}/json/`)
        .then((retorno) => {
          if (retorno.data?.erro) {
            toast.error("CEP inválido ou Não Localizado!");
          } else {
            const dadosCep = retorno.data;
            console.log(dadosCep)

            setLogradouro(dadosCep.logradouro);
            setCompl(dadosCep.complemento);
            setBairro(dadosCep.bairro);
            setCidade(dadosCep.localidade);
            setCuf(dadosCep.uf);
            setCnum(dadosCep.ibge);
          }
        })
        .catch((erro) => {
          console.log("erro: ", erro);
          const msg =
            erro.response?.data.message === undefined
              ? erro.message
              : erro.response.data.message;
          toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
        });
    }
  };
  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Dados Básicos" value="1" />
                    <Tab label="Adicionais" value="2" />
                    {isSuper == "1" && (
                      <Tab label="Configurações do banco" value="3" hidden={permissao} />
                    )}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="ID Filial"
                        name="id_filial"
                        type="text"
                        placeholder="ID Filial"
                        readonly
                        value={id_filial}
                        onChange={setId_filial}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="CNPJ"
                        name="CNPJ"
                        type="text"
                        placeholder="Nome CNPJ"
                        required
                        readonly={
                          acao === "Visualizar" || acao === "Editar"
                            ? true
                            : false
                        }
                        onBlur={function (e: any) {
                          const cnpj_formatado = CNPJ.replace(/[.\-/]/g, '');
                          carregarDadosCNPJ(cnpj_formatado)
                        }}
                        value={CNPJ || ""}
                        onChange={function (e: any) {
                          setCNPJ(e)
                        }}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Fantasia"
                        name="fantasia"
                        type="text"
                        placeholder="Nome Fantasia"
                        required
                        readonly={
                          acao === "Visualizar" || acao === "Editar"
                            ? true
                            : false
                        }
                        value={fantasia || ""}
                        onChange={setFantasia}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={email || ""}
                        onChange={setEmail}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={4}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Depositante Siscl Wms
                      </label>
                      <Switch
                        checked={depositanteSisclWMS}
                        onChange={() => set_depositanteSisclWMS(!depositanteSisclWMS)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Telefone"
                        name="telefone"
                        type="text"
                        placeholder="Telefone Fixo"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telfixo || ""}
                        value={mascaraTelefoneFixo(telfixo) || ""}
                        onChange={setTelfixo}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Celular"
                        name="telcelular"
                        type="text"
                        placeholder="Celular"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        // value={telcelular || ""}
                        value={mascaraTelefoneCelular(telcelular) || ""}
                        onChange={setTelcelular}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Contato"
                        name="responsavel Técnico"
                        type="text"
                        placeholder="Responsável"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={responsavel || ""}
                        onChange={setResponsavel}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    {/* <Col sm={2}>
                      <SelectInput
                        value={selectedTipoContratacao}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          setSelectedTipoContratacao(e.target.value)
                        }
                        options={tipoContratacaoOptions}
                        placeholder="Tipo Contratação"
                      />
                      <p>Opção selecionada: {selectedTipoSala}</p> 
                    </Col>*/}
                  </Row>
                  <Row>
                    {/* <Col sm={2}>
                      <InputComMascara
                        label="CEP"
                        name="cep"
                        type="text"
                        mask="cep"
                        placeholder="CEP"
                        readonly={acao === "Visualizar" ? true : false}
                        // value={applyCepMask(cep)}
                        value={cep}
                        // onChange={(value) => setCep(value)}
                        onChange={handleCepChange}
                        onBlur={() => buscarCep(cep)}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col> */}
                    <Col sm={2}>
                      <InputSemBorda
                        label="CEP"
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        readonly={acao === "Visualizar" ? true : false}
                        // value={cep || ""}
                        value={cep || ""}
                        onBlur={function (e: any) {
                          const cep_formatado = cep.replace(/[.\-/]/g, '');
                          setCep(cep_formatado)
                          buscarCep(cep_formatado)
                        }}
                        onChange={setCep}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Logradouro"
                        name="endereco"
                        type="text"
                        placeholder="Logradouro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={logradouro || ""}
                        onChange={setLogradouro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Complemento"
                        name="compl"
                        type="text"
                        placeholder="Complemento"
                        readonly={acao === "Visualizar" ? true : false}
                        value={compl || ""}
                        onChange={setCompl}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Número"
                        name="numero"
                        type="text"
                        placeholder="Número"
                        readonly={acao === "Visualizar" ? true : false}
                        value={numero || ""}
                        onChange={setNumero}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Bairro"
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        readonly={acao === "Visualizar" ? true : false}
                        value={bairro || ""}
                        onChange={setBairro}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cidade"
                        name="cidade"
                        type="text"
                        placeholder="Cidade"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cidade || ""}
                        onChange={setCidade}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Cód. Municipio"
                        name="cnum"
                        type="text"
                        placeholder="Cod. Municipio"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cnum || ""}
                        onChange={setCnum}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                    {/*<Col sm={3}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          UF
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={cuf}
                          onChange={(e: SelectChangeEvent) =>
                            setCuf(e.target.value)
                          }
                          label="UF"
                          required
                          disabled={acao === "Visualizar" ? true : false}
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {dadosUF.map((item: any) => (
                            <MenuItem value={item.uf}>{item.nome}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    */}

                    <Col sm={3}>
                      <InputSemBorda
                        label="UF"
                        name="cnum"
                        type="text"
                        placeholder="UF"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={cuf || ""}
                        onChange={setCuf}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                      />
                    </Col>

                  </Row>
                  {/* 
                  <Col sm={2}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1.5, width: "100%" }}
                    >
                      <InputLabel>País</InputLabel>
                      <Select
                        value={pais}
                        onChange={(e: SelectChangeEvent) =>
                          setPais(e.target.value)
                        }
                        label="País"
                        required
                        disabled={acao === "Visualizar" ? true : false}
                      >
                        <MenuItem value="">
                          <em>Selecione...</em>
                        </MenuItem>
                        {dadosPais.map((item: any) => (
                          <MenuItem value={item.codpais}>
                            {item.pais} ({item.codpais})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>*/}
                  <Col sm={2}>
                    <InputSemBorda
                      label="País"
                      name="cnum"
                      type="text"
                      placeholder="País"
                      required
                      readonly={acao === "Visualizar" ? true : false}
                      value={pais || ""}
                      onChange={setPais}
                      classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                    />
                  </Col>

                  <Row>
                    <Col sm={12}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1.5, width: "100%" }}
                      >
                        <InputLabel>Empresa</InputLabel>
                        <Select
                          value={empresaVinculada}
                          onChange={(e: SelectChangeEvent) =>
                            setEmpresaVinculada(e.target.value)
                          }
                          label="UF"
                          required
                          disabled={
                            acao === "Visualizar" || acao === "Editar"
                              ? true
                              : false
                          }
                        >
                          <MenuItem value="">
                            <em>Selecione...</em>
                          </MenuItem>
                          {empresa.map((item: any) => (
                            <MenuItem value={item.id}>{item.fantasia}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>

                </TabPanel>
                <TabPanel value="2">
                  <Typography borderBottom={1}>Dados Servidor de Email</Typography>
                  <Row>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Servidor SMTP"
                        name="smtp"
                        type="text"
                        placeholder="Servidor SMTP"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Servidor}
                        onChange={setSmtp_Servidor}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Porta"
                        name="porta"
                        type="text"
                        placeholder="Porta"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Porta}
                        onChange={setSmtp_Porta}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="E-mail"
                        name="email"
                        type="email"
                        placeholder="Email"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Email}
                        onChange={setSmtp_Email}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Usuário/E-mail (Autenticação)"
                        name="usuarioemail"
                        type="text"
                        placeholder="Usuário/E-mail (Autenticação)"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Usuario}
                        onChange={setSmtp_Usuario}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={6}>
                      <InputSemBorda
                        label="Senha (Autenticação)"
                        name="senha"
                        type="password"
                        placeholder="Senha (Autenticação)"
                        readonly={acao === "Visualizar" ? true : false}
                        value={smtp_Senha}
                        onChange={setSmtp_Senha}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Mensagem no E-mail para redefinição de senha</Form.Label>
                        <Form.Control as="textarea" value={conteudoEmailPadrao} onChange={function (e) {
                          setconteudoEmailPadrao(e.target.value)
                        }} rows={5} />
                      </Form.Group>
                    </Form>
                  </Row>

                  <Typography borderBottom={1} marginBottom={2}>Logo</Typography>
                  <Row className="mb-2">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {avatar ? (
                        <img
                          alt=""
                          style={{
                            width: "70%",
                            height: "250px",
                            objectFit: "contain",
                            backgroundImage: `url(data:image/png;base64,${avatar})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                      ) : (
                        <Avatar
                          src={""}
                          alt="Avatar"
                          sx={{ width: 240, height: 240 }}
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        id="avatar-input"
                        onChange={handleAvatarChange}
                        style={{ display: "none" }}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                      <label htmlFor="avatar-input">
                        <IconButton color="primary" component="span">
                          <PhotoCameraIcon />
                        </IconButton>
                      </label>
                      {avatar && acao !== "Visualizar" && (
                        <Button variant="outlined" onClick={handleRemoveAvatar}>
                          Remover Logo
                        </Button>
                      )}
                    </Stack>
                  </Row>
                </TabPanel>
                <TabPanel value="3">
                  <Row className="mb-2">
                    <Col sm={3}>
                      <InputSemBorda
                        label="Servidor"
                        name="server"
                        type="text"
                        placeholder="Servidor do banco"
                        readonly={acao === "Visualizar" ? true : false}
                        value={servidor_db}
                        onChange={set_servidor_db}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Banco"
                        name="banco"
                        type="text"
                        placeholder="Nome do banco de dados"
                        readonly={acao === "Visualizar" ? true : false}
                        value={banco}
                        onChange={set_banco}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Usuario"
                        name="Usuario"
                        type="text"
                        placeholder="Nome do Usuario do banco"
                        readonly={acao === "Visualizar" ? true : false}
                        value={usuario}
                        onChange={set_usuario}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={3}>
                      <InputSemBorda
                        label="Senha"
                        name="Senha"
                        type="password"
                        placeholder="Senha do banco"
                        readonly={acao === "Visualizar" ? true : false}
                        value={senha_db}
                        onChange={set_senha_db}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Porta"
                        name="Porta"
                        type="text"
                        placeholder="Porta do banco"
                        readonly={acao === "Visualizar" ? true : false}
                        value={porta_db}
                        onChange={set_porta_db}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                      />
                    </Col>
                    <Col sm={3}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Consultas via API
                      </label>
                      <Switch
                        checked={ativo}
                        onChange={() => set_ativo(!ativo)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>

                  </Row>
                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <ButtonCustom
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <ButtonCustom
                invisivel={false}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
                disabled={acao === "Visualizar" ? true : false}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card >
      <ModalUploadImage
        show={showUpload}
        label="Realizando Upload da Imagem ..."
      />
      <ModalLoading show={loading} label="Carregado dados da filial ..." />
    </>
  );
};
export default Formfilial;
