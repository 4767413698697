import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import SelectInput from "../../../../shared/SelectInputs";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { resolvePath, useNavigate } from "react-router-dom";
import axios from "axios";
import { Api } from "../../../../api/api";
import ApiPost from "../../../../api/endPoints/usePost";
import { getSessionData } from "../../../../utils/storageUtils";
import { toast } from "react-toastify";
import ApiGet from "../../../../api/endPoints/useGet";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";

function FormularioEquipeOperacional(info: any) {

    async function insert(e: any) {
        e.preventDefault()
        const token = getSessionData("MultfilialWebToken") || "";
        const dados = {
            id_filial: getSessionData("DadosIdfilial"),
            equipe: nomeEquipe,
            id_codigo_operacao: tipoOperacao,
            usuario: getSessionData("NomeUsuario")
        }
        console.log()
        const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/equipe?authorization=${token}`, dados, {
            headers: {
                authorization: token
            }
        })
        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message)
        }
        else {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
        }
    }

    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    async function update(e: any) {
        e.preventDefault()
        const token = getSessionData("MultfilialWebToken") || "";
        const dados = {
            id_filial: getSessionData("DadosIdfilial"),
            equipe: nomeEquipe,
            id_codigo_operacao: tipoOperacao,
            id_equipe: info.idequipe

        }
        const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/equipe/atualizar?authorization=${token}`, dados, {
            headers: {
                authorization: token
            }
        })
        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message)
        }
        else {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
        }


    }

    const navigate = useNavigate()

    //guia para paginação da TabPanel
    const [guia, set_guia] = useState("1")
    function setGuia(event: React.SyntheticEvent, guia: string) {
        set_guia(guia)
    }

    //valores para insert -- inicio
    const [nomeEquipe, set_nomeEquipe] = useState("")
    const [tipoOperacao, set_tipoOperacao] = useState("")

    //valores para insert 

    const [listaTiposOperacao, set_listaTiposOperacao] = useState([])
    async function carregarListaOperacoes() {
        set_mostrarModalCarregando(true)
        const token = getSessionData("MultfilialWebToken") || "";
        const headers = {
            Authorization: token,
        };
        const dados = {
            url: "/codigosistema/tabela/:tabela",
            parametros: {
                tabela: "OPERACAOEQUIPE"
            },
            headers
        }
        ApiGet(dados).then(function (resposta) {
            if (resposta.codigo == 200) {
                set_listaTiposOperacao(resposta.tabela)
            }
            else {
                toast.error("Erro ao carregar tipos de operação: " + resposta.data.erro)
            }
        }).catch(function (erro) {
            toast.error("Erro ao carregar tipos de operação: " + erro)
        })
        set_mostrarModalCarregando(false)
    }

    async function carregarEquipe() {
        const token = getSessionData("MultfilialWebToken") || "";
        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/lista/byId/equipe?id_equipe=${info.idequipe}&id_filial=${getSessionData("DadosIdfilial")}`, {
            headers: {
                Authorization: token
            }
        })
        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message)
        }
        else {
            set_nomeEquipe(resposta.data.equipe[0].equipe)
            set_tipoOperacao(resposta.data.equipe[0].descricao)
        }
    }


    useEffect(function () {
        if (info.idequipe != "novo") {
            carregarEquipe()
        }
        carregarListaOperacoes()
    }, [])
    return (
        <>
            <Card>
                <Form onSubmit={info.idequipe == "novo" ? insert : update}>
                    <Card.Body>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={setGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab label="Dados Básicos" value="1" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <Row>
                                        <Col sm={2}>
                                            <InputSemBorda
                                                label="ID Equipe"
                                                name="id_filial"
                                                type="text"
                                                placeholder="ID Filial"
                                                readonly
                                                required
                                                value={info.acao == "novo" ? "novo" : info.idequipe}
                                                onChange={set_guia}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <InputSemBorda
                                                label="Nome Equipe"
                                                name="NomeEquipe"
                                                type="text"
                                                placeholder="Nome Equipe"
                                                readonly={info.acao == "visualizar" ? true : false}
                                                value={nomeEquipe}
                                                onChange={set_nomeEquipe}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Tipo Operação
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={tipoOperacao}
                                                    onChange={function (e) {
                                                        set_tipoOperacao(e.target.value)
                                                    }}
                                                    label="Perfil"
                                                    required
                                                    readOnly={info.acao == "visualizar" ? true : false}
                                                >
                                                    <MenuItem value={tipoOperacao} hidden={true}>
                                                        <em>{tipoOperacao}</em>
                                                    </MenuItem>
                                                    {listaTiposOperacao.map((operacao: any) => (
                                                        <MenuItem value={operacao.id} data-master={operacao.descricao}>
                                                            {operacao.descricao}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </TabPanel>

                            </TabContext>
                        </Box>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                                disabled={info.acao == "visualizar" ? true : false}
                            />
                        </div>
                    </Card.Footer>
                </Form>
            </Card>
            <ModalLoading show={mostrarModalCarregando}/>
        </>
    )
}

export default FormularioEquipeOperacional