import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { useEffect, useState } from "react";
import Tabela from "../../../components/Formularios/Table";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { useNavigate } from "react-router-dom";
import { SpeedDial, SpeedDialIcon } from "@mui/material";
import axios from "axios";
import { getSessionData } from "../../../utils/storageUtils";
import { toast } from "react-toastify";

import ModalLoading from "../../../components/Formularios/Modal/ModalLoading"
function GridFilial() {

    const navigate = useNavigate()

    const colunas: ITabela[] = [
        { titulo: "", acesso: "avatar" },
        { titulo: "Id", acesso: "ID_filial" },
        { titulo: "Filial", acesso: "nome_filial" },
        { titulo: "Qtde. Usuários Total", acesso: "total_usuarios" },
        { titulo: "Qtde. Usuários Ativos", acesso: "usuarios_ativos" },
    ];

    const token = getSessionData("MultfilialWebToken")
    const [dados, set_dados] = useState([])
    function buscarDados() {
        set_mostrarModalCarregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/grid/filial?id_usuario=${getSessionData("DadosUsuarioID")}&id_perfil=${getSessionData("id_perfil")}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {

            set_mostrarModalCarregando(false)
            set_dados(resposta.data)
        }).catch(function (erro) {

            set_mostrarModalCarregando(false)
            toast.error("Erro ao carregar filais: " + erro)
        })
    }


    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)
    useEffect(function () {
        buscarDados()
    }, [])

    //MÉTODOS TESTE PARA RODAR APLICACAO 
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_visualizar, set_permissao_visualizar] = useState(false)

    const visualiarFilial = (dados: any) => {
        navigate(`/usuarios/filial/${dados.ID_filial}`)
    };

    const editarUsuario = () => { };
    const excluirUsuario = () => { };

    return (
        <>
            <ContainerTitulo titulo="Listagem de Filiais" />

            <Row>
                <Col sm={12}>
                    <Card>
                        <Card.Body>

                            <Row>
                                {<Tabela
                                    coluna={colunas}
                                    dados={dados}
                                    itemsPerPage={10}
                                    onVisuClick={visualiarFilial}
                                    onEditClick={editarUsuario}
                                    onDeleteClick={excluirUsuario}
                                    id="codigo"
                                    permissao_editar={permissao_editar}
                                    permissao_visualizar={permissao_visualizar}
                                    permissao_excluir={permissao_excluir}
                                />}
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <ModalLoading show={mostrarModalCarregando} />
        </>
    )

}

export default GridFilial