import {
  Card,
  Col,
  Form,
  Modal,
  Row,
  Button as Button_Boostrap,
} from "react-bootstrap";
import {
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tab,
  Avatar,
  Paper,
  Typography,
} from "@mui/material";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "../../../components/Formularios/Buttons/Button";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import SelectInput from "../../../shared/SelectInputs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import ptbrLocale from "@fullcalendar/core/locales/pt-br";
import EventContent from "./Components/ConfiguracaoCalendario/DadosAExibir";
import moment from "moment";
import "moment-timezone";
import Autocomplete, {
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
// Defina o fuso horário globalmente
import { getSessionData } from "../../../utils/storageUtils";

import { toast } from "react-toastify";
import ApiPost from "../../../api/endPoints/usePost";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../api/endPoints/useGet";
import { mdiCheckCircle, mdiKeyboardReturn, mdiTrashCan } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import "./Components/style/style.css";
import ApiPut from "../../../api/endPoints/usePut";
import Icon from "@mdi/react";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../api/endPoints/useDelete";
import { TextoFormatoCaptalize } from "../../../components/Auxiliar/ConvTextoCaptalize";
import { Switch } from "@mui/material";
import axios from "axios"
moment.tz.setDefault("America/Sao_Paulo")

export function CalendarioConsultas() {

  const id_filial = getSessionData("DadosIdfilial")
  const id_usuario = getSessionData("DadosUsuarioID")
  const id_empresa = getSessionData("isPerfilVinculo")
  const token = getSessionData("MultfilialWebToken")


  const navigate = useNavigate()
  const dadosDadosEquipe = getSessionData("DadosEquipe") || ""
  const dadosDadosEquipeJson = JSON.parse(dadosDadosEquipe)
  //CONFIGURAÇÃO DA Equipe DE ACORDO COM A SALA SELECIONADA
  const configuracaoEquipe = {
    equipe: dadosDadosEquipeJson.equipe,
    dt_cadastro: dadosDadosEquipeJson.dt_cadastro,
    id_codigo_operacao: dadosDadosEquipeJson.id_codigo_operacao,
    id_agenda_equipe: dadosDadosEquipeJson.id_agenda_equipe,
    id_filial: dadosDadosEquipeJson.id_filial,
    id_equipe: dadosDadosEquipeJson.id_equipe,
    operacao: dadosDadosEquipeJson.operacao,
    inicio: dadosDadosEquipeJson.inicio,
    fim: dadosDadosEquipeJson.fim,
    cadastro: dadosDadosEquipeJson.cadastro,
    usuario_cadastro: dadosDadosEquipeJson.usuario_cadastro,
    ativo: dadosDadosEquipeJson.ativo,
    hora_inicio: dadosDadosEquipeJson.hora_inicio,
    hora_fim: dadosDadosEquipeJson.hora_fim,
    duracao_janela: "00:10:00"
  }

  const [abrirModal, setAbrirModal] = useState(false)
  const [abrirModalEditar, setAbrirModalEditar] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [loading, setLoading] = useState(false)

  interface Event {
    id: number;
    title: string;
    start: string;
    end: string;
    // backgroundColor: string;
    // borderColor: string;
    // textColor: string;
    // subtitle: string;
    // detail: string;
  }
  const [eventoSala, setEventoSala] = useState<Event[]>([])
  const [dataInicio, setDataInicio] = useState<string>("")
  const [horaInicio, setHoraInicio] = useState<string>("")
  const [dataFim, setDataFim] = useState<string>("")
  const [horaFim, setHoraFim] = useState<string>("")
  const [tipoOperacao, setTipoOperacao] = useState("")
  const [perfilOperacao, setPerfilOperacao] = useState("")
  const [tipoVeiculo, setTipoVeiculo] = useState("")
  const [observacao, setObservacao] = useState("")
  const [StatusAgendamento, setStatusAgendamento] = useState("AGENDADO")


  const [ListaCodigoSistema, setListaCodigoSistema] = useState<any>([])
  const [ListaTempoOperacao, setListaTempoOperacao] = useState<any>([])
  const [ListaPerfilOperacao, setListaPerfilOperacao] = useState<any>([])
  const [ListaStatusAgendamento, setListaStatusAgendamento] = useState<any>([])
  const [ListaTipoVeiculo, setListaTipoVeiculo] = useState<any>([])
  function carregarCodigosDoSistema() {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/carregar/codigo/agenda`, {
        headers: {
          Authorization: token
        }
      })
      .then(function (resposta) {
        if (resposta.data.codigo !== 200) {
          toast.error(resposta.data.message)
          setLoading(false)
        } else {
          const codigos = resposta.data.codigosSistema
          setListaCodigoSistema(codigos)

          setListaTempoOperacao([])
          setListaPerfilOperacao([])
          setListaTipoVeiculo([])
          setListaStatusAgendamento([])

          //listando tipos de operação
          codigos
            .filter((codigo: any) => codigo.tabela === 'OPERACAO')
            .forEach((operacao: any) => {

              setListaTempoOperacao((prevLista: any) => [
                ...prevLista,
                { label: operacao.descricao, value: operacao.descricao, id: operacao.id }
              ])
            })

          //listando Perfil de operação
          codigos
            .filter((codigo: any) => codigo.tabela === 'PERFILDECARGA')
            .forEach((perfildecarga: any) => {

              setListaPerfilOperacao((prevLista: any) => [
                ...prevLista,
                { label: perfildecarga.descricao, value: perfildecarga.descricao, id: perfildecarga.id }
              ])
            })

          //listando Tipos Veiculos
          codigos
            .filter((codigo: any) => codigo.tabela === 'TIPOVEICULO')
            .forEach((tipoveiculo: any) => {

              setListaTipoVeiculo((prevLista: any) => [
                ...prevLista,
                { label: tipoveiculo.descricao, value: tipoveiculo.descricao, id: tipoveiculo.id }
              ])
            })


          //listando Status
          codigos
            .filter((codigo: any) => codigo.tabela === 'STATUSOP')
            .forEach((status: any) => {

              setListaStatusAgendamento((prevLista: any) => [
                ...prevLista,
                { label: status.descricao, value: status.descricao, id: status.id }
              ])
            })

          setLoading(false)
        }
      })
      .catch(function (erro) {
        toast.error(erro);
      });
  }

  const [id_operacao, set_id_operacao] = useState<any>("")
  const [btnSalvar, setBtnSalvar] = useState(true)
  function calcularHoraFim() {

    if (tipoOperacao != "" && tipoVeiculo != "" && perfilOperacao != "" && depositante != "") {

      setLoading(true)

      axios.get(`${process.env.REACT_APP_API_BASE_URL}/procurar/tempo?id_filial=${id_filial}&tipo_op=${tipoOperacao}&tipo_veiculo=${tipoVeiculo}&perfil_carga=${perfilOperacao}&id_depositante=${depositante}`, {
        headers: {
          Authorization: token
        }
      }).then(function (resposta) {
        setLoading(false)
        if (resposta.data.codigo == 400) {

          setHoraFim("")
          toast.error(resposta.data.message)
          //bloqueando btn salvar
          setBtnSalvar(true)
        }
        else {

          //set_id_operacao(resposta.data.tempo_op[0].id)

          //setando tempo fim
          const TempoDB = resposta.data.tempo_op[0].tempo_op
          let horaMinutoSegundo = TempoDB.slice(11, 19)

          const horaFimSomada = moment.duration(horaInicio).add(moment.duration(horaMinutoSegundo))
          setHoraFim(moment.utc(horaFimSomada.asMilliseconds()).format("HH:mm:ss"))

          //liberando btn salvar
          setBtnSalvar(false)
        }


      }).catch(function (erro) {
        setLoading(false)
        toast.error(erro)
      })
    }
  }

  function calcularHoraFimEditar(tipoOperacaoEditar: any, tipoVeiculoEditar: any, perfilOperacaoEditar: any, depositanteEditar: any) {

    if (tipoOperacaoEditar != "" && tipoVeiculoEditar != "" && perfilOperacaoEditar != "" && depositanteEditar != "") {

      setLoading(true)

      axios.get(`${process.env.REACT_APP_API_BASE_URL}/procurar/tempo?id_filial=${id_filial}&tipo_op=${tipoOperacaoEditar}&tipo_veiculo=${tipoVeiculoEditar}&perfil_carga=${perfilOperacaoEditar}&id_depositante=${depositanteEditar}`, {
        headers: {
          Authorization: token
        }
      }).then(function (resposta) {
        setLoading(false)
        if (resposta.data.codigo == 400) {

          setHoraFim("")
          toast.error(resposta.data.message)
          //bloqueando btn salvar
          setBtnSalvar(true)
        }
        else {

          //set_id_operacao(resposta.data.tempo_op[0].id)

          //setando tempo fim
          const TempoDB = resposta.data.tempo_op[0].tempo_op
          let horaMinutoSegundo = TempoDB.slice(11, 19)

          const horaFimSomada = moment.duration(horaInicio).add(moment.duration(horaMinutoSegundo))
          setHoraFim(moment.utc(horaFimSomada.asMilliseconds()).format("HH:mm:ss"))

          //liberando btn salvar
          setBtnSalvar(false)
        }


      }).catch(function (erro) {
        setLoading(false)
        toast.error(erro)
      })
    }
  }

  const [selectInfo, setSelectInfo] = useState<any>("")
  const selecionarHorario = (selectInfo: any) => {
    setstatusEditar(false)//indica que não é edição para o form
    const { start, end } = selectInfo
    setSelectInfo(selectInfo)

    const dataInicioOriginal = moment(start)
    const dataInicio = dataInicioOriginal.format("YYYY-MM-DD")

    const hoje = moment()


    const horaInicioOriginal = moment.utc(start)
    const horaInicio = horaInicioOriginal.format("HH:mm:ss")

    const dataFinalOriginal = moment(end)
    const dataFinal = dataFinalOriginal.format("YYYY-MM-DD")

    const horaFinalOriginal = moment.utc(end);
    const horaFinal = horaFinalOriginal.format("HH:mm:ss")

    if (incluirAgendamentoDeEquipe == true) {
      toast.error("Perfil sem permissão para realizar agendamentos.")
    }
    else {
      if (moment(dataInicio).isAfter(hoje, 'day')) {

        setDataInicio(dataInicio)
        setHoraInicio(horaInicio)

        carregarCodigosDoSistema()

        setDataFim(dataFinal)
        setHoraFim("")
        setAbrirModal(true)
      }
      else {
        let dataAtual = new Date()

        //add um dia a data atual
        dataAtual.setDate(dataAtual.getDate() + 1)
        toast.error("Agendamento permito apenas para o dia " + dataAtual.toLocaleDateString("pt-br") + " ou posteriores.")
        carregarAgendamento()
      }
    }

  }

  function formatarDataHora(data: any) {

    const ano = data.getFullYear();
    const mes = data.getMonth() + 1;
    const dia = data.getDate() + 1;
    const horas = data.getHours();
    const minutos = data.getMinutes();
    const segundos = data.getSeconds();

    return `${ano}-${mes < 10 ? "0" + mes : mes}-${dia < 10 ? "0" + dia : dia
      }T${horas < 10 ? "0" + horas : horas}:${minutos < 10 ? "0" + minutos : minutos
      }:${segundos < 10 ? "0" + segundos : segundos}+00:00`;
  }

  const [ListaAgendamentos, setListaAgendamentos] = useState<Event[]>([])
  function carregarAgendamento() {

    setLoading(true)

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/by/filial/agendamento?id_filial=${id_filial}&id_usuario=${id_usuario}&id_equipe=${configuracaoEquipe.id_equipe}`, {
      headers: {
        Authorization: token
      }
    })
      .then(function (resposta) {

        if (resposta.data.codigo != 200) {
          setLoading(false)
          toast.error(resposta.data.message)
        }
        else {

          setLoading(false)
          const dadosAgendamento = resposta.data.agendamentos.map(function (agendamento: any) {

            const dataInicio = moment(agendamento.data_inicio).format("YYYY-MM-DD")

            const dataFim = moment(agendamento.data_fim).format("YYYY-MM-DD")

            const inicioCompleto = new Date(`${dataInicio} ${agendamento.hora_inicio_formatada}`)
            // Obter componentes individuais da data e hora

            // Formatar os componentes conforme necessário
            const dataInicial = formatarDataHora(inicioCompleto)

            const fimCompleto = new Date(`${dataFim} ${agendamento.hora_fim_formatada}`);
            const dataFinal = formatarDataHora(fimCompleto)


            //pegando tipo_op e depositante

            /*const tipo_operacao: any = ListaTempoOperacao.find(function (option: any) {

              return option.id == agendamento.id_tipooperacao
            })*/

            const depositante: any = listaDepositantesByIdFilial.find(function (option: any) {

              return option.id_depositante == agendamento.id_depositante
            })

            return ({
              id: agendamento.id_agendamento,
              title: agendamento.status,
              start: dataInicial,
              end: dataFinal,
              backgroundColor: agendamento.status == "AGENDADO" ? "#1D2934" : agendamento.status == "AGUARDANDO OPERACAO" ? "" : agendamento.status == "EM OPERACAO" ? "#e6b33d" : agendamento.status == "CONCLUIDO" ? "#015340" : agendamento.status == "CANCELADO" ? "#950000" : "",
              borderColor: agendamento.tipoevento == "AGENDADO" ? "#1D2934" : "#630202",
              textColor: "#fff",
              subtitle: agendamento.tipooperacao,
              timeText: depositante && depositante.fantasia ? depositante.fantasia : "******************"
            })
          })
          setListaAgendamentos(dadosAgendamento)
        }
      }).catch(function (erro) {

        toast.error(erro)
      })

  }

  const [tipo_veiculo, set_tipo_veiculo] = useState("")
  const [perfil_operacao, set_perfil_operacao] = useState("")
  function gravarAgendamento(e: any) {
    e.preventDefault()


    if (configuracaoEquipe.hora_fim < horaFim) {
      toast.error("Hora fim do agendamento ultrapassa tempo da agenda.")
    }
    else {
      setLoading(true)
      const dados = {
        id_filial: id_filial,
        id_equipe: configuracaoEquipe.id_equipe,
        operacao: tipoOperacao,
        veiculo: tipoVeiculo,
        perfil_operacao: perfilOperacao,
        data_inicio: dataInicio,
        hora_inicio: horaInicio,
        data_fim: dataFim,
        hora_fim: horaFim,
        usuario_cadastro: getSessionData("NomeUsuario"),
        status: StatusAgendamento,
        id_depositante: depositante,
        id_usuario_cadastro: getSessionData("DadosUsuarioID")
      }

      axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/agendamento`, dados, {
        headers: {
          Authorization: token
        }
      }).then(function (resposta) {

        if (resposta.data.codigo != 200) {
          toast.error(resposta.data.message)
          setLoading(false)
          setAbrirModal(false)
        }
        else {
          toast.success(resposta.data.message)
          setLoading(false)
          setAbrirModal(false)
          carregarAgendamento()

          setTipoVeiculo("")
          setPerfilOperacao("")
          setTipoOperacao("")
          setDataInicio("")
          setHoraInicio("")
          setDataFim("")
          set_depositante("")
          set_id_agendamento_editar("")
          set_id_operacao("")
          set_perfil_operacao("")
          set_tipo_veiculo("")
          //edição
          setdepositanteEditar("")
          settipoVeiculoEditar("")
          setTipoOperacaoEditar("")
          setPerfilOperacaoEditar("")
        }
      })
    }
  }

  //seleciona evento para editar
  const [id_agendamento_editar, set_id_agendamento_editar] = useState("")
  const [statusEditar, setstatusEditar] = useState(false)

  const editarEvento = (info: any) => {
    setstatusEditar(true)//indica para form que é edição
    if (info.event._def.extendedProps.timeText == "******************") {
      toast.error("Esse depositante não está vinculado ao seu usuario.")
    }
    else {
      const idagendamento = info.event["_def"].publicId //seto o id do agendamento para editar
      set_id_agendamento_editar(idagendamento)

      axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/agendamento?id_agendamento=${idagendamento}`, {
        headers: {
          Authorization: token
        }
      }).then(function (resposta) {

        setLoading(false)
        if (resposta.data.codigo != 200) {

          toast.error(resposta.data.message)
        }
        else {

          //tratamento para os inputs ao selecionar agendamentoProgramado
          const veiculo: any = ListaTipoVeiculo.find(function (option: any) {
            return option.value == resposta.data.agendamento[0].tipoveiculo
          })
          set_tipo_veiculo(veiculo.id)
          settipoVeiculoEditar(veiculo.value)

          const perfil_operacao: any = ListaPerfilOperacao.find(function (option: any) {
            return option.value == resposta.data.agendamento[0].perfil_operacao
          })
          set_perfil_operacao(perfil_operacao.id)
          setPerfilOperacaoEditar(perfil_operacao.value)

          const tipo_operacao: any = ListaTempoOperacao.find(function (option: any) {
            return option.value == resposta.data.agendamento[0].tipooperacao
          })
          set_id_operacao(tipo_operacao.id)
          setTipoOperacaoEditar(tipo_operacao.value)

          const dataInicio = resposta.data.agendamento[0].data_inicio.split("T")[0]
          const dataFim = resposta.data.agendamento[0].data_fim.split("T")[0]

          setDataInicio(dataInicio)
          setDataFim(dataFim)

          const dataHoje = new Date()

          const year = dataHoje.getUTCFullYear()
          const month = (dataHoje.getUTCMonth() + 1).toString().padStart(2, '0') // Adiciona 1 ao mês (0-11) e preenche com zero à esquerda se necessário
          const day = dataHoje.getUTCDate().toString().padStart(2, '0')// Preenche com zero à esquerda se necessário

          const dataHojeFormatada = new Date(year + "-" + month + "-" + day + "T00:00:00-03:00")
          const dataInicioFormatada = new Date(dataInicio + "T00:00:00-03:00")

          if (dataInicioFormatada < dataHojeFormatada && EditarAgendamentoDeEquipeTodos == true) {
            toast.error("Usuario sem permissão para ver ou alterar agendamentos passados.")
          }
          else {
            setStatusAgendamento(resposta.data.agendamento[0].status)
            setHoraInicio(resposta.data.agendamento[0].hora_inicio_formatada)
            setHoraFim(resposta.data.agendamento[0].hora_fim_formatada)
            setdepositanteEditar(resposta.data.agendamento[0].id_depositante)
            setAbrirModal(true)
          }
        }
      }).catch(function (erro: any) {
        setLoading(false)
        toast.error(erro)
      })
    }
  }

  function updateAgendamento(e: any) {
    setLoading(true)
    e.preventDefault()


    const dataHoje = new Date()

    const year = dataHoje.getUTCFullYear()
    const month = (dataHoje.getUTCMonth() + 1).toString().padStart(2, '0') // Adiciona 1 ao mês (0-11) e preenche com zero à esquerda se necessário
    const day = dataHoje.getUTCDate().toString().padStart(2, '0')// Preenche com zero à esquerda se necessário

    const dataHojeFormatada = new Date(year + "-" + month + "-" + day + "T00:00:00-03:00")
    const dataInicioFormatada = new Date(dataInicio + "T00:00:00-03:00")

    if (configuracaoEquipe.hora_fim < horaFim) {
      toast.error("Hora fim do agendamento ultrapassa tempo da agenda.")
    }
    else if (dataHojeFormatada > dataInicioFormatada) {
      let dataAtual = new Date()

      //add um dia a data atuals
      dataAtual.setDate(dataAtual.getDate() + 1)
      toast.error("Agendamento permito apenas para o dia " + dataAtual.toLocaleDateString("pt-br") + " ou posteriores.")
      carregarAgendamento()
    }
    else {
      const dados = {
        id_agendamento: id_agendamento_editar,
        operacao: tipoOperacaoEditar,
        id_depositante: depositanteEditar,
        data_inicio: dataInicio,
        hora_inicio: horaInicio,
        data_fim: dataFim,
        hora_fim: horaFim,
        veiculo: tipoVeiculoEditar,
        perfil_operacao: perfilOperacaoEditar,
        usuario_edicao: getSessionData("NomeUsuario"),
        status: StatusAgendamento,
        id_filial: id_filial,
        id_equipe: configuracaoEquipe.id_equipe
      }

      axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/agendamento?EditarAgendamentoDeEquipeUsuario=${EditarAgendamentoDeEquipeUsuario}&EditarAgendamentoDeEquipeTodos=${EditarAgendamentoDeEquipeTodos}&id_usuario=${getSessionData("DadosUsuarioID")}&id_filial=${id_filial}`, dados, {
        headers: {
          Authorization: token
        }
      }).then(function (resposta) {

        setLoading(false)

        if (resposta.data.codigo == 200) {
          setAbrirModal(false)
          toast.success(resposta.data.message)
          carregarAgendamento()
        }
        else {
          toast.error(resposta.data.message)
        }

      }).catch(function (erro) {
        setLoading(false)
        toast.error(erro)
      })
    }
    setLoading(false)
  }


  //CARREGA OS DEPOSITANTES
  const [depositante, set_depositante] = useState("")//guarda o ID do depositante
  const [listaDepositantesByIdFilial, set_listaDepositantesByIdFilial] = useState<any>([])
  async function carregarDepositantesListar() {

    setLoading(true)
    const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/usuario/depositante?id_usuario=${getSessionData("DadosUsuarioID")}&id_filial=${getSessionData("DadosIdfilial")}`)

    if (resposta.data.codigo != 200) {

      toast.error(resposta.data.message || "Erro ao listar depositantes")
    }
    else {

      set_listaDepositantesByIdFilial(resposta.data.depositante)
    }
    setLoading(false)
  }

  async function excluirAgendamento() {

    setLoading(true)

    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/agendamento?id_agendamento=${id_agendamento_editar}&ExcluirAgendamentoDeEquipeUsuario=${ExcluirAgendamentoDeEquipeUsuario}&ExcluirAgendamentoDeEquipeTodos=${ExcluirAgendamentoDeEquipeTodos}&id_usuario=${getSessionData("DadosUsuarioID")}`, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {

      if (resposta.data.codigo == 200) {
        toast.success(resposta.data.message)
        setAbrirModal(false)
      }
      else {
        toast.error(resposta.data.message)
      }
      setLoading(false)
      carregarAgendamento()

      setTipoVeiculo("")
      setPerfilOperacao("")
      setTipoOperacao("")
      setDataInicio("")
      setHoraInicio("")
      setDataFim("")
      set_depositante("")
      set_id_agendamento_editar("")
      set_id_operacao("")
      set_perfil_operacao("")
      set_tipo_veiculo("")
      setStatusAgendamento("AGENDADO")
      //edição
      setdepositanteEditar("")
      settipoVeiculoEditar("")
      setTipoOperacaoEditar("")
      setPerfilOperacaoEditar("")

    }).catch(function (erro) {

      setLoading(false)
      toast.error(erro)
    })
  }

  //CARREGANDO PERMISSÕES
  const id_perfil_usuario = getSessionData("id_perfil")
  const [incluirAgendamentoDeEquipe, setincluirAgendamentoDeEquipe] = useState(true)
  const [EditarAgendamentoDeEquipeUsuario, setEditarAgendamentoDeEquipeUsuario] = useState(true)
  const [EditarAgendamentoDeEquipeTodos, setEditarAgendamentoDeEquipeTodos] = useState(true)
  const [ExcluirAgendamentoDeEquipeUsuario, setExcluirAgendamentoDeEquipeUsuario] = useState(true)
  const [ExcluirAgendamentoDeEquipeTodos, setExcluirAgendamentoDeEquipeTodos] = useState(true)

  function carregarPermissões() {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil_usuario}`, {
      headers: {
        Authorization: token
      }
    })
      .then(function (resposta) {

        if (resposta.data[0].incluirAgendamentoDeEquipe == 1) {
          //dar permissão para criar novos agendamentos
          setincluirAgendamentoDeEquipe(false)
        }
        if (resposta.data[0].EditarAgendamentoDeEquipeUsuario == 1) {
          //dar permissão para editar apenas agendamentos que o usuario criou
          setEditarAgendamentoDeEquipeUsuario(false)
        }
        if (resposta.data[0].EditarAgendamentoDeEquipeTodos == 1) {
          //dar permissão para editar qualquer agendamento
          setEditarAgendamentoDeEquipeTodos(false)
        }
        if (resposta.data[0].ExcluirAgendamentoDeEquipeUsuario == 1) {
          //dar permissão para Excluir apenas agendamentos que o usuario criou
          setExcluirAgendamentoDeEquipeUsuario(false)
        }
        if (resposta.data[0].ExcluirAgendamentoDeEquipeTodos == 1) {
          //dar permissão para Excluir qualquer agendamento
          setExcluirAgendamentoDeEquipeTodos(false)
        }


      }).catch(function (erro) {
        toast.error(erro)
      })
  }

  const [depositantesUsuario, set_depositantesUsuario] = useState([])
  function carregarDepositantesDoUsuario() {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/usuario/depositante?id_usuario=${id_usuario}&id_filial=${id_filial}`)
      .then(function (resposta) {

        if (resposta.data.codigo != 200) {


        }
        else {

          set_depositantesUsuario(resposta.data.depositante)
        }
      }).catch(function (erro) {

        toast.error(erro)
      })
  }

  useEffect(function () {
    carregarDepositantesListar()
    carregarCodigosDoSistema()
    carregarPermissões()
    carregarDepositantesDoUsuario()
  }, [])

  useEffect(function () {
    carregarAgendamento()
  }, [ListaCodigoSistema])

  useEffect(function () {

    calcularHoraFim()
  }, [depositante])

  useEffect(function () {

    calcularHoraFim()
  }, [tipoOperacao])

  useEffect(function () {

    calcularHoraFim()
  }, [perfilOperacao])

  useEffect(function () {

    calcularHoraFim()
  }, [tipoVeiculo])


  //PARA CONTROLAR EDIÇÃO
  const [depositanteEditar, setdepositanteEditar] = useState("")
  const [tipoOperacaoEditar, setTipoOperacaoEditar] = useState("")
  const [perfilOperacaoEditar, setPerfilOperacaoEditar] = useState("")
  const [tipoVeiculoEditar, settipoVeiculoEditar] = useState("")

  /*useEffect(function () {

    calcularHoraFimEditar()
  }, [depositanteEditar])

  useEffect(function () {

    calcularHoraFimEditar()
  }, [tipoOperacaoEditar])

  useEffect(function () {

    calcularHoraFimEditar()
  }, [perfilOperacaoEditar])

  useEffect(function () {

    calcularHoraFimEditar()
  }, [tipoVeiculoEditar])*/

  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo={`Agendamento de Operação - Equipe: ${configuracaoEquipe.equipe}`} />
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <Stack direction="row" spacing={2}>
                      <Button
                        invisivel={false}
                        type="button"
                        className="btn btn-light text-info-emphasis bg-white border-0"
                        descricaoBotao="Voltar"
                        icone={mdiKeyboardReturn}
                        onclick={() =>
                          navigate(-1)
                        }
                      />
                    </Stack>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <Col sm={12}>
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                          listPlugin,
                        ]}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        buttonText={{
                          today: "Hoje",
                          month: "Mês",
                          week: "Semana",
                          day: "Dia",
                          list: "Agenda",
                        }}
                        initialView="timeGridWeek"
                        //editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        events={ListaAgendamentos as any}
                        select={selecionarHorario}
                        timeZone="UTC"
                        locale={ptbrLocale}
                        firstDay={0}
                        slotLabelFormat={{
                          hour: "numeric",
                          minute: "2-digit",
                          omitZeroMinute: false,
                        }}
                        slotDuration={configuracaoEquipe.duracao_janela}
                        slotLabelInterval={configuracaoEquipe.duracao_janela}
                        eventContent={(dadosEvento) => (

                          <EventContent
                            timeText={dadosEvento.event.extendedProps.timeText}
                            eventTitle={dadosEvento.event.title}
                            subtitle={dadosEvento.event.extendedProps.subtitle}
                            detail={dadosEvento.event.extendedProps.detail}
                          />
                        )}
                        contentHeight="auto"
                        slotMinTime={configuracaoEquipe.hora_inicio}
                        slotMaxTime={configuracaoEquipe.hora_fim}
                        allDaySlot={false}
                        eventClick={(dadosEvento) => editarEvento(dadosEvento)}
                      //eventDrop={eventoArrastar}
                      //eventResize={eventoArrastar}
                      // eventMouseEnter={handleEventMouseEnter} // Este é o callback para quando o cursor entra no evento
                      // eventMouseLeave={handleEventMouseLeave}
                      />
                    </Col>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>


      {/*MODAL PARA CRIAR OU EDITAR EVENTO(AGENDAMENTO) */}
      <Modal show={abrirModal}>
        <Modal.Header className="bg-white">
          <Modal.Title>{statusEditar == false ? "Criar Agendamento" : "Editar Agendamento"}</Modal.Title>
          <Button
            invisivel={(statusEditar == false && ExcluirAgendamentoDeEquipeUsuario == false) || (statusEditar == false && ExcluirAgendamentoDeEquipeTodos == false) ? true : false}
            type="button"
            className="btn btn-light text-danger bg-white border-0"
            descricaoBotao=""
            title="Cancelar Agendamento"
            icone={mdiTrashCan}
            onclick={function () {
              showConfirmationDialog("Excluir Agendamento",
                `Tem certeza que deseja continuar com o cancelamento do agendamento ?`,
                "question", 1).then(function (resposta) {
                  if (!resposta.confirmed) {
                    excluirAgendamento()
                  }
                })
            }}
          />
        </Modal.Header>
        <Form onSubmit={statusEditar == false ? gravarAgendamento : updateAgendamento}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={7}>
                <InputSemBorda
                  type="text"
                  label="Equipe"
                  name="Equipe"
                  id="Equipe"
                  value={configuracaoEquipe.equipe}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
              <Row>
                <Col>

                  <FormControl
                    variant="standard"
                    sx={{ m: 1.5, width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Depositante
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={statusEditar == true ? depositanteEditar : depositante}
                      onChange={function (e: any) {
                        set_depositante(e.target.value)


                      }}
                      label="Perfil"
                      required
                    >
                      <MenuItem value={depositante} hidden={true}>
                        <em>{depositante}</em>
                      </MenuItem>
                      {listaDepositantesByIdFilial.map((depositante: any) => (
                        <MenuItem value={depositante.id_depositante}
                          data-master={depositante.fantasia}>
                          {depositante.fantasia}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectInput
                    value={StatusAgendamento}
                    onChange={function (e: ChangeEvent<HTMLSelectElement>) {
                      setStatusAgendamento(e.target.value)
                    }}
                    options={ListaStatusAgendamento}
                    placeholder="Status do agendamento"
                    required
                    disabed={statusEditar == false || EditarAgendamentoDeEquipeTodos == true ? true : false}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <InputSemBorda
                    type="date"
                    label="Data Início"
                    name="dataInicio"
                    id="dataInicio"
                    value={dataInicio}
                    onChange={setDataInicio}
                    required
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                  />
                </Col>
                <Col sm={6}>
                  <InputSemBorda
                    type="time"
                    label="Hora Início"
                    name="horaInicio"
                    id="horaInicio"
                    onBlur={function () {

                      if (statusEditar == true) {

                        calcularHoraFimEditar(tipoOperacaoEditar, tipoVeiculoEditar, perfilOperacaoEditar, depositanteEditar)
                      }
                      else {

                        calcularHoraFim()
                      }


                    }}
                    value={horaInicio}
                    onChange={setHoraInicio}
                    required
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <InputSemBorda
                    type="date"
                    label="Data Fim"
                    name="datafim"
                    id="dataFim"
                    value={dataFim}
                    onChange={setDataFim}
                    required
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                  />
                </Col>
                <Col sm={6}>
                  <InputSemBorda
                    type="time"
                    label="Hora Fim"
                    name="horaFim"
                    id="horaFim"
                    value={horaFim}
                    onChange={setHoraFim}
                    required
                    readonly={EditarAgendamentoDeEquipeTodos == false ? false : true}
                    classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                  />
                </Col>
              </Row>
              <Row>
                <Row>
                  <Col>
                    <SelectInput
                      value={statusEditar == true ? tipoOperacaoEditar : tipoOperacao}
                      onChange={function (e: ChangeEvent<HTMLSelectElement>) {

                        setTipoOperacao(e.target.value)//seta o tempo da operação
                        if (statusEditar == true) {

                          setTipoOperacaoEditar(e.target.value)
                          calcularHoraFimEditar(e.target.value, tipoVeiculoEditar, perfilOperacaoEditar, depositanteEditar)
                        }
                        else {

                          setTipoOperacao(e.target.value)//seta o tempo da operação
                        }

                        const selectedOption: any = ListaTempoOperacao.find(function (option: any) {
                          return option.value == e.target.value
                        })

                        if (selectedOption) {
                          set_id_operacao(selectedOption.id)
                        }
                      }}
                      options={ListaTempoOperacao}
                      placeholder="Tipo Operação"
                      required
                      disabed={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SelectInput
                      value={statusEditar == true ? perfilOperacaoEditar : perfilOperacao}
                      onChange={function (e: ChangeEvent<HTMLSelectElement>) {

                        if (statusEditar == true) {

                          setPerfilOperacaoEditar(e.target.value)
                          calcularHoraFimEditar(tipoOperacaoEditar, tipoVeiculoEditar, e.target.value, depositanteEditar)
                        }
                        else {
                          setPerfilOperacao(e.target.value)
                        }

                        const selectedOption: any = ListaPerfilOperacao.find(function (option: any) {
                          return option.value == e.target.value
                        })

                        if (selectedOption) {

                          set_perfil_operacao(selectedOption.id)
                        }
                      }}
                      options={ListaPerfilOperacao}
                      placeholder="Perfil Operação"
                      required
                      disabed={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SelectInput
                      value={statusEditar == true ? tipoVeiculoEditar : tipoVeiculo}
                      onChange={function (e: any) {

                        if (statusEditar == true) {

                          settipoVeiculoEditar(e.target.value)
                          calcularHoraFimEditar(tipoOperacaoEditar, e.target.value, perfilOperacaoEditar, depositanteEditar)
                        }
                        else {

                          setTipoVeiculo(e.target.value)
                        }


                        const selectedOption: any = ListaTipoVeiculo.find(function (option: any) {
                          return option.value == e.target.value
                        })

                        if (selectedOption) {

                          set_tipo_veiculo(selectedOption.id)
                        }
                      }}
                      options={ListaTipoVeiculo}
                      placeholder="Tipo Veiculo"
                      required
                      disabed={false}
                    />
                  </Col>
                </Row>
              </Row>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={function () {
                  setTipoVeiculo("")
                  setPerfilOperacao("")
                  setTipoOperacao("")
                  setDataInicio("")
                  setHoraInicio("")
                  setDataFim("")
                  set_depositante("")
                  set_id_agendamento_editar("")
                  set_id_operacao("")
                  set_perfil_operacao("")
                  set_tipo_veiculo("")

                  //edição
                  setdepositanteEditar("")
                  settipoVeiculoEditar("")
                  setTipoOperacaoEditar("")
                  setPerfilOperacaoEditar("")

                  setAbrirModal(false)
                }}//fechar modal
              />
              <Button
                invisivel={false}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                disabled={btnSalvar}
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal >

      <ModalLoading show={loading} />
    </>
  );
}
