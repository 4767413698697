import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import SelectInput from "../../../../shared/SelectInputs";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { resolvePath, useNavigate } from "react-router-dom";
import axios from "axios";
import { Api } from "../../../../api/api";
import ApiPost from "../../../../api/endPoints/usePost";
import { getSessionData } from "../../../../utils/storageUtils";
import { toast } from "react-toastify";
import ApiGet from "../../../../api/endPoints/useGet";
import ListarDepositantes from "../../depositante/depositantes";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";

function FormularioOperacoes(info: any) {
    const id_empresa_usuario = getSessionData("isPerfilVinculo")
    const id_filial_usuario = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";
    const id_perfil = getSessionData("id_perfil")

    async function insert(e: any) {
        e.preventDefault()
        const dados = {
            id_filial: getSessionData("DadosIdfilial"),
            id_depositante: depositante,
            tipo_op: tipoOperacao,
            perfil_carga: carga,
            tipo_veiculo: veiculo,
            tempo_op: tempo,
            usuario_cadastro: getSessionData("NomeUsuario")
        }
        const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/tempo/operacional`, dados, {
            headers: {
                Authorization: token
            }
        })
        if (resposta.data.codigo == 200) {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
        }
        else {
            toast.error(resposta.data.message)
        }
    }

    async function update(e: any) {
        e.preventDefault()
        const dados = {
            id_depositante: depositante,
            tipo_op: tipoOperacao,
            perfil_carga: carga,
            tipo_veiculo: veiculo,
            tempo_op: tempo,
        }
        const resposta = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/tempo?id=${info.idequipe}`, dados, {
            headers: {
                Authorization: token
            }
        })
        if (resposta.data.codigo == 200) {
            toast.success(resposta.data.message)
            setTimeout(function () {
                navigate(-1)
            }, 2000)
        }
        else {
            toast.error(resposta.data.message)
        }
    }


    const navigate = useNavigate()

    //guia para paginação da TabPanel
    const [guia, set_guia] = useState("1")
    function setGuia(event: React.SyntheticEvent, guia: string) {
        set_guia(guia)
    }

    //dados para insert inicio
    const [tipoOperacao, set_tipoOperacao] = useState("")
    const [depositante, set_depositante] = useState("")
    const [carga, set_carga] = useState("")
    const [veiculo, set_veiculo] = useState("")
    const [tempo, set_tempo] = useState("")

    //dados para insert fim


    const [listaTiposOperacao, set_listaTiposOperacao] = useState([])
    async function carregarListaOperacoes() {
        const token = getSessionData("MultfilialWebToken") || "";
        const headers = {
            Authorization: token,
        };
        const dados = {
            url: "/codigosistema/tabela/:tabela",
            parametros: {
                tabela: "OPERACAO"
            },
            headers
        }
        ApiGet(dados).then(function (resposta) {
            if (resposta.codigo == 200) {
                set_listaTiposOperacao(resposta.tabela)
            }
            else {
                toast.error(resposta.data.erro)
            }
        }).catch(function (erro) {
            toast.error(erro)
        })
    }

    const [listaTiposCarga, set_listaTiposCarga] = useState([])
    async function carregarListaCarga() {
        const token = getSessionData("MultfilialWebToken") || "";
        const headers = {
            Authorization: token,
        };
        const dados = {
            url: "/codigosistema/tabela/:tabela",
            parametros: {
                tabela: "PERFILDECARGA"
            },
            headers
        }
        ApiGet(dados).then(function (resposta) {
            if (resposta.codigo == 200) {
                set_listaTiposCarga(resposta.tabela)
            }
            else {
                toast.error(resposta.data.erro)
            }
        }).catch(function (erro) {
            toast.error(erro)
        })
    }

    const [listaTiposVeiculo, set_listaTiposVeiculo] = useState([])
    async function carregarListaVeiculo() {
        set_mostrarModalCarregando(true)
        const token = getSessionData("MultfilialWebToken") || "";
        const headers = {
            Authorization: token,
        };
        const dados = {
            url: "/codigosistema/tabela/:tabela",
            parametros: {
                tabela: "TIPOVEICULO"
            },
            headers
        }
        ApiGet(dados).then(function (resposta) {
            if (resposta.codigo == 200) {
                set_listaTiposVeiculo(resposta.tabela)
            }
            else {
                toast.error(resposta.data.erro)
            }
        }).catch(function (erro) {
            toast.error(erro)
        })
        set_mostrarModalCarregando(false)
    }



    const [listaDepositantesByIdFilial, set_listaDepositantesByIdFilial] = useState<any>([])
    async function carregarDepositantesListar() {
        set_mostrarModalCarregando(true)
        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/depositante?id_filial=${getSessionData("DadosIdfilial")}`)

        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message || "Erro ao listar depositantes")
        }
        else {

            set_listaDepositantesByIdFilial(resposta.data.depositante)
        }
        set_mostrarModalCarregando(false)
    }

    async function carregarOperacao() {
        set_mostrarModalCarregando(true)
        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/tempo/byId/byfilial?id_filial=${getSessionData("DadosIdfilial")}&id=${info.idequipe}`, {
            headers: {
                Authorization: token
            }
        })

        if (resposta.data.codigo == 200) {


            /*const [depositante, set_depositante] = useState("")
            const [carga, set_carga] = useState("")
            const [veiculo, set_veiculo] = useState("")
            const [tempo, set_tempo] = useState("")*/

            set_depositante(resposta.data.tempo[0].id_depositante)
            set_tipoOperacao(resposta.data.tempo[0].tipo_op)
            set_carga(resposta.data.tempo[0].perfil_carga)
            set_veiculo(resposta.data.tempo[0].tipo_veiculo)

            // Convertendo a string para um objeto Date
            let dataObj = new Date(resposta.data.tempo[0].tempo_op)

            // Obtendo as horas, minutos e segundos
            let horas = dataObj.getUTCHours()
            let minutos = dataObj.getUTCMinutes()
            let segundos = dataObj.getUTCSeconds()

            // Formatando a string de hora desejada
            let horaFormatada = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`
            set_tempo(horaFormatada)


        }
        else {
            toast.error(resposta.data.erro)
        }
        set_mostrarModalCarregando(false)
    }

    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    useEffect(function () {
        set_mostrarModalCarregando(true)
        carregarListaOperacoes()
        carregarDepositantesListar()
        carregarListaCarga()
        carregarListaVeiculo()
        if (info.idequipe != "novo") {
            carregarOperacao()
        }
        set_mostrarModalCarregando(false)
    }, [])


    return (
        <>
            <Card>
                <Form onSubmit={info.idequipe == "novo" ? insert : update}>
                    <Card.Body>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={setGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab label="Dados Básicos" value="1" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <Row>
                                        <Col sm={2}>
                                            <InputSemBorda
                                                label="ID Equipe"
                                                name="id_filial"
                                                type="text"
                                                placeholder="ID Filial"
                                                readonly
                                                required
                                                value={info.acao == "novo" ? "novo" : info.idequipe}
                                                onChange={set_guia}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Tipo Operação
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={tipoOperacao}
                                                    onChange={function (e) {
                                                        set_tipoOperacao(e.target.value)
                                                    }}
                                                    label="Operação"
                                                    required
                                                    readOnly={info.acao == "visualizar" ? true : false}
                                                >
                                                    <MenuItem value={tipoOperacao} hidden={true}>
                                                        <em>{tipoOperacao}</em>
                                                    </MenuItem>
                                                    {listaTiposOperacao.map((operacao: any) => (
                                                        <MenuItem value={operacao.descricao} data-master={operacao.descricao}>
                                                            {operacao.descricao}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Depositante
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={depositante}
                                                    onChange={function (e) {
                                                        set_depositante(e.target.value)
                                                    }}
                                                    label="Depositante"
                                                    required
                                                    readOnly={info.acao == "visualizar" ? true : false}
                                                >
                                                    <MenuItem value={depositante} hidden={true}>
                                                        <em>{depositante}</em>
                                                    </MenuItem>
                                                    {listaDepositantesByIdFilial.map((depositante: any) => (
                                                        <MenuItem value={depositante.id} data-master={depositante.fantasia}>
                                                            {depositante.fantasia}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Perfil Operação
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={carga}
                                                    onChange={function (e: any) {
                                                        set_carga(e.target.value)
                                                    }}
                                                    label="Perfil Operação"
                                                    required
                                                    readOnly={info.acao == "visualizar" ? true : false}
                                                >
                                                    <MenuItem value={carga} hidden={true}>
                                                        <em>{carga}</em>
                                                    </MenuItem>
                                                    {listaTiposCarga.map((carga: any) => (
                                                        <MenuItem value={carga.descricao} data-master={carga.descricao}>
                                                            {carga.descricao}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Veiculo
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={veiculo}
                                                    onChange={function (e) {
                                                        set_veiculo(e.target.value)
                                                    }}
                                                    label="Veiculo"
                                                    required
                                                    readOnly={info.acao == "visualizar" ? true : false}
                                                >
                                                    <MenuItem value={veiculo} hidden={true}>
                                                        <em>{veiculo}</em>
                                                    </MenuItem>
                                                    {listaTiposVeiculo.map((veiculo: any) => (
                                                        <MenuItem value={veiculo.descricao} data-master={veiculo.descricao}>
                                                            {veiculo.descricao}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col sm={3}>
                                            <InputSemBorda

                                                label="Tempo de Operação (HH:MM)"
                                                name="TempodeOperacao"
                                                type="time"
                                                placeholder="HH:MM"
                                                readonly={info.acao == "visualizar" ? true : false}
                                                required
                                                value={tempo}
                                                onChange={function (e: any) {
                                                    set_tempo(e)
                                                }}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </Col>
                                    </Row>
                                </TabPanel>

                            </TabContext>
                        </Box>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                                disabled={info.acao == "visualizar" ? true : false}
                            />
                        </div>
                    </Card.Footer>
                </Form>
            </Card>
            <ModalLoading show={mostrarModalCarregando}/>
        </>
    )
}

export default FormularioOperacoes