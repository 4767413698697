import { useParams } from "react-router-dom"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import FormularioDepositante from "./components/formulario"


function DepositanteVisualizar() {

    const params = useParams()

    console.log("params:")
    console.log(params)

    return (
        <>
            <div className="container-fluid mt-2">
                <ContainerTitulo titulo="Visualizar Depositante" />
                <FormularioDepositante id_depositante={params.id} acao="visualizar" />
            </div>
        </>
    )
}

export default DepositanteVisualizar