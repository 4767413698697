import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import { MdLocalPrintshop } from "react-icons/md";


import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../components/Formularios/Buttons/Button";
import SelectInput from "../../shared/SelectInputs";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import { useEffect, useState } from "react";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import { resolvePath, useNavigate } from "react-router-dom";
import axios from "axios";
import { Api } from "../../api/api";
import { getSessionData } from "../../utils/storageUtils";
import { toast } from "react-toastify";
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import { ITabela } from "../../components/Formularios/Table/TabelaInterface"
import Tabela from "../../components/Formularios/Table";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ImFileExcel } from "react-icons/im"
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";

function ResumidoValidade() {

    const id_filial_usuario = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";
    const id_perfil = getSessionData("id_perfil")
    const navigate = useNavigate()

    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    const [listaDepositantesByIdFilial, set_listaDepositantesByIdFilial] = useState<any>([])
    const [depositante, set_depositante] = useState("")

    async function carregarDepositantesListar() {
        set_mostrarModalCarregando(true)

        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/usuario/depositante?id_usuario=${getSessionData("DadosUsuarioID")}&id_filial=${getSessionData("DadosIdfilial")}`)

        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message || "Erro ao listar depositantes")
        }
        else {

            set_listaDepositantesByIdFilial(resposta.data.depositante)
            if (listaDepositantesByIdFilial.length == 1) {
                set_depositante(resposta.data.depositante[0].fantasia)
            }
        }
        set_mostrarModalCarregando(false)
    }

    const [listaResumidoValidade, set_listaResumidoValidade] = useState([])
    const [nf, set_nf] = useState("")
    const [serie, set_serie] = useState("")
    async function consultarResumidoValidade(e: any) {

        e.preventDefault()

        set_mostrarModalCarregando(true)
        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/consultar/recebimento_resumo?cnpj_depositante=${depositante}&app=1&nf=${nf}&serie=${serie}`,
            {
                headers: {
                    Authorization: token
                }
            })
        if (resposta.data.codigo == 400) {
            toast.error(resposta.data.message)
        }
        else {

            set_listaResumidoValidade(resposta.data.pedido || [])
        }
        set_mostrarModalCarregando(false)
    }

    const colunas: ITabela[] = [

        { titulo: "CNPJ", acesso: "cnpj" },
        { titulo: "Processo", acesso: "processo" },
        { titulo: "Codigo", acesso: "codigo" },
        { titulo: "Volume", acesso: `volume` },
        { titulo: "PB", acesso: "peso_br" },
        { titulo: "PL", acesso: "peso_liq" },
        { titulo: "Produção", acesso: "producao" },
        { titulo: "Validade", acesso: "validade" },
        { titulo: "Lote", acesso: "lote" }

    ]

    interface Action {
        icon: JSX.Element;
        name: string;
    }

    const actions: Action[] = [
        /* {
             icon: (
                 <AddIcon
                     onClick={() => navigate("/cadastro/tempo/operacional/incluir/novo")}
                 />
             ),
             name: "Incluir",
         },*/

        {
            icon: <MdLocalPrintshop onClick={function () {


                window.print()

            }} />, name: "Imprimir"
        },
        {
            icon: <ImFileExcel onClick={function () {


                gerarExcelEstoque()

            }} />, name: "Gerar Excel com mais Informações"
        }

    ]

    function gerarExcelEstoque() {
        set_mostrarModalCarregando(true)
        if (listaResumidoValidade.length == 0) {
            toast.error("Selecione o depositante e gere o relatório.")
        }
        else {
            // Criar uma planilha a partir do array de objetos 'listaResumidoValidade'
            const ws = XLSX.utils.json_to_sheet(listaResumidoValidade)

            // Obter as propriedades do primeiro objeto para usar como cabeçalho
            const headerProperties = Object.keys(listaResumidoValidade[0])

            // Adicionar uma linha adicional com as propriedades como cabeçalho
            const headerRow = headerProperties.map(prop => prop.toUpperCase()) // Converter para maiúsculas
            XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 0 })

            // Criar um novo livro de Excel
            const wb = XLSX.utils.book_new()

            // Adicionar a planilha ao livro de Excel
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')

            // Configurar opções de escrita, incluindo o tipo de livro e o tipo de saída
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

            // Criar um Blob a partir do buffer de Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            // Salvar o Blob como um arquivo Excel
            FileSaver.saveAs(blob, 'ResumidoValidade.xlsx')
        }
        set_mostrarModalCarregando(false)
    }

    const [imagemFilial, set_imagemFilial] = useState("")
    function setImagemFilial(imagemBase64: any) {
        set_imagemFilial(imagemBase64.replace(/^"(.*)"$/, '$1'))
    }

    useEffect(function () {
        setImagemFilial(getSessionData('imgFilial' || ""))
        carregarDepositantesListar()
    }, [])



    return (
        <>
            <div className="container-fluid mt-2 no-print">
                <ContainerTitulo titulo="Recebido resumido validade" />
                <Card>
                    <Card.Body>
                        <Box>
                            <div className="container no-print">
                                <Row>
                                    <SpeedDial

                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <Form onSubmit={consultarResumidoValidade}>
                                    <div className="row">
                                        <div className="col-4">
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Depositante
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={depositante}
                                                    onChange={function (e: any) {
                                                        set_depositante(e.target.value)
                                                    }}
                                                    label="Perfil"
                                                    required
                                                >
                                                    <MenuItem value={depositante} hidden={true}>
                                                        <em>{depositante}</em>
                                                    </MenuItem>
                                                    {listaDepositantesByIdFilial.map((depositante: any) => (
                                                        <MenuItem value={depositante.cnpj}
                                                            data-master={depositante.fantasia}>
                                                            {depositante.fantasia}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-3">
                                            <InputSemBorda
                                                label="NF"
                                                name="NF"
                                                type="text"
                                                placeholder="NF"
                                                value={nf}
                                                onChange={set_nf}
                                                required
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <InputSemBorda
                                                label="Serie"
                                                name="Serie"
                                                type="text"
                                                placeholder="Serie"
                                                value={serie}
                                                onChange={set_serie}
                                                required
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                                            />
                                        </div>
                                        <div className="col">
                                            <button type="submit" className="btn btn-success mt-2">Consultar</button>
                                        </div>
                                        <div className="w-100"></div>
                                    </div>
                                </Form>
                            </div>
                            <TabContext value="1">
                                <Row id="elementoParaImprimir" className="imprimir-tabela">
                                    <Tabela
                                        coluna={colunas}
                                        dados={listaResumidoValidade}
                                        itemsPerPage={10}
                                        onVisuClick={function () {

                                        }}
                                        onEditClick={function () {

                                        }}
                                        onDeleteClick={function () {

                                        }}
                                        id="codigo"
                                        permissao_editar={true}
                                        permissao_visualizar={true}
                                        permissao_excluir={true}
                                    />
                                </Row>
                            </TabContext>
                        </Box>
                    </Card.Body>
                </Card>
            </div>


            <div className="container-fluid mt-2 printyes" hidden>
                <ContainerTitulo titulo="Pedido Resumido" />
                <img
                    src={`data:image/png;base64,${imagemFilial}`}
                    alt="user"
                    style={{
                        width: "400px",
                        height: "200px",
                        display: "block",
                        margin: "auto"
                    }}
                />
                <Card>
                    <Card.Body>
                        <Box>
                            <div className="table-responsive">
                                <table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
                                    <thead className="table-cabecalho">
                                        <tr>
                                            <th>CNPJ</th>
                                            <th>Processo</th>
                                            <th>Codigo</th>
                                            <th>Volume</th>
                                            <th>PB</th>
                                            <th>PL</th>
                                            <th>Produção</th>
                                            <th>Validade</th>
                                            <th>Lote</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {listaResumidoValidade.map(function (produto: any) {
                                            return (<>
                                                <tr>
                                                    <th>{produto.cnpj}</th>
                                                    <th>{produto.processo}</th>
                                                    <th>{produto.codigo}</th>
                                                    <th>{produto.volume}</th>
                                                    <th>{produto.peso_br}</th>
                                                    <th>{produto.peso_liq}</th>
                                                    <th>{produto.producao}</th>
                                                    <th>{produto.validade}</th>
                                                    <th>{produto.lote}</th>
                                                </tr>
                                            </>)
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        </Box>
                    </Card.Body>
                </Card>
            </div>
            <ModalLoading show={mostrarModalCarregando} />
        </>
    )
}

export default ResumidoValidade