import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import Tabela from "../../../components/Formularios/Table";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import BackupIcon from '@mui/icons-material/Backup';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"
import ApiGet from "../../../api/endPoints/useGet";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";


function ListarDepositantes() {

    const id_empresa_usuario = getSessionData("isPerfilVinculo")
    const id_filial_usuario = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";
    const id_perfil = getSessionData("id_perfil")

    const visualizar = (dados: any) => {
        console.log(dados)
        const id = dados.id;
        navigate("/cadastro/depositantes/depositante/visualizar/" + id);
    }

    const editar = async (dados: any) => {
        const id = dados.id;
        navigate("/cadastro/depositantes/depositante/editar/" + id)
    }

    const excluir = async (dados: any) => {
        const id = dados.id

        await showConfirmationDialog("Excluir Depositante",
            `Tem certeza que deseja excluir o depositante \n ${dados.depositante}?`,
            "question").then(async function (resposta) {
                if (resposta.confirmed) {

                    const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/del/depositante?id_filial=${getSessionData("DadosIdfilial")}&id=${id}`)
                    if (resposta.data.codigo != 200) {
                        toast.error(resposta.data.message)
                    }
                    else {
                        toast.success(resposta.data.message)
                        carregarDepositantesListar()
                    }
                }
            })
    }

    const navigate = useNavigate()

    //depositantes
    const [depositantes, set_depositantes] = useState([])

    interface Action {
        icon: JSX.Element;
        name: string;
    }

    const [depositante_siscl_wms, set_depositante_siscl_wms] = useState(true)
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/cadastro/depositantes/depositante/novo")}
                />
            ),
            name: "Incluir",
        },
        {
            icon: (
                <BackupIcon onClick={
                    async function () {
                        set_mostrarModalCarregando(true)

                        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/depositantes/ativos?id_filial=${id_filial_usuario}&usuario=${getSessionData("NomeUsuario")}`, {
                            headers: {
                                Authorization: token
                            }
                        }).then(function (resposta: any) {
                            if (resposta.data.codigo != 200) {
                                toast.error(resposta.data.message)
                                set_mostrarModalCarregando(false)
                            }
                            else {
                                toast.success(resposta.data.message)
                                carregarDepositantesListar()
                                set_mostrarModalCarregando(false)
                            }
                        })
                    }
                } />
            ),
            name: "Carregar Depositantes SISCL WMS"
        },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    async function carregarDepositantesListar() {

        set_mostrarModalCarregando(true)
        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/depositante?id_filial=${getSessionData("DadosIdfilial")}&id_vinculo=${getSessionData("isPerfilVinculo")}`)

        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message || "Erro ao listar depositantes")
        }
        else {

            set_depositantes(resposta.data.depositante)
            set_depositante_siscl_wms(resposta.data.depositante_siscl_wms)
        }
        set_mostrarModalCarregando(false)

    }
    useEffect(function () {
        carregarDepositantesListar()
        CarregarPermissões(id_perfil)
    }, [])

    const colunas: ITabela[] = [

        { titulo: "CNPJ", acesso: `cnpj` },
        { titulo: "Fantasia", acesso: "fantasia" }

    ]

    //permissões de alteração
    const [editarDepositante, set_editarDepositante] = useState(true) //-- não tem permissão para editar (true)
    const [verDepositante, set_verDepositante] = useState(true) //-- não tem permissão para ver (true)
    const [excluirDepositante, set_excluirDepositante] = useState(true) // -- não tem permissão para excluir (true)
    const [criarDepositante, set_criarDepositante] = useState(true) // -- não tem permissão para criar (true)


    async function CarregarPermissões(id_perfil: any) {

        const headers = {
            Authorization: token,
        }
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: id_perfil,
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].visualizarDepositante === 1) {
                set_verDepositante(false)
            }
            if (response[0].incluirDepositante === 1) {
                set_criarDepositante(false)
            }
            if (response[0].EditarDepositante === 1) {
                set_editarDepositante(false)
            }
            if (response[0].ExcluirDepositante === 1) {
                set_excluirDepositante(false)
            }


        })
    }

    // Função para realizar a busca
    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase();

        if (termoBusca == "") {
            carregarDepositantesListar()
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = depositantes.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.fantasia.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)

            })

            set_depositantes(resultados)
        }
    }

    useEffect(function () {
        Search(busca)
    }, [busca])

    return (
        <>
            <div className="container-fluid mt-2">
                <ContainerTitulo titulo="Listagem de Depositantes" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        hidden={criarDepositante}
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                hidden={depositante_siscl_wms == true && action.name == "Carregar Depositantes SISCL WMS" ? true : false}
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <div className="d-flex my-2 gap-1 ">
                                        <Col sm={11}>
                                            <InputSemBorda
                                                name="CNPJ"
                                                type="text"
                                                value={busca}
                                                onChange={set_busca}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Procurar por fantasia"
                                            />
                                        </Col>
                                    </div>
                                </Row>




                                <Row>
                                    <Tabela
                                        coluna={colunas}
                                        dados={depositantes}
                                        itemsPerPage={10}
                                        onVisuClick={visualizar}
                                        onEditClick={editar}
                                        onDeleteClick={excluir}
                                        id="codigo"
                                        permissao_editar={editarDepositante}
                                        permissao_visualizar={verDepositante}
                                        permissao_excluir={excluirDepositante}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalLoading show={mostrarModalCarregando} />
        </>
    )
}

export default ListarDepositantes