import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import Tabela from "../../../components/Formularios/Table";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"
import ApiGet from "../../../api/endPoints/useGet";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";

function ListarOperacoes() {
    const id_empresa_usuario = getSessionData("isPerfilVinculo")
    const id_filial_usuario = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken") || "";
    const isSuper = getSessionData("isPerfilSuper") || "";
    const id_perfil = getSessionData("id_perfil")

    const visualizar = (dados: any) => {
        const id = dados.id;
        navigate("/cadastro/tempo/operacional/visualizar/" + id);
    }

    const editar = async (dados: any) => {
        const id = dados.id;
        navigate("/cadastro/tempo/operacional/editar/" + id)
    }

    const excluir = async (dados: any) => {
        const id = dados.id

        await showConfirmationDialog(
            "Excluir Código do Sitema",
            `Tem certeza que deseja excluir a operação de tipo \n ${dados.tipo_op}?`,
            "question"
        ).then(async function (resposta) {
            if (resposta.confirmed) {
                const resposta = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/del/byID?id_filial=${getSessionData("DadosIdfilial")}&id=${id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                if (resposta.data.codigo != 200) {
                    toast.error(resposta.data.message)
                    carregarTempos()
                }
                else {
                    toast.success(resposta.data.message)
                    carregarTempos()
                }
            }
        })
    }

    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    interface Action {
        icon: JSX.Element;
        name: string;
    }

    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/cadastro/tempo/operacional/incluir/novo")}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    const navigate = useNavigate()

    //tempoOperacoes
    const [tempoOperacao, set_TempoOperacao] = useState([])

    const colunas: ITabela[] = [

        { titulo: "Depositante", acesso: "fantasia" },
        { titulo: "Operação", acesso: `tipo_op` },
        { titulo: "Carga", acesso: "perfil_carga" },
        { titulo: "Veiculo", acesso: "tipo_veiculo" },
        { titulo: "Tempo Operacional", acesso: "tempo_operacional" }
    ]

    //permissões de alteração
    const [editarTempoOperacional, set_editarTempoOperacional] = useState(true) //-- não tem permissão para editar (true)
    const [verTempoOperacional, set_verTempoOperacional] = useState(true) //-- não tem permissão para ver (true)
    const [excluirTempoOperacional, set_excluirTempoOperacional] = useState(true) // -- não tem permissão para excluir (true)
    const [criarTempoOperacional, set_criarTempoOperacional] = useState(true) // -- não tem permissão para criar (true)

    async function CarregarPermissões(id_perfil: any) {
        set_mostrarModalCarregando(true)
        const headers = {
            Authorization: token,
        }
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: id_perfil,
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].visualizarTempoOperacional === 1) {
                set_verTempoOperacional(false)
            }
            if (response[0].incluirTempoOperacional === 1) {
                set_criarTempoOperacional(false)
            }
            if (response[0].EditarTempoOperacional === 1) {
                set_editarTempoOperacional(false)
            }
            if (response[0].ExcluirTempoOperacional === 1) {
                set_excluirTempoOperacional(false)
            }


        })
        set_mostrarModalCarregando(false)
    }

    async function carregarTempos() {
        set_mostrarModalCarregando(true)
        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/tempo/byfilial?id_filial=${getSessionData("DadosIdfilial")}&id_vinculo=${getSessionData("isPerfilVinculo")}`, {
            headers: {
                authorization: token
            }
        })

        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message || "Erro ao listar tempos")
        }
        else {
            set_TempoOperacao(resposta.data.tempos)
            console.log(resposta.data.tempos)
        }
        set_mostrarModalCarregando(false)
    }

    useEffect(function () {
        carregarTempos()
        CarregarPermissões(id_perfil)
    }, [])


    // Função para realizar a busca
    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase();

        if (termoBusca == "") {
            carregarTempos()
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = tempoOperacao.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.tipo_op.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)

            })

            set_TempoOperacao(resultados)
        }
    }

    useEffect(function () {
        Search(busca)
    }, [busca])

    return (
        <>
            <div className="container-fluid mt-2">
                <ContainerTitulo titulo="Listagem de Tempo Operacional" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        hidden={criarTempoOperacional}
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <div className="d-flex my-2 gap-1 ">
                                        <Col sm={11}>
                                            <InputSemBorda
                                                name="CNPJ"
                                                type="text"
                                                value={busca}
                                                onChange={set_busca}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Procurar por operação"
                                            />
                                        </Col>
                                    </div>
                                </Row>




                                <Row>
                                    <Tabela
                                        coluna={colunas}
                                        dados={tempoOperacao}
                                        itemsPerPage={10}
                                        onVisuClick={visualizar}
                                        onEditClick={editar}
                                        onDeleteClick={excluir}
                                        id="codigo"
                                        permissao_editar={editarTempoOperacional}
                                        permissao_visualizar={verTempoOperacional}
                                        permissao_excluir={excluirTempoOperacional}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ModalLoading show={mostrarModalCarregando} />
        </>
    )
}

export default ListarOperacoes