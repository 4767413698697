import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import SelectInput from "../../shared/SelectInputs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button as ButtonCustom } from "../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import Tabela from "../../components/Formularios/Table";
import { ChangeEvent, useEffect, useState } from "react";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import { resolvePath, useNavigate } from "react-router-dom";
import axios from "axios";
import { Api } from "../../api/api";
import { getSessionData } from "../../utils/storageUtils";
import { toast } from "react-toastify";
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";
import { MdLocalPrintshop } from "react-icons/md"
import { FaFileExcel } from "react-icons/fa";
import { ImFileExcel } from "react-icons/im"
import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { FaSearch } from "react-icons/fa"
import { ITabela } from "../../components/Formularios/Table/TabelaInterface"
import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver"
import { FaEdit } from "react-icons/fa"
import {
    Card,
    Col,
    Form,
    Modal,
    Row,
    Button as Button_Boostrap,
} from "react-bootstrap";

function AgendaConsultaAgendamentos() {

    const id_filial = getSessionData("DadosIdfilial")
    const id_usuario = getSessionData("DadosUsuarioID")
    const id_empresa = getSessionData("isPerfilVinculo")
    const token = getSessionData("MultfilialWebToken")


    interface Action {
        icon: JSX.Element;
        name: string;
    }

    const actions: Action[] = [
        /* {
             icon: (
                 <AddIcon
                     onClick={() => navigate("/cadastro/tempo/operacional/incluir/novo")}
                 />
             ),
             name: "Incluir",
         },*/

        {
            icon: <MdLocalPrintshop onClick={function () {


                window.print()
                //imprime o pdf do estoque

            }} />, name: ""
        },
        {
            icon: <ImFileExcel onClick={function () {


                gerarExcelEstoque()

            }} />, name: "Gerar Excel com mais Informações"
        }

    ]



    function gerarExcelEstoque() {
        set_mostrarModalCarregando(true)
        if (ListaagendamentosFiltrados.length == 0) {
            toast.error("Selecione o depositante e gere o relatório.")
        }
        else {

            // Criar um novo objeto com as propriedades específicas do cabeçalho
            const headerRowData = {
                Depositante: 'Depositante',
                Status: 'Status',
                Equipe: 'Equipe',
                'Tipo Operação': 'Tipo Operação',
                'Tipo Veículo': 'Tipo Veículo',
                Perfil: 'Perfil',
                'Dt Cadastro': 'Dt Cadastro',
                'Usuário Cadastro': 'Usuário Cadastro',
                'Agenda Inicio': 'Agenda Inicio',
                'Agenda Fim': 'Agenda Fim',
                Chegada: 'Chegada',
                'Usuário Chegada': 'Usuário Chegada',
                'Inicio Operação': 'Inicio Operação',
                'Usuário Inicio Operação': 'Usuário Inicio Operação',
                Concluído: 'Concluído',
                'Usuário Concluído': 'Usuário Concluído'
            }

            // Converter o objeto em uma matriz de valores (cabeçalho)
            const headerRow = Object.values(headerRowData)

            // Criar uma nova planilha
            const ws = XLSX.utils.aoa_to_sheet([headerRow])

            // Adicionar a matriz de cabeçalho à planilha
            XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 0 })

            ListaagendamentosFiltrados.forEach(function (item: any, index: any) {

                const dados: any =
                {
                    Depositante: item.fantasia,
                    Status: item.status,
                    Equipe: item.equipe,
                    Tipo_Operação: item.tipooperacao,
                    Tipo_Veículo: item.tipoveiculo,
                    Perfil: item.perfil_operacao,
                    Dt_Cadastro: item.cadastro,
                    Usuário_Cadastro: item.usuario_cadastro,
                    Agenda_Inicio: item.hora_inicio + " " + item.data_inicio,
                    Agenda_Fim: item.hora_fim + " " + item.data_fim,
                    Chegada: item.horario_aguardando_op,
                    Usuário_Chegada: item.usuario_agop,
                    Inicio_Operação: item.horario_em_op,
                    Usuário_Inicio_Operação: item.usuario_emop,
                    Concluído: item.horario_concluido,
                    Usuário_Concluído: item.usuario_concluido
                }


                // Adicionar cada linha à planilha
                XLSX.utils.sheet_add_aoa(ws, [Object.values(dados)], { origin: index + 1 })
            })

            // Criar um novo livro de Excel
            const wb = XLSX.utils.book_new()

            // Adicionar a planilha ao livro de Excel
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')

            // Configurar opções de escrita, incluindo o tipo de livro e o tipo de saída
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

            // Criar um Blob a partir do buffer de Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            // Salvar o Blob como um arquivo Excel
            FileSaver.saveAs(blob, 'Agendamentos.xlsx')
        }
        set_mostrarModalCarregando(false)
    }
    const [mostrarModalCarregando, set_mostrarModalCarregando] = useState(false)

    const [depositante, set_depositante] = useState("TODOS")//guarda o id do depositante
    const [listaDepositantesByIdFilial, set_listaDepositantesByIdFilial] = useState<any>([])
    async function carregarDepositantesListar() {
        set_mostrarModalCarregando(true)
        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/usuario/depositante?id_usuario=${getSessionData("DadosUsuarioID")}&id_filial=${getSessionData("DadosIdfilial")}`)

        if (resposta.data.codigo != 200) {
            toast.error(resposta.data.message || "Erro ao listar depositantes")
        }
        else {

            set_listaDepositantesByIdFilial(resposta.data.depositante)
            if (listaDepositantesByIdFilial.length == 1) {
                set_depositante(resposta.data.depositante[0].fantasia)
            }
        }
        set_mostrarModalCarregando(false)
    }

    const [equipe, set_equipe] = useState("Todos")//guarda o id da equipe
    const [listaEquipeByIdFilial, set_listaEquipeByIdFilia] = useState([])
    //criar logica para puxar qeuipes pela filial

    const [ListaCodigoSistema, setListaCodigoSistema] = useState<any>([])
    const [ListaTempoOperacao, setListaTempoOperacao] = useState<any>([])
    const [ListaPerfilOperacao, setListaPerfilOperacao] = useState<any>([])
    const [ListaTipoVeiculo, setListaTipoVeiculo] = useState<any>([])
    const [tipoOperacao, setTipoOperacao] = useState("Todos")
    const [id_operacao, set_id_operacao] = useState<any>("")
    const [perfilOperacao, setPerfilOperacao] = useState("Todos")
    const [id_perfil_operacao, set_id_perfil_operacao] = useState("")
    const [tipoVeiculo, setTipoVeiculo] = useState("Todos")
    const [id_tipo_veiculo, set_id_tipo_veiculo] = useState("")
    const [horaInicio, setHoraInicio] = useState<string>("")
    const [horaFim, setHoraFim] = useState<string>("")
    const [ListaStatusAgendamento, setListaStatusAgendamento] = useState<any>([])

    function carregarCodigosDoSistema() {
        set_mostrarModalCarregando(true)
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/carregar/codigo/agenda`, {
                headers: {
                    Authorization: token
                }
            })
            .then(function (resposta) {
                if (resposta.data.codigo !== 200) {
                    toast.error(resposta.data.message)
                    set_mostrarModalCarregando(false)
                } else {
                    const codigos = resposta.data.codigosSistema
                    setListaCodigoSistema(codigos)

                    setListaTempoOperacao([{ label: "Todos", value: "Todos" }])
                    setListaPerfilOperacao([{ label: "Todos", value: "Todos" }])
                    setListaTipoVeiculo([{ label: "Todos", value: "Todos" }])
                    setListaStatusAgendamento([{ label: "TODOS", value: "TODOS" }])

                    //listando tipos de operação
                    codigos
                        .filter((codigo: any) => codigo.tabela === 'OPERACAO')
                        .forEach((operacao: any) => {

                            setListaTempoOperacao((prevLista: any) => [
                                ...prevLista,
                                { label: operacao.descricao, value: operacao.descricao, id: operacao.id }
                            ])
                        })

                    //listando Perfil de operação
                    codigos
                        .filter((codigo: any) => codigo.tabela === 'PERFILDECARGA')
                        .forEach((perfildecarga: any) => {

                            setListaPerfilOperacao((prevLista: any) => [
                                ...prevLista,
                                { label: perfildecarga.descricao, value: perfildecarga.descricao, id: perfildecarga.id }
                            ])
                        })

                    //listando Tipos Veiculos
                    codigos
                        .filter((codigo: any) => codigo.tabela === 'TIPOVEICULO')
                        .forEach((tipoveiculo: any) => {

                            setListaTipoVeiculo((prevLista: any) => [
                                ...prevLista,
                                { label: tipoveiculo.descricao, value: tipoveiculo.descricao, id: tipoveiculo.id }
                            ])
                        })

                    //listando Status
                    codigos
                        .filter((codigo: any) => codigo.tabela === 'STATUSOP')
                        .forEach((status: any) => {

                            setListaStatusAgendamento((prevLista: any) => [
                                ...prevLista,
                                { label: status.descricao, value: status.descricao, id: status.id }
                            ])
                        })

                    set_mostrarModalCarregando(false)
                }
            })
            .catch(function (erro) {
                toast.error(erro);
            });
    }


    useEffect(function () {

        const date = new Date()
        const ano = date.getFullYear()
        const mes = String(date.getMonth() + 1).padStart(2, '0')
        const dia = String(date.getDate()).padStart(2, '0')

        setDataInicio(`${ano}-${mes}-${dia}`)
        setDataFim(`${ano}-${mes}-${dia}`)
    }, [])

    const [dataInicio, setDataInicio] = useState<string>("")
    const [dataFim, setDataFim] = useState<string>("")

    function buscarAgendamentos() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/agenda/byfilial?id_usuario=${id_usuario}&id_filial=${id_filial}&dataInicio=${dataInicio}&dataFim=${dataFim}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {

            if (resposta.data.codigo != 200) {
                toast.error(resposta.data.message)
            }
            else {

                set_ListaagendamentosFiltrados(resposta.data.agendamentos)
            }
        }).catch(function (erro) {
            toast.error(erro)
        })
    }

    function buscarAgendamentosFiltro(e: any = "") {
        e.preventDefault()

        const dados = {
            id_filial: id_filial,
            depositante: depositante,
            equipe: equipe,
            tipoOperacao: tipoOperacao,
            perfilOperacao: perfilOperacao,
            tipoVeiculo: tipoVeiculo,
            StatusAgendamento: StatusAgendamento,
            dataInicio: dataInicio,
            dataFim: dataFim,
            id_usuario: id_usuario
            //horaInicio: horaInicio,
            //horaFim: horaFim
        }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/filtrar/agendamentos`, {
            headers: {
                Authorization: token
            },
            params: dados

        }).then(function (resposta) {

            if (resposta.data.codigo == 200) {

                set_ListaagendamentosFiltrados(resposta.data.agendamentos)
            }
            else {

                toast.error(resposta.data.message)
                set_ListaagendamentosFiltrados(resposta.data.agendamentos)
            }

        }).catch(function (erro) {
            toast.error(erro)
        })
    }

    const [StatusAgendamento, setStatusAgendamento] = useState("TODOS")

    const [ListaagendamentosFiltrados, set_ListaagendamentosFiltrados] = useState([])


    const [listaEquipes, setListaEquipes] = useState([])
    const buscarEquipes = (id_filial: any) => {

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/all/equipes/by/filial?app=1&id_filial=${id_filial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {

            setListaEquipes(resposta.data.agenda_equipes)
        }).catch(function (erro: any) {

            toast.error(erro)
        })
    }

    const [imagemFilial, set_imagemFilial] = useState("")
    function setImagemFilial(imagemBase64: any) {
        set_imagemFilial(imagemBase64.replace(/^"(.*)"$/, '$1'))
    }

    const colunas: ITabela[] = [

        { titulo: "Depositante", acesso: `fantasia` },
        { titulo: "Equipe", acesso: "equipe" },
        { titulo: "Data Inicio", acesso: "data_inicio" },
        { titulo: "Hora Inicio", acesso: "hora_inicio" },
        { titulo: "Data Fim", acesso: "data_fim" },
        { titulo: "Hora Fim", acesso: "hora_fim" },
        { titulo: "status", acesso: "status" },
        { titulo: "Operação", acesso: "tipooperacao" },
        { titulo: "Perfil Op", acesso: "perfil_operacao" },
        { titulo: "Veiculo", acesso: "tipoveiculo" }
    ]

    const [idAgendamento, set_idAgendamento] = useState("")
    const [equipeForm, set_equipeForm] = useState("")
    const [depositanteForm, set_depositanteForm] = useState("")
    const [abrirModal, set_abrirModal] = useState(false)


    const [DataAgendado, set_DataAgendado] = useState("")
    const [DataAgOp, set_DataAgOp] = useState("")
    const [DataConcluido, set_DataConcluido] = useState("")
    const [DataEmOP, set_DataEmOp] = useState("")
    const [horasEmOP, set_horasEmOp] = useState("")
    const [horasAgendado, set_horasAgendado] = useState("")
    const [horasAgOp, set_horasAgOp] = useState("")
    const [horasConcluido, set_horasConcluido] = useState("")


    function AtualizarAgendamento(e: any) {

        e.preventDefault()

        const dados = {
            id_agendamento: idAgendamento,
            horas_agendado: horasAgendado,
            data_agendado: DataAgendado,
            horas_agop: horasAgOp,
            data_agop: DataAgOp,
            horas_emop: horasEmOP,
            data_emop: DataEmOP,
            horasConcluido: horasConcluido,
            data_concluido: DataConcluido,
            usuarioEdicao: getSessionData("NomeUsuario") || ""
        }

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/att/agendamento/via/agenda`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {

            if (resposta.data.codigo != 200) {

                toast.error(resposta.data.message)
            }
            else {

                toast.success(resposta.data.message)
                set_abrirModal(false)
                buscarAgendamentos()
            }
        }).catch(function (erro) {

            toast.error(erro)
        })
    }


    function AbrirEditarStatus(dados: any) {


        axios.get(`${process.env.REACT_APP_API_BASE_URL}/agendamento/id?id_agendamento=${dados.id_agendamento}`, {
            headers: {
                Authorization: token
            }
        })
            .then(function (resposta) {

                if (resposta.data.codigo != 200) {
                    toast.error(resposta.data.message)
                }
                else {

                    set_idAgendamento(resposta.data.agendamento[0].id_agendamento)
                    set_equipeForm(resposta.data.agendamento[0].equipe)
                    set_depositanteForm(resposta.data.agendamento[0].fantasia)

                    set_horasAgendado(resposta.data.agendamento[0].horario_agendado ? resposta.data.agendamento[0].horario_agendado.split("T")[1].split(".")[0] || "" : "")
                    set_DataAgendado(resposta.data.agendamento[0].horario_agendado ? resposta.data.agendamento[0].horario_agendado.split("T")[0] || "" : "")

                    set_horasAgOp(resposta.data.agendamento[0].horario_aguardando_op ? resposta.data.agendamento[0].horario_aguardando_op.split("T")[1].split(".")[0] || "" : "")
                    set_DataAgOp(resposta.data.agendamento[0].horario_aguardando_op ? resposta.data.agendamento[0].horario_aguardando_op.split("T")[0] || "" : "")

                    set_horasEmOp(resposta.data.agendamento[0].horario_em_op ? resposta.data.agendamento[0].horario_em_op.split("T")[1].split(".")[0] || "" : "")
                    set_DataEmOp(resposta.data.agendamento[0].horario_em_op ? resposta.data.agendamento[0].horario_em_op.split("T")[0] || "" : "")

                    set_horasConcluido(resposta.data.agendamento[0].horario_concluido ? resposta.data.agendamento[0].horario_concluido.split("T")[1].split(".")[0] || "" : "")
                    set_DataConcluido(resposta.data.agendamento[0].horario_concluido ? resposta.data.agendamento[0].horario_concluido.split("T")[0] || "" : "")

                    set_abrirModal(true)
                }
            }).catch(function (erro) {

                toast.error(erro)
            })
    }


    //verificando permissões
    const id_perfil_usuario = getSessionData("id_perfil")
    const [EditarAgendamentoDeEquipeTodos, setEditarAgendamentoDeEquipeTodos] = useState(true)
    function carregarPermissões() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil_usuario}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {

            if (resposta.data[0].EditarAgendamentoDeEquipeTodos == 1) {
                //dar permissão para visualizarAgendamento de Equipes
                setEditarAgendamentoDeEquipeTodos(false)
            }
            else {
                toast.error("Perfil sem permissão para visualizar agenda")
            }

        }).catch(function (erro) {
            toast.error(erro)
        })
    }

    useEffect(function () {
        setImagemFilial(getSessionData("imgFilial"))
        buscarEquipes(id_filial)
        carregarDepositantesListar()
        carregarCodigosDoSistema()
        carregarPermissões()
    }, [])

    useEffect(function () {
        buscarAgendamentos()
    }, [listaEquipes])


    return (
        <>
            <div className="container-fluid mt-2 no-print">
                <ContainerTitulo titulo="Listagem de Agendamentos" />
                <Card>
                    <Card.Body>
                        <Box>
                            <div className="container">
                                <Row>
                                    <SpeedDial

                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                            />
                                        ))}
                                    </SpeedDial>
                                </Row>
                                <form onSubmit={buscarAgendamentosFiltro}>
                                    <div className="row">
                                        <div className="col-4">
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Depositante
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={depositante}
                                                    onChange={function (e: any) {
                                                        set_depositante(e.target.value)
                                                    }}
                                                    label="Depositante"
                                                    required
                                                >
                                                    <MenuItem value={"TODOS"}>
                                                        <em>TODOS</em>
                                                    </MenuItem>
                                                    {listaDepositantesByIdFilial.map((depositante: any) =>
                                                    (
                                                        <MenuItem value={depositante.id_depositante} data-master={depositante.fantasia} onClick={function () {

                                                        }}>
                                                            {depositante.fantasia}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="col-4">
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                    Equipe
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    required
                                                    id="demo-simple-select-standard"
                                                    value={equipe}
                                                    onChange={function (e: any) {
                                                        set_equipe(e.target.value)
                                                    }}
                                                    label="Equipe"
                                                >
                                                    <MenuItem value={"Todos"}>
                                                        <em>Todos</em>
                                                    </MenuItem>
                                                    {listaEquipes.map((equipe: any) => (
                                                        <MenuItem value={equipe.id} data-master={equipe.equipe} onClick={function () {

                                                        }}>
                                                            {equipe.equipe}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col-3">
                                            <SelectInput
                                                value={tipoOperacao}
                                                onChange={function (e: ChangeEvent<HTMLSelectElement>) {

                                                    setTipoOperacao(e.target.value)//seta o tempo da operação

                                                    const selectedOption: any = ListaTempoOperacao.find(function (option: any) {
                                                        return option.value == e.target.value
                                                    })

                                                    if (selectedOption) {
                                                        set_id_operacao(selectedOption.id)
                                                    }
                                                }}
                                                options={ListaTempoOperacao}
                                                placeholder="Tipo Operação"
                                                disabed={false}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <SelectInput
                                                value={perfilOperacao}
                                                onChange={function (e: ChangeEvent<HTMLSelectElement>) {

                                                    setPerfilOperacao(e.target.value)

                                                    const selectedOption: any = ListaPerfilOperacao.find(function (option: any) {
                                                        return option.value == e.target.value
                                                    })

                                                    if (selectedOption) {

                                                        set_id_perfil_operacao(selectedOption.id)
                                                    }
                                                }}
                                                options={ListaPerfilOperacao}
                                                placeholder="Perfil Operação"
                                                disabed={false}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <SelectInput
                                                value={tipoVeiculo}
                                                onChange={function (e: any) {

                                                    setTipoVeiculo(e.target.value)

                                                    const selectedOption: any = ListaTipoVeiculo.find(function (option: any) {
                                                        return option.value == e.target.value
                                                    })

                                                    if (selectedOption) {

                                                        set_id_tipo_veiculo(selectedOption.id)
                                                    }
                                                }}
                                                options={ListaTipoVeiculo}
                                                placeholder="Tipo Veiculo"
                                                disabed={false}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <SelectInput
                                                value={StatusAgendamento}
                                                onChange={function (e: ChangeEvent<HTMLSelectElement>) {
                                                    setStatusAgendamento(e.target.value)
                                                }}
                                                options={ListaStatusAgendamento}
                                                placeholder="Status do agendamento"
                                            />
                                        </div>
                                        <div className="w-100 pt-2"></div>
                                        <div className="col-2">
                                            <InputSemBorda
                                                type="date"
                                                label="Data Início"
                                                name="dataInicio"
                                                id="dataInicio"
                                                value={dataInicio}
                                                onChange={setDataInicio}
                                                required
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                                            />
                                        </div>
                                        {/*<div className="col-2">
                                            <InputSemBorda
                                                type="time"
                                                label="Hora Início"
                                                name="horaInicio"
                                                id="horaInicio"
                                                value={horaInicio}
                                                onChange={setHoraInicio}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                                            />
                                        </div> */}
                                        <div className="col-2">
                                            <InputSemBorda
                                                type="date"
                                                label="Data Fim"
                                                name="datafim"
                                                id="dataFim"
                                                value={dataFim}
                                                required
                                                onChange={setDataFim}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                                            />
                                        </div>
                                        {/*<div className="col-2">
                                            <InputSemBorda
                                                type="time"
                                                label="Hora Fim"
                                                name="horaFim"
                                                id="horaFim"
                                                value={horaFim}
                                                onChange={setHoraFim}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                                            />
                                        </div> */}
                                        <div className="col-4 p-3">

                                            <button type="submit" className="btn btn-primary"><FaSearch /> Procurar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <TabContext value="1">
                                <Row id="elementoParaImprimir" className="imprimir-tabela">
                                    <Tabela
                                        coluna={colunas}
                                        dados={ListaagendamentosFiltrados}
                                        itemsPerPage={10}
                                        onVisuClick={function () {

                                        }}
                                        onEditClick={AbrirEditarStatus}
                                        onDeleteClick={function () {

                                        }}
                                        id="codigo"
                                        permissao_editar={EditarAgendamentoDeEquipeTodos}
                                        permissao_visualizar={true}
                                        permissao_excluir={true}
                                    />
                                </Row>
                            </TabContext>
                        </Box>
                    </Card.Body>
                </Card>
            </div>
            <ModalLoading show={mostrarModalCarregando} />



            <div className="container-fluid mt-2 printyes" hidden>
                <ContainerTitulo titulo="Listagem de Agendamentos" />

                <img
                    src={`data:image/png;base64,${imagemFilial}`}
                    alt="user"
                    style={{
                        width: "400px",
                        height: "200px",
                        display: "block",
                        margin: "auto"
                    }}
                />

                <br />
                <Card style={{ position: "absolute" }}>
                    <Card.Body>
                        <Box>
                            <div className="table-responsive">
                                <table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
                                    <thead className="table-cabecalho">
                                        <tr>
                                            <th>Depositante</th>
                                            <th>Equipe</th>
                                            <th>Data Inicio</th>
                                            <th>Hora Inicio</th>
                                            <th>Data Fim</th>
                                            <th>Hora Fim</th>
                                            <th>Status</th>
                                            <th>Operação</th>
                                            <th>Perfil Op</th>
                                            <th>Veiculo</th>
                                            <th>Agendado</th>
                                            <th>Aguardando operação</th>
                                            <th>Em operação</th>
                                            <th>Concluido</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ListaagendamentosFiltrados.map(function (agendamento: any) {

                                            return (<>
                                                <tr>
                                                    <th>{agendamento.fantasia}</th>
                                                    <th>{agendamento.equipe}</th>
                                                    <th>{agendamento.data_inicio}</th>
                                                    <th>{agendamento.hora_inicio}</th>
                                                    <th>{agendamento.data_fim}</th>
                                                    <th>{agendamento.hora_fim}</th>
                                                    <th><span className={agendamento.status == "AGENDADO" ? "bg-secondary text-white" : agendamento.status == "AGUARDANDO OPERACAO" ? "bg-primary text-white" : agendamento.status == "EM OPERACAO" ? "bg-warning text-white" : agendamento.status == "CONCLUIDO" ? "bg-success text-white" : "bg-danger text-white"}>{agendamento.status}</span></th>
                                                    <th>{agendamento.tipooperacao}</th>
                                                    <th>{agendamento.perfil_operacao}</th>
                                                    <th>{agendamento.tipoveiculo}</th>
                                                    <th>{agendamento.horario_agendado}</th>
                                                    <th>{agendamento.horario_aguardando_op}</th>
                                                    <th>{agendamento.horario_em_op}</th>
                                                    <th>{agendamento.horario_concluido}</th>
                                                </tr>

                                            </>)
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        </Box>
                    </Card.Body>
                </Card>

                {/*MODAL PARA EDITAR STATUS-HORA */}
                <Modal show={abrirModal}>
                    <Modal.Header className="bg-white">
                        <Modal.Title>{"Editar horários do agendamento"}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={AtualizarAgendamento}>
                        <Modal.Body className="bg-white">
                            <Row>
                                <Col>
                                    <InputSemBorda
                                        type="text"
                                        label="ID"
                                        name="id agendamento"
                                        id="id agendamento"
                                        value={idAgendamento}
                                        onChange={() => { }}
                                        readonly
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                                    />
                                </Col>
                                <Col>
                                    <InputSemBorda
                                        type="text"
                                        label="Equipe"
                                        name="Equipe"
                                        id="Equipe"
                                        value={equipeForm}
                                        onChange={() => { }}
                                        readonly
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputSemBorda
                                        type="text"
                                        label="Depositante"
                                        name="Depositante"
                                        id="Depositante"
                                        value={depositanteForm}
                                        onChange={() => { }}
                                        readonly
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <div className="container">
                                    <div className="row">
                                        <div className="col py-2">
                                            <p>AGENDADO:</p>
                                        </div>
                                        <div className="col">
                                            <input type="time" value={horasAgendado} onChange={function (e: any) {
                                                set_horasAgendado(e.target.value)
                                            }}
                                            />
                                        </div>
                                        <div className="col">
                                            <input type="date" value={DataAgendado} onChange={function (e: any) {
                                                set_DataAgendado(e.target.value)
                                            }} />
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col py-1">
                                            <p>AGUARDANDO OPERAÇÃO:</p>
                                        </div>
                                        <div className="col">
                                            <input type="time" value={horasAgOp} onChange={function (e: any) {
                                                set_horasAgOp(e.target.value)
                                            }}
                                                disabled={horasAgendado != "" && DataAgendado != "" ? false : true}
                                            />
                                        </div>
                                        <div className="col">
                                            <input type="date" value={DataAgOp} onChange={function (e: any) {
                                                set_DataAgOp(e.target.value)
                                            }}
                                                disabled={horasAgendado != "" && DataAgendado != "" ? false : true}
                                            />
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col py-2">
                                            <p>EM OPERAÇÃO:</p>
                                        </div>
                                        <div className="col">
                                            <input type="time" value={horasEmOP} onChange={function (e: any) {
                                                set_horasEmOp(e.target.value)
                                            }}
                                                disabled={horasAgOp != "" && DataAgOp != "" ? false : true}
                                            />
                                        </div>
                                        <div className="col">
                                            <input type="date" value={DataEmOP} onChange={function (e: any) {
                                                set_DataEmOp(e.target.value)
                                            }}
                                                disabled={horasAgOp != "" && DataAgOp != "" ? false : true}
                                            />
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col py-2">
                                            <p>CONCLUIDO:</p>
                                        </div>
                                        <div className="col">
                                            <input type="time" value={horasConcluido} onChange={function (e: any) {
                                                set_horasConcluido(e.target.value)
                                            }}
                                                disabled={horasEmOP != "" && DataEmOP != "" ? false : true}
                                            />
                                        </div>
                                        <div className="col">
                                            <input type="date" value={DataConcluido} onChange={function (e: any) {
                                                set_DataConcluido(e.target.value)
                                            }}
                                                disabled={horasEmOP != "" && DataEmOP != "" ? false : true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Row>

                        </Modal.Body>
                        <Modal.Footer className="bg-white">
                            <div className="d-flex my-2 gap-1 justify-content-between">
                                <button type="button" className="btn btn-secondary"
                                    onClick={function () {

                                        set_abrirModal(false)
                                    }}>Voltar</button>
                                <button type='submit' className="btn btn-primary">Salvar</button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        </>
    )
}


export default AgendaConsultaAgendamentos