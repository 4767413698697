import FormularioOperacoes from "./formulario/formularioOperacoes";
import { useParams } from "react-router-dom"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"

function EditarOperacao() {
    const params = useParams()
    return (
        <>
            <div className="container-fluid mt-2">
                <ContainerTitulo titulo="Editar Tempo de Operação" />
                <FormularioOperacoes idequipe={params.id} acao="editar" />
            </div>
        </>
    )
}

export default EditarOperacao