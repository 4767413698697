import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/img/principal.jpg";
import { getSessionData } from "../utils/storageUtils";
import ApiGet from "../api/endPoints/useGet";
import { useEffect, useState } from "react";

const LeftBar = (): JSX.Element => {
  const isSuper = getSessionData("isPerfilSuper") || "";
  const id_perfil = getSessionData("id_perfil")
  const token = getSessionData("MultfilialWebToken") || "";
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false);

  //controle de permissões --- inicio
  const [acessoADM, setacessoADM] = useState(true)
  const [empresaHOLDING, setempresaHOLDING] = useState(true)
  const [empresaPERFILPERMISSAO, setempresaPERFILPERMISSAO] = useState(true)
  const [empresaUSUARIO, setempresaUSUARIO] = useState(true)
  const [empresaCODSISTEMA, setempresaCODSISTEMA] = useState(true)


  const [acessoCADASTRO, setacessoCADASTRO] = useState(true)
  const [acessoFILIAL, setacessoFILIAL] = useState(true)
  const [acessoDEPOSITANTE, setacessoDEPOSITANTE] = useState(true)
  const [acessoEQUIPEOPERACIONAL, setacessoEQUIPEOPERACIONAL] = useState(true)
  const [acessoTEMPOOPERACIONAL, setacessoTEMPOOPERACIONAL] = useState(true)
  const [acessoAGENDAEQUIPE, setacessoAGENDAEQUIPE] = useState(true)

  const [acessoESTOQUE, setacessoESTOQUE] = useState(true)
  const [acessoESTOQUESINTETICO, setacessoESTOQUESINTETICO] = useState(true)
  const [acessoESTOQUEVALIDADELOTE, setacessoESTOQUEVALIDADELOTE] = useState(true)

  const [acessoPEDIDO, setacessoPEDIDO] = useState(true);
  const [visualizarEnviarPedido, setvisualizarEnviarPedido] = useState(true)
  const [visualizarPedidoStatus, setVisualizarPedidoStatus] = useState(true)
  const [visualizarPedidoResumido, setVisualizarPedidoResumido] = useState(true)
  const [visualizarPedidoValidadeLote, setVisualizarPedidoValidadeLote] = useState(true)
  const [visualizarPedidoBarra, setVisualizarPedidoBarra] = useState(true)

  const [acessoAGENDAMENTODEEQUIPE, setacessoAGENDAMENTODEEQUIPE] = useState(true)
  /*const [visualizarAgendamentoDeEquipe, setvisualizarAgendamentoDeEquipe] = useState(true)
  const [incluirAgendamentoDeEquipe, setincluirAgendamentoDeEquipe] = useState(true)
  const [EditarAgendamentoDeEquipeUsuario, setEditarAgendamentoDeEquipeUsuario] = useState(true)
  const [EditarAgendamentoDeEquipeTodos, setEditarAgendamentoDeEquipeTodos] = useState(true)
  const [ExcluirAgendamentoDeEquipeUsuario, setExcluirAgendamentoDeEquipeUsuario] = useState(true)
  const [ExcluirAgendamentoDeEquipeTodos, setExcluirAgendamentoDeEquipeTodos] = useState(true)
  const [AcessoTotalAgendamentoDeEquipe, setAcessoTotalAgendamentoDeEquipe] = useState(true)*/

  const [visualizarResumidoValidade, setVisualizarResumidoValidade] = useState(true)
  const [visualizarDetalhadoBarra, setvisualizarDetalhadoBarra] = useState(true)
  const [acessoRECEBIMENTOS, setacessoRECEBIMENTOS] = useState(true)

  const [acessoRELATORIOS, setacessoRELATORIOS] = useState(true)
  const [visualizarDashboard, set_visualizarDashboard] = useState(true)
  const [visualizarTorre, set_visualizarTorre] = useState(true)
  const [acessoUsuarioFilial, setAcessoUsuarioFilial] = useState(true)

  async function CarregarPermissões(id_perfil: any) {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil",
      parametros: {
        idperfil: id_perfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then(function (retorno) {
        console.log(retorno)
        //##ACESSO ADM - módulo 
        if (retorno[0].visualizar_empresa_holding === 1 ||
          retorno[0].incluir_empresa_holding === 1 ||
          retorno[0].editar_empresa_holding === 1 ||
          retorno[0].excluir_empresa_holding === 1 ||
          retorno[0].visualizar_perfil_permissao === 1 ||
          retorno[0].incluir_perfil_permissao === 1 ||
          retorno[0].editar_perfil_permissao === 1 ||
          retorno[0].excluir_perfil_permissao === 1 ||
          retorno[0].visualizar_usuario === 1 ||
          retorno[0].incluir_usuario === 1 ||
          retorno[0].editar_usuario === 1 ||
          retorno[0].excluir_usuario === 1 ||
          retorno[0].visualizar_codsistema === 1 ||
          retorno[0].incluir_codsistema === 1 ||
          retorno[0].editar_codsistema === 1 ||
          retorno[0].excluir_codsistema === 1 ||
          retorno[0].visualizarUsuarioFilial === 1) {  


          setacessoADM(false) //tem acesso à adm - mostrar adm


          //##ACESSO empresa(holding) - submódulo 
          if (retorno[0].visualizar_empresa_holding === 1 ||
            retorno[0].incluir_empresa_holding === 1 ||
            retorno[0].editar_empresa_holding === 1 ||
            retorno[0].excluir_empresa_holding === 1) {
            setempresaHOLDING(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO Perfil / Permissão - submódulo 
          if (retorno[0].visualizar_perfil_permissao === 1 ||
            retorno[0].incluir_perfil_permissao === 1 ||
            retorno[0].editar_perfil_permissao === 1 ||
            retorno[0].excluir_perfil_permissao === 1) {
            setempresaPERFILPERMISSAO(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO usuario - submódulo 
          if (retorno[0].visualizar_usuario === 1 ||
            retorno[0].incluir_usuario === 1 ||
            retorno[0].editar_usuario === 1 ||
            retorno[0].excluir_usuario === 1) {
            setempresaUSUARIO(false) //tem acesso empresa(holding) - submódulo 
          }
          //##ACESSO usuario - submódulo 
          if (retorno[0].visualizar_codsistema === 1 ||
            retorno[0].incluir_codsistema === 1 ||
            retorno[0].editar_codsistema === 1 ||
            retorno[0].excluir_codsistema === 1) {
            setempresaCODSISTEMA(false) //tem acesso empresa(holding) - submódulo 
          }


        }
        else {
          setacessoADM(true) //não tem acesso - não mostrar
        }

        //##ACESSO CADASTRO - módulo 
        if (retorno[0].visualizarfilial === 1 ||
          retorno[0].incluirfilial === 1 ||
          retorno[0].editarfilial === 1 ||
          retorno[0].excluirfilial === 1 ||
          retorno[0].visualizarDepositante === 1 ||
          retorno[0].incluirDepositante === 1 ||
          retorno[0].editarDepositante === 1 ||
          retorno[0].excluirDepositante === 1 ||
          retorno[0].visualizarDepositante === 1 ||
          retorno[0].incluirDepositante === 1 ||
          retorno[0].editarDepositante === 1 ||
          retorno[0].excluirDepositante === 1 ||
          retorno[0].visualizarEquipeOperacional === 1 ||
          retorno[0].incluirEquipeOperacional === 1 ||
          retorno[0].editarEquipeOperacional === 1 ||
          retorno[0].excluirEquipeOperacional === 1 ||
          retorno[0].visualizarAgendaEquipe === 1 ||
          retorno[0].incluirAgendaEquipe === 1 ||
          retorno[0].editarAgendaEquipe === 1 ||
          retorno[0].excluirAgendaEquipe === 1) {

          setacessoCADASTRO(false) //tem acesso à cadastro - mostrar cadastro

          if (retorno[0].visualizarfilial === 1 ||
            retorno[0].incluirfilial === 1 ||
            retorno[0].editarfilial === 1 ||
            retorno[0].excluirfilial === 1) {
            setacessoFILIAL(false)//tem acesso à filial - mostrar filial

          }
          if (retorno[0].visualizarDepositante === 1 ||
            retorno[0].incluirDepositante === 1 ||
            retorno[0].editarDepositante === 1 ||
            retorno[0].excluirDepositante === 1) {
            setacessoDEPOSITANTE(false)//tem acesso à depositante - mostrar depositante
          }
          if (retorno[0].visualizarEquipeOperacional === 1 ||
            retorno[0].incluirEquipeOperacional === 1 ||
            retorno[0].editarEquipeOperacional === 1 ||
            retorno[0].excluirEquipeOperacional === 1) {
            setacessoEQUIPEOPERACIONAL(false)//tem acesso À equipe operacional
          }
          if (retorno[0].visualizarTempoOperacional === 1 ||
            retorno[0].incluirTempoOperacional === 1 ||
            retorno[0].editarTempoOperacional === 1 ||
            retorno[0].excluirTempoOperacional === 1) {
            setacessoTEMPOOPERACIONAL(false)//tem acesso À tempo operacional
          }
          if (retorno[0].visualizarAgendaEquipe === 1 ||
            retorno[0].incluirAgendaEquipe === 1 ||
            retorno[0].editarAgendaEquipe === 1 ||
            retorno[0].excluirAgendaEquipe === 1) {
            setacessoAGENDAEQUIPE(false)//tem acesso À tempo operacional
          }
        }
        else {
          setacessoCADASTRO(true) //não tem acesso - não mostrar
        }
        //ACESSO MODULO ESTOQUE
        if (retorno[0].visualizarEstoqueSintetico === 1 || retorno[0].visualizarEstoqueValidadeLote === 1) {
          setacessoESTOQUE(false) //---ganha acesso ao modulo estoque

          if (retorno[0].visualizarEstoqueSintetico === 1) {
            setacessoESTOQUESINTETICO(false)
          }
          if (retorno[0].visualizarEstoqueValidadeLote === 1) {
            setacessoESTOQUEVALIDADELOTE(false)
          }
        }
        //ACESSO MODULO PEDIDOS
        if (retorno[0].visualizarEnviarPedido === 1 ||
          retorno[0].visualizarPedidoStatus === 1 ||
          retorno[0].visualizarPedidoResumido === 1 ||
          retorno[0].visualizarPedidoValidadeLote === 1 ||
          retorno[0].visualizarPedidoBarra === 1) {
          setacessoPEDIDO(false) //--ganha acesso ao modulo PEDIDOS
          if (retorno[0].visualizarEnviarPedido === 1) {
            setvisualizarEnviarPedido(false)
          }
          if (retorno[0].visualizarPedidoStatus === 1) {
            setVisualizarPedidoStatus(false)
          }
          if (retorno[0].visualizarPedidoResumido === 1) {
            setVisualizarPedidoResumido(false)
          }
          if (retorno[0].visualizarPedidoValidadeLote === 1) {
            setVisualizarPedidoValidadeLote(false)
          }
          if (retorno[0].visualizarPedidoBarra === 1) {
            setVisualizarPedidoBarra(false)
          }
        }

        //ACESSO AGENDAMENTO DE EQUIPE - MODULO
        if (retorno[0].visualizarAgendamentoDeEquipe === 1 ||
          retorno[0].incluirAgendamentoDeEquipe === 1 ||
          retorno[0].EditarAgendamentoDeEquipeUsuario === 1 ||
          retorno[0].EditarAgendamentoDeEquipeTodos === 1 ||
          retorno[0].ExcluirAgendamentoDeEquipeUsuario === 1 ||
          retorno[0].ExcluirAgendamentoDeEquipeTodos === 1 ||
          retorno[0].AcessoTotalAgendamentoDeEquipe === 1) {
          setacessoAGENDAMENTODEEQUIPE(false)//libera acesso ao agendamento de equipe
        }
        //acesso RECEBIDOS
        if (retorno[0].visualizarResumidoValidade || retorno[0].visualizarDetalhadoBarra) {
          setacessoRECEBIMENTOS(false)

          if (retorno[0].visualizarResumidoValidade === 1) {
            setVisualizarResumidoValidade(false)
          }
          if (retorno[0].visualizarDetalhadoBarra === 1) {
            setvisualizarDetalhadoBarra(false)
          }
        }

        if (retorno[0].dashboard === 1 || retorno[0].TorreControle === 1) {
          setacessoRELATORIOS(false)

          if (retorno[0].dashboard === 1) {

            set_visualizarDashboard(false)
          }
          if (retorno[0].TorreControle === 1) {

            set_visualizarTorre(false)
          }
        }

        if (retorno[0].visualizarUsuarioFilial == 1) {
          setAcessoUsuarioFilial(false)
        }

      }).catch(function (erro) {
        sessionStorage.clear();
        navigate("/login");
      })
  }


  //controle de permissões --- FIM

  useEffect(function () {
    CarregarPermissões(id_perfil)
  }, [])


  return (
    <div className="leftside-menu">
      <a href="/" className="logo text-center ">
        <span className="logo-lg">
          <img src={Logo} alt="" style={{ height: "48px" }} />
        </span>
        <span className="logo-sm">
          <img src={Logo} alt="" style={{ height: "24px" }} />
        </span>
      </a>

      <div className="h-100" id="leftside-menu-container" data-simplebar>
        <ul className="side-nav">
          <li className="side-nav-item" hidden={acessoADM}>
            <a
              data-bs-toggle="collapse"
              href="#Administrativo"
              aria-expanded="false"
              aria-controls="Administrativo"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="clarity:administrator-solid"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}> Administrativo </span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="Administrativo">
              <ul className="side-nav-second-level">
                {isSuper === "1" && (
                  <li hidden={empresaHOLDING}>
                    <Link to={"/administrativo/empresa"}>
                      Empresa (Holding)
                    </Link>
                  </li>
                )}
                {isSuper === "1" && (
                  <li hidden={empresaPERFILPERMISSAO}>
                    <Link to={"/administrativo/permisao"}>
                      Perfil / Permissão
                    </Link>
                  </li>
                )}
                <li hidden={empresaUSUARIO}>
                  <Link to={"/administrativo/usuario"}>Usuário</Link>
                </li>


                <li hidden={acessoUsuarioFilial}>
                  <Link to={"grid/filial"}>
                    Usuarios/Filial
                  </Link>
                </li>


                {isSuper === "1" && (
                  <li hidden={empresaCODSISTEMA}>
                    <Link to={"/administrativo/codistema"}>
                      Código do Sistema
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </li>
          <li className="side-nav-item" hidden={acessoCADASTRO}>
            <a
              data-bs-toggle="collapse"
              href="#Cadastro"
              aria-expanded="false"
              aria-controls="Cadastro"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="fluent:people-list-24-filled"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}>Cadastro</span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="Cadastro">
              <ul className="side-nav-third-level">
                <li>
                  <Link hidden={acessoFILIAL} to={"cadastro/filiais/filial"}>Filial</Link>
                </li>
                <li hidden={acessoDEPOSITANTE}>
                  <Link to={"cadastro/depositantes"}>Depositante</Link>
                </li>
                <li hidden={acessoEQUIPEOPERACIONAL}>
                  <Link to={"cadastro/equipes/operacional/listar"}>Equipe Operacional</Link>
                </li>
                <li hidden={acessoTEMPOOPERACIONAL}>
                  <Link to={"cadastro/tempo/operacional/listar"}>Tempo Operacional</Link>
                </li>
                <li hidden={acessoAGENDAEQUIPE}>
                  <Link to={"cadastro/agenda/operacional/listar"}>Grade Agenda Equipe</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="side-nav-item" hidden={acessoESTOQUE}>
            <a
              data-bs-toggle="collapse"
              href="#Estoque"
              aria-expanded="false"
              aria-controls="Estoque"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="clarity:align-bottom-line"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}>Estoque</span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="Estoque">
              <ul className="side-nav-third-level">
                <li hidden={acessoESTOQUESINTETICO}>
                  <Link to={"estoque/sintetico"}>Estoque Sintético</Link>
                </li>
                <li hidden={acessoESTOQUEVALIDADELOTE}>
                  <Link to={"estoque/barra/validade"}>Estoque Validade/Lote</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="side-nav-item" hidden={acessoPEDIDO}>
            <a
              data-bs-toggle="collapse"
              href="#Pedido"
              aria-expanded="false"
              aria-controls="Pedido"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="clarity:clipboard-solid"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}>Pedidos</span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="Pedido">
              <ul className="side-nav-third-level">
                <li hidden={visualizarEnviarPedido}>
                  <Link to={"pedido/criar/novo"}>Enviar Pedido</Link>
                </li>
                <li hidden={visualizarPedidoStatus}>
                  <Link to={"pedido/consultar/status"}>Consulta Pedido Status</Link>
                </li>
                <li hidden={visualizarPedidoResumido}>
                  <Link to={"pedido/consultar/consolidado"}>Consulta Pedido Resumido</Link>
                </li>
                <li hidden={visualizarPedidoValidadeLote}>
                  <Link to={"pedido/consultar/validade/lote"}>Consulta Pedido validade/lote</Link>
                </li>
                <li hidden={visualizarPedidoBarra}>
                  <Link to={"pedido/consultar/barra"}>Consulta Pedido Barra</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="side-nav-item" hidden={acessoRECEBIMENTOS}>
            <a
              data-bs-toggle="collapse"
              href="#Recebimento"
              aria-expanded="false"
              aria-controls="Recebimento"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="clarity:nvme-line"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}>Recebimentos</span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="Recebimento">
              <ul className="side-nav-third-level">
                <li hidden={visualizarResumidoValidade}>
                  <Link to={"recebido/resumido/validade"}>Resumido Validade</Link>
                </li>
                <li hidden={visualizarDetalhadoBarra}>
                  <Link to={"recebido/detalhado/barra"}>Detalhado por Barra</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="side-nav-item" hidden={acessoAGENDAMENTODEEQUIPE}>
            <a
              data-bs-toggle="collapse"
              href="#Agendamento"
              aria-expanded="false"
              aria-controls="Agendamento"
              className="side-nav-link"
            >
              <span
                className="iconify"
                data-icon="ant-design:schedule-outlined"
                data-width="22"
              ></span>
              <span style={{ margin: "15px" }}>Agendamento</span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="Agendamento">
              <ul className="side-nav-third-level">
                <li>
                  <Link to={"agendamento/equipe"}>Agendamento de Equipes</Link>
                </li>
                <li>
                  <Link to={"agendamento/consultar/agenda"}>Agenda</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="side-nav-item" hidden={acessoRELATORIOS}>
            <a
              data-bs-toggle="collapse"
              href="#relatorios"
              aria-expanded="false"
              aria-controls="relatorios"
              className="side-nav-link"
            >
              <span className="iconify" data-icon="carbon:dashboard"></span>
              <span style={{ margin: "15px" }}>Relatórios</span>
              <span className="menu-arrow"></span>
            </a>
            <div className="collapse" id="relatorios">
              <ul className="side-nav-third-level">
                <li hidden={visualizarDashboard}>
                  <Link to={"dashborad/consultar"}>Dashboard Agenda</Link>
                </li>
                <li hidden={visualizarTorre}>
                  <Link to={"dashborad/controle/torre"}>Torre de Controle</Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { LeftBar };
